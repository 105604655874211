// Webpack did not want to import this from worker-provider in index.tsx
// it does not recognise the import - no idea why!

// Restore MPCComlinkWorkerFile from './comlink-mpc-worker?worker';
const MpcComlinkWorkerFile = new Worker(
  new URL('./comlink-mpc-worker.ts', import.meta.url)
  // { type: 'module' }
);
import * as Comlink from 'comlink';

// export const webWorker = new Worker(
//   new URL('./comlink-worker.ts', import.meta.url)
// );

// export const mpcComlinkWorker = new MpcComlinkWorkerFile();
export const mpcWorker: any = Comlink.wrap(MpcComlinkWorkerFile);

// console.log('ufo ', import.meta);
