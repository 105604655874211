import { Fragment, useEffect, useState } from 'react';
import {
  Button,
  Input,
  Popup,
  Divider,
  Toast,
  Mask,
  SpinLoading,
  AutoCenter,
  DotLoading,
} from 'antd-mobile';
import { CloseOutline, RightOutline } from 'antd-mobile-icons';
import { useImmer } from 'use-immer';
import { ethers } from 'ethers';
import copy from 'copy-to-clipboard';

import {
  getGasLimit,
  sendTransactionFromBitverse,
  sendErc20Transfer,
  BWWalletService,
  getProvider,
} from '@bitverse-h5-wallet/bitverse-core';

import httpClient from '@bitverse-h5-wallet/bitverse-http-client';
import useNavigatePage from '../../hooks/use-navigate-page';
import { useNetwork } from '../../components/networks/useNetworks';

import { Header } from '../../layouts/Header';
import copyBtn from '../../assets/copyBtn.svg';
import { PasswordPicker } from '../Password';

import './index.less';
import { NX_API_Base_URL } from '../../utils';
import { txListStorageKey } from '../../constants';

interface Form {
  address: string;
  amount: string;
}

const BLANK_IMAGE_URL =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAOklEQVR42mP4z8DwHwEF/wPAmcB8z+AbXABVyA8FYQBoIaQA5wIyrgE8n/wYFRiQimQAAAABJRU5ErkJggg=';

const Send = () => {
  const { navigateToPage } = useNavigatePage();
  const params = new URLSearchParams(window.location.search);
  const networks = useNetwork(params.get('chainId') || '')?.network;
  const [visible, setVisible] = useState(false);
  const [form, updateForm] = useImmer<Form>({
    address: '',
    amount: '',
  });
  const [tokenLoading, setTokenLoading] = useState(false);
  const [txLoading, setTxLoading] = useState(false);

  const [token, setToken] = useState<any>({});
  const [addressTips, setAddressTips] = useState('');
  const [amountTips, setAmountTips] = useState('');
  const [gasLimit, setGasLimit] = useState('');
  const [gasPrice, setGasPrice] = useState('');
  const [pwdVisible, setPwdVisible] = useState(false);

  const [gasLoading, setGasLoading] = useState(false);

  const account = BWWalletService.instance.getCurrentWallet();

  const getGasPrice = async () => {
    setGasLoading(true);
    try {
      const gasLimit = await getGasLimit({ to: form.address }, networks);
      const provider = getProvider(networks);
      const feeData = await provider.getFeeData();
      const { gasPrice } = feeData;
      const total = ethers.utils.formatEther(gasPrice?.mul(gasLimit));
      setGasLimit(gasLimit);
      setGasPrice(total);
      setGasLoading(false);
    } catch (e) {
      Toast.show({
        icon: 'fail',
        content: 'Cannot estimate gas',
        position: 'top',
      });
      setVisible(false);
    }
  };

  useEffect(() => {
    if (params.get('coinId') || params.get('chainId')) {
      const coinId = Number(params.get('coinId')) || networks.coinId;
      const chainId = Number(params.get('chainId')) || networks.chainId;
      const contract = params.get('contract') || '';
      handleContractDetail({
        coinId: coinId || 0,
        chainId: chainId,
        contractAddress: contract,
      });
    }
  }, []);

  const handleContractDetail = async ({
    coinId,
    chainId,
    contractAddress,
  }: {
    coinId: number;
    chainId: number | string;
    contractAddress: string;
  }) => {
    setTokenLoading(true);

    try {
      const rtn = await httpClient.post(
        `${NX_API_Base_URL}/bitverse/wallet/v1/public/asset/token/batchQuery`,
        {
          includeBalance: true,
          list: {
            coinId: coinId,
            chainId,
            contract: contractAddress, // 合约地址
            address: account.address,
          },
        }
      );
      const { result, retCode } = rtn;
      if (retCode === 0) {
        setToken(result.result?.[0] || {});
        setTokenLoading(false);
        return result.result?.[0] || {};
      }
    } catch (error) {
      console.log('[response error]: ', error);
    }
    setTokenLoading(false);
  };

  // 把交易记录添加到本地
  function addTxListToLocal(txHash?: string) {
    if (!txHash) return;
    const txList = JSON.parse(
      localStorage.getItem(txListStorageKey) || '[]'
    ) as any[];
    txList.unshift({
      symbol: token.symbol,
      contract: token.contract,
      chainId: networks.chainId,
      hash: txHash,
      quantity: form.amount,
      date: Math.floor(Date.now() / 1000),
      address: account.address,
    });
    localStorage.setItem(txListStorageKey, JSON.stringify(txList));
  }

  const handleTxErc20 = async () => {
    const amount = ethers.utils.parseUnits(
      String(Number(form.amount).toFixed(token.decimals)),
      token.decimals
    );
    try {
      const txHash = await sendErc20Transfer(
        token.contract,
        form.address,
        amount.toString(),
        (txResult) => {
          console.log('txResult:', txResult);
          handleAddTxHash({
            hash: txResult.transactionHash,
            transaction: JSON.stringify(txResult),
          });
        },
        networks
      );
      setTxLoading(true);
      addTxListToLocal(txHash);
      navigateToPage('status', {
        state: {
          hash: txHash,
          contract: token.contract,
          iconUrl: token.iconUrl,
          balance: token.balance,
          chainId: networks?.chainId,
        },
      });
    } catch (error) {
      console.log('err:', error);
      Toast.show({
        icon: 'fail',
        content: 'Transaction Failed',
        position: 'top',
      });
    }
  };

  // 主链币转账
  const handleTx = async () => {
    const amount = ethers.utils.parseUnits(
      String(Number(form.amount).toFixed(token.decimals)),
      token.decimals
    );
    try {
      const transactionRaw = {
        to: form.address,
        gasLimit,
        value: amount.toHexString(),
      };
      const txR: string | undefined = await sendTransactionFromBitverse(
        transactionRaw,
        (txResult) => {
          console.log('txResult:', txResult);
          handleAddTxHash({
            hash: txResult.transactionHash,
            transaction: JSON.stringify(txResult),
          });
        },
        networks
      );
      // 如果是错误，就显示错误信息，否则显示交易 hash
      setTxLoading(false);
      addTxListToLocal(txR);
      navigateToPage('status', {
        state: {
          hash: txR,
          contract: token.contract,
          iconUrl: token.iconUrl,
          balance: token.balance,
          chainId: networks?.chainId,
        },
      });
    } catch (err: any) {
      console.error('err:', err);
      setTxLoading(false);
      Toast.show({
        icon: 'fail',
        content: JSON.parse(err.body)?.error?.message || 'Transaction Failed',
        position: 'top',
      });
    }
  };

  const handleAddTxHash = async ({
    hash,
    transaction,
  }: {
    hash: string | undefined;
    transaction: string;
  }) => {
    if (!hash) return;

    const coinId = Number(params.get('coinId')) || networks.coinId || 0;
    const chainId = params.get('chainId') || networks.chainId || '';

    const data = {
      coinId: coinId,
      chainId: chainId,
      address: account.address,
      toAddress: form.address,
      hash,
      contract: token.contract,
      amount: form.amount,
      transaction,
    };
    const res = await httpClient.post(
      `${NX_API_Base_URL}/bitverse/wallet/v1/public/trade/transaction/add`,
      data
    );
    if (res.retCode === 1001) {
      const params = {
        walletAddresses: [
          {
            coinId: networks.coinId,
            chainId: networks.chainId,
            address: account.address,
            walletId: account.walletId,
            type: 'BW',
          },
        ],
      };
      const rep = await httpClient.post(
        `${NX_API_Base_URL}/bitverse/wallet/v1/public/device/address/create`,
        { ...params }
      );
      if (rep.retCode === 0) {
        handleAddTxHash({ hash, transaction });
      }
    }
  };
  const handleChangeCoin = () => {
    navigateToPage('send', {
      state: {
        chainId: networks?.chainId,
      },
    });
  };

  const handleFormChange = (value: string, field: keyof Form) => {
    updateForm((draft) => {
      draft[field] = value;
    });
    if (field === 'address') {
      if (ethers.utils.isAddress(value)) {
        setAddressTips('');
      } else {
        setAddressTips('Invalid Wallet Address');
      }
    }
    if (field === 'amount') {
      if (isNaN(Number(value)) || Number(value) <= 0) {
        setAmountTips('Illegal Amount');
      } else if (Number(value) > Number(token.balance)) {
        setAmountTips('The transfer amount cannot exceed the balance');
      } else {
        setAmountTips('');
      }
    }
  };

  const onSubmit = () => {
    setPwdVisible(false);
    setVisible(false);
    setTxLoading(true);
    // token 的 contract 为空时，为主链币，否则为 erc20
    if (token?.contract) {
      handleTxErc20();
    } else {
      handleTx();
    }
  };

  const handleCopy = (txt: string) => {
    if (copy(txt)) {
      Toast.show({
        content: 'Copy Successfully!',
        position: 'top',
      });
    } else {
      Toast.show({
        content: 'Copy failed, please manually select and right-click to copy.',
        position: 'top',
      });
    }
  };

  const handleBackToTokenDetail = () => {
    if (token.symbol) {
      navigateToPage('detail', {
        state: { ...token, chainId: networks?.chainId },
      });
    } else {
      navigateToPage('home');
    }
  };

  return (
    <Fragment>
      <Header pageName="Send" handleBackClick={handleBackToTokenDetail} />
      <div className="pd16 send-container">
        <div className="df aic mt16 adm-input" onClick={handleChangeCoin}>
          <img
            alt="coin"
            className="token-img mr10"
            src={token.iconUrl || BLANK_IMAGE_URL}
          />
          <p className="fs16 f1">{token.symbol}</p>
          <RightOutline />
        </div>
        <p className="fs14 lh24 mt24">Recipient</p>

        <Input
          className="mt8"
          value={form.address}
          onChange={(value: string) => handleFormChange(value, 'address')}
        />
        <div className="mt8 tips">{addressTips}</div>

        <div className="receive-title lh24 mt24">
          <div className="fs14">Quantity</div>
          <div className="fs14 balance">Available:{token.balance}</div>
        </div>
        <Input
          className="mt8"
          value={form.amount}
          onChange={(value: string) => handleFormChange(value, 'amount')}
        />
        <div className="mt8 tips">{amountTips}</div>

        <Button
          color="primary"
          className="w100 mt32"
          fill="solid"
          size="large"
          disabled={
            !form.address || !form.amount || !!amountTips || !!addressTips
          }
          onClick={async () => {
            setVisible(true);
            getGasPrice();
          }}
        >
          Confirm
        </Button>

        <Popup
          visible={visible}
          bodyClassName="send-popup"
          position="bottom"
          onMaskClick={() => {
            setVisible(false);
          }}
          onClose={() => {
            setVisible(false);
          }}
        >
          <div className="send_wrap">
            <div className="df aic jcc header_box">
              <div className="popup-header f1">Confirm</div>
              <CloseOutline
                className="t2 fs16 cp"
                onClick={() => setVisible(false)}
              />
            </div>
            <div className="popup-title">
              <img src={token.iconUrl || BLANK_IMAGE_URL} alt="" />
              <div>{token.symbol}</div>
            </div>
            <div className="popup-line">
              <div className="t2">Recipient</div>
              <div>{form.address}</div>
              <img
                onClick={() => handleCopy(form.address)}
                src={copyBtn}
                alt=""
              />
            </div>
            <div className="popup-line">
              <div className="t2">Quantity</div>
              <div>{`${form.amount} ${token.symbol}`}</div>
            </div>
            <div className="popup-line">
              <div className="t2">Gas</div>
              {gasLoading ? (
                <DotLoading color="primary" />
              ) : (
                <div>{`${gasPrice} ${networks.symbol}`}</div>
              )}
            </div>
            <Button
              color="primary"
              className="confirm-button mt32 mb24"
              fill="solid"
              size="large"
              onClick={() => {
                setPwdVisible(true);
                setVisible(false);
              }}
              disabled={!gasPrice}
            >
              Confirm
            </Button>
          </div>
        </Popup>
      </div>
      <PasswordPicker
        visible={pwdVisible}
        onConfirm={onSubmit}
        onClose={() => setPwdVisible(false)}
      />

      <Mask visible={txLoading || tokenLoading}>
        <div className="tx-loading">
          <AutoCenter>
            <SpinLoading />
          </AutoCenter>
        </div>
      </Mask>
    </Fragment>
  );
};

export default Send;
