import { FC, useMemo, useState, useEffect, useContext } from 'react';
import { Picker } from 'antd-mobile';
import { useNetworks, useCurrentNetwork, switchNetwork } from './useNetworks';
import { ProviderContext } from '../../context';

type NetworkSwitcherProps = object;

const NetworkSwitcher: FC<NetworkSwitcherProps> = () => {
  const allNetworks = useNetworks();
  const networkInfo = useCurrentNetwork();
  const basicColumns = useMemo(() => {
    return [
      allNetworks.map((network) => {
        return {
          network,
          label: network.name,
          value: network.chainId,
        };
      }),
    ];
  }, [allNetworks]);
  const [visible, setVisible] = useState(false);
  const [value, setValue] = useState<any>(networkInfo?.chainId);
  const { globalState, globalDispatch } = useContext(ProviderContext);

  const ChooseNetworkFun = (v: any, ext: any) => {
    switchNetwork(v[0]);
    setValue(v);
    globalDispatch({
      type: 'get_network_action',
      payload: {
        netWork: ext?.items?.[0]?.network,
      },
    });
  };
  console.log('networkInfo', networkInfo);

  return (
    <>
      <div
        className="cp fs14 tar"
        onClick={() => {
          setVisible(true);
        }}
      >
        <i className="point"></i>
        {networkInfo?.name || ''}
      </div>
      <Picker
        columns={basicColumns as any}
        visible={visible}
        cancelText="cancel"
        confirmText="confirm"
        onClose={() => {
          setVisible(false);
        }}
        defaultValue={[value]}
        onConfirm={(v, ext) => {
          ChooseNetworkFun(v, ext);
        }}
      />
    </>
  );
};

export default NetworkSwitcher;
