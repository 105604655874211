import { Header } from '../../layouts/Header';
import './index.less';
import { Fragment } from 'react';
import QrCodeView from '../../components/QrCode';
import copy from 'copy-to-clipboard';
import { Button, Toast } from 'antd-mobile';
import copyBtn from '../../assets/copyBtn.svg';
import { BWWalletService } from '@bitverse-h5-wallet/bitverse-core';

import { useNetwork } from '../../components/networks/useNetworks';
import { useNavigate } from 'react-router-dom';

const Receive = () => {
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const networkParams = params.get('chainId') || '';
  const network = useNetwork(networkParams)?.network;
  const { address, backupStatus } = BWWalletService.instance.getCurrentWallet();
  const handleCopy = (txt: string) => {
    if (copy(txt)) {
      Toast.show({
        content: 'Copy Successfully!',
        position: 'bottom',
      });
    } else {
      Toast.show({
        icon: 'fail',
        content: 'Copy failed, please manually select and right-click to copy.',
        position: 'bottom',
      });
    }
  };

  return (
    <Fragment>
      <Header pageName="Receive" />
      {backupStatus?.google ? (
        <div className="pd16 tac fs14">
          <p className="mt16 t2">Collection network {network.name}</p>
          <p className="mt24 t2">Scan the QR code and pay me</p>
          <div className="mt16 df aic jcc">
            <QrCodeView
              data={address}
              color="#02182B"
              margin={0}
              cellSize={5}
            />
          </div>
          <div className="mt16 t2">
            {address}
            <img
              className="receive-copy-btn"
              onClick={() => handleCopy(address)}
              src={copyBtn}
              alt=""
            />
          </div>
        </div>
      ) : (
        <div className="backup-hint t2">
          <p>
            For the safety of your assets, please back up your current wallet
            first
          </p>
          <Button
            onClick={() => navigate('/backup')}
            color="primary"
            className="mt16"
            fill="solid"
            size="large"
          >
            BackUp Wallet
          </Button>
        </div>
      )}
    </Fragment>
  );
};

export default Receive;
