import { ethers } from 'ethers';
import { Network, Networks } from '../networks';

const jsonRpcProvider: any = {};

export function getProvider(network ?: Network) {
  const currentNetwork = network ?? Networks.instance.getCurrentNetwork();
  console.log(currentNetwork.info.rpc, '>>>>>>>>');
  const rprUrl = currentNetwork.info.rpc;
  const currentChainId = currentNetwork.chainId;
  if (!jsonRpcProvider || !(currentChainId in jsonRpcProvider)) {
    jsonRpcProvider[currentChainId] = new ethers.providers.JsonRpcProvider(
      rprUrl
    );
  }
  return jsonRpcProvider[currentChainId];
}

export async function eth_getTransactionCount(address: string, network?: Network) {
  const provider = getProvider(network);
  try {
    const transactionResponse = await provider.send('eth_getTransactionCount', [
      address,
      'latest',
    ]);
    console.log(
      '[eth_getTransactionCount result]',
      parseInt(transactionResponse, 16)
    );
    return transactionResponse;
  } catch (error) {
    console.log('[eth_getTransactionCount error]', error);
  }
}

export async function eth_gasPrice(network?: Network) {
  const provider = getProvider(network);
  try {
    const transactionResponse = await provider.send('eth_gasPrice', []);
    console.log('[eth_gasPrice result]', transactionResponse);
    return transactionResponse;
  } catch (error) {
    console.log('[eth_gasPrice error]', error);
  }
}

export async function sendRawTransaction(
  signedTransaction: string,
  callback?: (txResult: any) => void,
  network?: Network
): Promise<string> {
  const provider = getProvider(network);
  let transactionHash = '';
  // try {
  // 发送原始交易数据到以太坊网络
  try {
    transactionHash = await provider.send('eth_sendRawTransaction', [
      signedTransaction,
    ]);
  } catch (error) {
    console.error(error);
    throw error;
    // return error;
  }

  // 等待交易确认
  provider.waitForTransaction(transactionHash).then((txResult: any) => {
    console.log('[waitForTransaction result]', txResult);
    callback && callback(txResult);
  });

  return transactionHash;
  // } catch (error) {
  //   console.log('[sendRawTransaction error]', error);
  //   return String(error);
  // }
}
