export type EventType = string | symbol;

// An event handler can take an optional event argument
// and should not return a value
export type Handler<T = unknown> = (event: T) => void;
export type WildcardHandler<T = Record<string, unknown>> = (
  type: keyof T,
  event: T[keyof T]
) => void;

// An array of all currently registered event handlers for a type
export type EventHandlerList<T = unknown> = Array<Handler<T>>;
export type WildCardEventHandlerList<T = Record<string, unknown>> = Array<
  WildcardHandler<T>
>;

// A map of event types and their corresponding event handlers.
export type EventHandlerMap<Events extends Record<EventType, unknown>> = Map<
  keyof Events | '*',
  EventHandlerList<Events[keyof Events]> | WildCardEventHandlerList<Events>
>;

export interface Emitter<Events extends Record<EventType, unknown>> {
  all: EventHandlerMap<Events>;
  untreated: EventHandlerMap<Events>;

  on<Key extends keyof Events>(type: Key, handler: Handler<Events[Key]>): void;
  on(type: '*', handler: WildcardHandler<Events>): void;

  off<Key extends keyof Events>(
    type: Key,
    handler?: Handler<Events[Key]>
  ): void;
  off(type: '*', handler: WildcardHandler<Events>): void;

  emit<Key extends keyof Events>(type: Key, event: Events[Key]): void;
  emit<Key extends keyof Events>(
    type: undefined extends Events[Key] ? Key : never
  ): void;
}

/**
 * Mitt: Tiny (~200b) functional event emitter / pubsub.
 * @name mitt
 * @returns {Mitt}
 */
function mitt<Events extends Record<EventType, unknown>>(): Emitter<Events> {
  type GenericEventHandler =
    | Handler<Events[keyof Events]>
    | WildcardHandler<Events>;
  const all = new Map();
  const untreated = new Map();

  return {
    /**
     * A Map of event names to registered handler functions.
     */
    all,

    /**
     * A Map of event names to record not resolved events.
     */
    untreated,
    /**
     *为给定类型注册事件处理程序。
     *@param {string|symbol} type 要监听的事件类型，或所有事件的“*”
     *@param {Function} handler 响应给定事件而调用的函数
     *@memberOf 手套
     */
    on<Key extends keyof Events>(type: Key, handler: GenericEventHandler) {
      const handlers: Array<GenericEventHandler> | undefined = all.get(type);
      if (handlers) {
        handlers.push(handler);
      } else {
        all!.set(type, [handler] as EventHandlerList<Events[keyof Events]>);
      }

      /** 处理之前发送未能响应的消息 */
      const params: Events[Key] = untreated!.get(type);
      if (params !== undefined) {
        this.emit(type, params);
        untreated.delete(type);
      }
    },

    /**
     *删除给定类型的事件处理程序。
     *如果省略“handler”，则删除给定类型的所有处理程序。
     *@param {string|symbol} type 要注销`handler`的事件类型，或`'*'`
     *@param {Function} [handler] 要删除的处理函数
     *@memberOf 手套
     */
    off<Key extends keyof Events>(type: Key, handler?: GenericEventHandler) {
      const handlers: Array<GenericEventHandler> | undefined = all!.get(type);
      if (handlers) {
        if (handler) {
          handlers.splice(handlers.indexOf(handler) >>> 0, 1);
        } else {
          all!.set(type, []);
        }
      }
    },

    /**
     *调用给定类型的所有处理程序。
     *如果存在，则在类型匹配的处理程序之后调用“*”处理程序。
     *
     *注意：不支持手动触发“*”处理程序。
     *
     *@param {string|symbol} type 要调用的事件类型
     *@param {Any} [evt] 任何值（推荐使用对象，功能强大），传递给每个处理程序
     *@memberOf 手套
     */
    emit<Key extends keyof Events>(type: Key, evt?: Events[Key]) {
      let handlers = all!.get(type);
      if (handlers) {
        (handlers as EventHandlerList<Events[keyof Events]>)
          .slice()
          .map((handler) => {
            handler(evt!);
          });
      } else {
        // 如果接收消息的事件还没注册好需要先存下来
        untreated.set(type, evt!);
      }

      handlers = all!.get('*');
      if (handlers) {
        (handlers as WildCardEventHandlerList<Events>)
          .slice()
          .map((handler) => {
            handler(type, evt!);
          });
      }
    },
  };
}

const bus = mitt();

export const type = 'BITVERSE_MESSAGE_SDK_EVENT_BUS';

export default {
  on: bus.on.bind(bus),
  off: bus.off.bind(bus),
  emit: (t: string, params: any = {}, error?: any) => {
    bus.emit(type, {
      type: t,
      params,
      error
    });
  },
};
