import { BWWalletService } from "@bitverse-h5-wallet/bitverse-core"

const CREATE_WALLET_PAGES = ['/email-address', '/pwd', '/recovery', '/app/google']

/**
 * 如果当前页面是创建钱包相关页面，无需判断钱包是否存在，返回true
 * 如果当前页面不是创建钱包相关页面，判断钱包列表和当前钱包是否存在，返回true或false
 * @returns 钱包是否存在
 */
export const walletVerify = () => {
  const path = window?.location.pathname
  return CREATE_WALLET_PAGES.includes(path) ||
    (BWWalletService.instance.getAllWallets()?.length &&
      BWWalletService.instance.getCurrentWallet()?.address)
}