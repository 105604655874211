import { useEffect, useMemo, useState } from "react";
import classnames from 'classnames';
import { CheckOutline, CloseOutline } from "antd-mobile-icons";

interface PasswordCheckPassedProps {
  value: string;
  setPassed: (pass: boolean) => void;
}

const CheckIcon = ({checked}: {checked: boolean} ) => {
  return checked? <CheckOutline className="green mr10"/> : <CloseOutline className="red mr10"/>
}

export const PasswordCheckPassed: React.FC<PasswordCheckPassedProps> = (
  props: PasswordCheckPassedProps
) => {
  const [lengthPass, setLengthPass] = useState(false);
  const [uppercasePass, setUppercasePass] = useState(false);
  const [numberPass, setNumberPass] = useState(false);
  const [symbolPass, setSymbolPass] = useState(false);

  const checkSymbol = (str: string) => {
    const en = /[`!@#$%^&*()_+<>?:"{},./;'[\]]/im,
      cn = /[·！#￥（——）：；“”‘、，|《。》？、【】[\]]/im;
    return en.test(str) || cn.test(str);
  };

  const checkUppercase = (str: string) => {
    const uppercase = /^(?=.*?[A-Z]).*$/;
    return uppercase.test(str);
  };
  const checkNumber = (str: string) => {
    const uppercase = /^(?=.*?\d).*$/;
    return uppercase.test(str);
  };
  const checkLength = (str: string) => {
    return str?.length >= 8 && str?.length <= 30;
  };

  useEffect(() => {
    setLengthPass(checkLength(props.value));
    setUppercasePass(checkUppercase(props.value));
    setNumberPass(checkNumber(props.value));
    setSymbolPass(checkSymbol(props.value));
  }, [props.value]);

  useEffect(() => {
    setLengthPass(checkLength(props.value));
    setUppercasePass(checkUppercase(props.value));
    setNumberPass(checkNumber(props.value));
    setSymbolPass(checkSymbol(props.value));
  }, [props.value]);

  useEffect(() => {
    props.setPassed(lengthPass && uppercasePass && numberPass && symbolPass);
  }, [lengthPass, uppercasePass, numberPass, symbolPass]);

  return (
    <div className="mt16">
      <div className="df">
        <div className="df f1 lh24 aic">
          <CheckIcon checked={lengthPass} />
          <p
            className={classnames('password-check-pass-title', {
              'password-check-pass-title-active': lengthPass,
            })}
          >
            8-30 characters
          </p>
        </div>
        <div className="df f1 lh24 aic">
          <CheckIcon checked={uppercasePass} />
          <p
            className={classnames('password-check-pass-title', {
              'password-check-pass-title-active': uppercasePass,
            })}
          >
            1 uppercase
          </p>
        </div>
      </div>
      <div className="df">
        <div className="df f1 lh24 aic">
          <CheckIcon checked={numberPass} />
          <p
            className={classnames('password-check-pass-title', {
              'password-check-pass-title-active': numberPass,
            })}
          >
            1 number
          </p>
        </div>
        <div className="df f1 lh24 aic">
          <CheckIcon checked={symbolPass} />
          <p
            className={classnames('password-check-pass-title', {
              'password-check-pass-title-active': symbolPass,
            })}
          >
            1 symbol
          </p>
        </div>
      </div>
    </div>
  );
};