import React from 'react';
import { MovieOutline } from 'antd-mobile-icons';
import './index.less';

export const DApps = () => {
  return (
    <div className="dapps-wrap tac">
      <MovieOutline fontSize={70} className="green" />
      <p className="mt12 fs14 t2">Please look forward to it</p>
    </div>
  );
};
