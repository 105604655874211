const supportNetworks = [
  {
    id: 'ethereum',
    name: 'Ethereum',
    coinId: 60,
    symbol: 'ETH',
    decimals: 18,
    blockchain: 'Ethereum',
    derivation: [
      {
        path: "m/44'/60'/0'/0/0",
      },
    ],
    curve: 'secp256k1',
    publicKeyType: 'secp256k1Extended',
    chainId: '1',
    addressHasher: 'keccak256',
    explorer: {
      url: 'https://etherscan.io',
      txPath: '/tx/',
      accountPath: '/address/',
      sampleTx:
        '0x9edaf0f7d9c6629c31bbf0471fc07d696c73b566b93783f7e25d8d5d2b62fa4f',
      sampleAccount: '0x5bb497e8d9fe26e92dd1be01e32076c8e024d167',
    },
    info: {
      url: 'https://ethereum.org',
      source: 'https://github.com/ethereum/go-ethereum',
      // rpc: 'https://eth.llamarpc.com',
      rpc: 'https://node.bitverse.zone/bw/api/web3/node/eth',
      documentation: 'https://eth.wiki/json-rpc/API',
    },
    iconUrl: 'https://pic.bitverse.zone/token_images/1/eth.png',
  },
  {
    id: 'polygon',
    name: 'Polygon',
    coinId: 966,
    symbol: 'MATIC',
    decimals: 18,
    blockchain: 'Ethereum',
    derivation: [
      {
        path: "m/44'/60'/0'/0/0",
      },
    ],
    curve: 'secp256k1',
    publicKeyType: 'secp256k1Extended',
    chainId: '137',
    addressHasher: 'keccak256',
    explorer: {
      url: 'https://polygonscan.com',
      txPath: '/tx/',
      accountPath: '/address/',
      sampleTx:
        '0xe26ed1470d5bf99a53d687843e7acdf7e4ba6620af93b4d672e714de90476e8e',
      sampleAccount: '0x720E1fa107A1Df39Db4E78A3633121ac36Bec132',
    },
    info: {
      url: 'https://polygon.technology',
      source: 'https://github.com/maticnetwork/contracts',
      // rpc: 'https://polygon-rpc.com',
      rpc: 'https://node.bitverse.zone/bw/api/web3/node/matic',
      documentation: 'https://eth.wiki/json-rpc/API',
    },
    iconUrl: 'https://pic.bitverse.zone/token_images/137/matic.png',
  },
  // {
  //   id: 'mumbai',
  //   name: 'mumbai',
  //   // coinId: 966,
  //   symbol: 'MATIC',
  //   decimals: 18,
  //   blockchain: 'Ethereum',
  //   derivation: [
  //     {
  //       path: "m/44'/60'/0'/0/0",
  //     },
  //   ],
  //   curve: 'secp256k1',
  //   publicKeyType: 'secp256k1Extended',
  //   chainId: '80001',
  //   addressHasher: 'keccak256',
  //   explorer: {
  //     url: 'https://mumbai.polygonscan.com/',
  //     txPath: '/tx/',
  //     accountPath: '/address/',
  //     sampleTx:
  //       '0xe26ed1470d5bf99a53d687843e7acdf7e4ba6620af93b4d672e714de90476e8e',
  //     sampleAccount: '0x720E1fa107A1Df39Db4E78A3633121ac36Bec132',
  //   },
  //   info: {
  //     url: 'https://mumbai.polygonscan.com',
  //     source: 'https://github.com/maticnetwork/contracts',
  //     rpc: 'https://endpoints.omniatech.io/v1/matic/mumbai/public',
  //     documentation: 'https://eth.wiki/json-rpc/API',
  //   },
  //   iconUrl: 'https://pic.bitverse.zone/token_images/137/matic.png',
  // },
  {
    id: 'goerli',
    name: 'goerli',
    // coinId: 966,
    symbol: 'ETH',
    decimals: 18,
    blockchain: 'Ethereum',
    derivation: [
      {
        path: "m/44'/60'/0'/0/0",
      },
    ],
    curve: 'secp256k1',
    publicKeyType: 'secp256k1Extended',
    chainId: '5',
    addressHasher: 'keccak256',
    explorer: {
      url: 'https://goerli.etherscan.io/',
      txPath: '/tx/',
      accountPath: '/address/',
      sampleTx:
        '0xe26ed1470d5bf99a53d687843e7acdf7e4ba6620af93b4d672e714de90476e8e',
      sampleAccount: '0x720E1fa107A1Df39Db4E78A3633121ac36Bec132',
    },
    info: {
      url: 'https://goerli.etherscan.io/',
      rpc: 'https://rpc.ankr.com/eth_goerli',
    },
    iconUrl:
      'https://goerli.etherscan.io/images/svg/brands/ethereum-original.svg',
  },
  {
    id: 'sepolia',
    name: 'sepolia',
    // coinId: 966,
    symbol: 'ETH',
    decimals: 18,
    blockchain: 'Ethereum',
    derivation: [
      {
        path: "m/44'/60'/0'/0/0",
      },
    ],
    curve: 'secp256k1',
    publicKeyType: 'secp256k1Extended',
    chainId: '11155111',
    addressHasher: 'keccak256',
    explorer: {
      url: 'https://sepolia.etherscan.io/',
      txPath: '/tx/',
      accountPath: '/address/',
      sampleTx:
        '0xe26ed1470d5bf99a53d687843e7acdf7e4ba6620af93b4d672e714de90476e8e',
      sampleAccount: '0x720E1fa107A1Df39Db4E78A3633121ac36Bec132',
    },
    info: {
      url: 'https://sepolia.etherscan.io/',
      rpc: 'https://rpc.ankr.com/eth_sepolia',
    },
    iconUrl:
      'https://sepolia.etherscan.io/images/svg/brands/ethereum-original.svg',
  },
  {
    name: 'Mantle',
    code: 'mantle',
    chainId: '5000',
    coinId: 10005000,
    description: 'Mantle Chain',
    iconUrl: 'https://pic.bitverse.zone/chain_images/mantle.png',
    symbol: 'MNT',
    slip44: 0,
    blockchain: 'Ethereum',
    derivation: [
      {
        path: "m/44'/60'/0'/0/0",
      },
    ],
    mpcPath: '',
    curve: 'secp256k1',
    publicKeyType: 'secp256k1Extended',
    explorer: {
      url: 'https://explorer.mantle.xyz',
      txPath: '/tx/',
      accountPath: '/address/',
      sampleTx:
        '0xf97eebc08c4ccd940320fa1813c6de615bbdee111254ea799302df81722fd154',
      sampleAccount: '0xCc23091DE47a988DCB1ac8b0a80B49A14fe0A4ab',
    },
    info: {
      url: 'https://www.mantle.xyz/',
      source: 'https://github.com/mantlenetworkio',
      // rpc: 'https://rpc.mantle.xyz',
      rpc: 'https://rpc.mantle.xyz',
      documentation: 'https://docs.mantle.xyz/',
    },
    id: 'mantle',
    addressHasher: 'keccak256',
    decimals: 18,
    order: 4,
    popularity: 0,
    networkType: 'MAINNET',
  },
  {
    name: 'Mantle Testnet',
    code: 'mantle-testnet',
    chainId: '5001',
    coinId: 10005001,
    description: 'Mantle Testnet Chain',
    iconUrl: 'https://pic.bitverse.zone/chain_images/mantle.png',
    symbol: 'MNT',
    slip44: 0,
    blockchain: 'Ethereum',
    derivation: [
      {
        path: "m/44'/60'/0'/0/0",
      },
    ],
    mpcPath: '',
    curve: 'secp256k1',
    publicKeyType: 'secp256k1Extended',
    explorer: {
      url: 'https://explorer.testnet.mantle.xyz',
      txPath: '/tx/',
      accountPath: '/address/',
      sampleTx:
        '0xf97eebc08c4ccd940320fa1813c6de615bbdee111254ea799302df81722fd154',
      sampleAccount: '0xCc23091DE47a988DCB1ac8b0a80B49A14fe0A4ab',
    },
    info: {
      url: 'https://www.mantle.xyz/',
      source: 'https://github.com/mantlenetworkio',
      rpc: 'https://rpc.testnet.mantle.xyz',
      documentation: 'https://docs.mantle.xyz/',
    },
    id: 'mantle-testnet',
    addressHasher: 'keccak256',
    decimals: 18,
    order: 101,
    popularity: 0,
    networkType: 'TESTNET',
  },
  // {
  //   id: 'bitcoin',
  //   name: 'Bitcoin',
  //   coinId: 1,
  //   chainId: '10000001',
  //   symbol: 'BTC',
  //   decimals: 8,
  //   blockchain: 'Bitcoin',
  //   derivation: [
  //     {
  //       name: 'segwit',
  //       path: "m/84'/0'/0'/0/0",
  //       xpub: 'zpub',
  //       xprv: 'zprv',
  //     },
  //     {
  //       name: 'legacy',
  //       path: "m/44'/0'/0'/0/0",
  //       xpub: 'xpub',
  //       xprv: 'xprv',
  //     },
  //     {
  //       name: 'testnet',
  //       path: "m/84'/1'/0'/0/0",
  //       xpub: 'zpub',
  //       xprv: 'zprv',
  //     },
  //   ],
  //   curve: 'secp256k1',
  //   publicKeyType: 'secp256k1',
  //   p2pkhPrefix: 0,
  //   p2shPrefix: 5,
  //   hrp: 'bc',
  //   publicKeyHasher: 'sha256ripemd',
  //   base58Hasher: 'sha256d',
  //   explorer: {
  //     url: 'https://blockchair.com',
  //     txPath: '/bitcoin/transaction/',
  //     accountPath: '/bitcoin/address/',
  //     sampleTx:
  //       '0607f62530b68cfcc91c57a1702841dd399a899d0eecda8e31ecca3f52f01df2',
  //     sampleAccount: '17A16QmavnUfCW11DAApiJxp7ARnxN5pGX',
  //   },
  //   info: {
  //     url: 'https://bitcoin.org',
  //     source: 'https://github.com/trezor/blockbook',
  //     rpc: '',
  //     documentation:
  //       'https://github.com/trezor/blockbook/blob/master/docs/api.md',
  //   },
  //   iconUrl: 'https://pic.bitverse.zone/chain_images/btclogo.png',
  //   coinIconUrl: 'https://pic.bitverse.zone/chain_images/btclogo.png',
  //   networkType: 'MAINNET',
  //   order: 0,
  //   popularity: 0,
  //   networkId: '10000001',
  //   denom: 'sats',
  // },
  // {
  //   id: 'bitcointestnet',
  //   name: 'Bitcoin Testnet',
  //   coinId: 20000001,
  //   chainId: '30000001',
  //   symbol: 'BTC',
  //   decimals: 8,
  //   blockchain: 'Bitcoin',
  //   derivation: [
  //     {
  //       name: 'segwit',
  //       path: "m/84'/1'/0'/0/0",
  //       xpub: 'zpub',
  //       xprv: 'zprv',
  //     },
  //     {
  //       name: 'legacy',
  //       path: "m/44'/1'/0'/0/0",
  //       xpub: 'xpub',
  //       xprv: 'xprv',
  //     },
  //   ],
  //   curve: 'secp256k1',
  //   publicKeyType: 'secp256k1',
  //   p2pkhPrefix: 111,
  //   p2shPrefix: 196,
  //   hrp: 'tb',
  //   publicKeyHasher: 'sha256ripemd',
  //   base58Hasher: 'sha256d',
  //   explorer: {
  //     url: 'https://blockchair.com',
  //     txPath: '/bitcoin/testnet/transaction/',
  //     accountPath: '/bitcoin/testnet/address/',
  //     sampleTx:
  //       '2628679656093f062455ba9c34e10d60b6da01e7336e0d0df3b817d9a7dda23b',
  //     sampleAccount: 'tb1qks8l9haxjszn9r6yf2dm65ed3w6wmz85r379ms',
  //   },
  //   info: {
  //     url: 'https://bitcoin.org',
  //     source: 'https://github.com/trezor/blockbook',
  //     rpc: '',
  //     documentation:
  //       'https://github.com/trezor/blockbook/blob/master/docs/api.md',
  //   },
  //   iconUrl: 'https://pic.bitverse.zone/chain_images/btclogo.png',
  //   coinIconUrl: 'https://pic.bitverse.zone/chain_images/btclogo.png',
  //   networkType: 'TESTNET',
  //   order: 0,
  //   popularity: 0,
  //   networkId: '30000001',
  //   denom: 'sats',
  // },
  {
    id: 'smartchain',
    name: 'BNB Chain',
    displayName: 'BNB Chain',
    coinId: 20000714,
    slip44: 714,
    symbol: 'BNB',
    decimals: 18,
    blockchain: 'Ethereum',
    derivation: [
      {
        path: "m/44'/60'/0'/0/0",
      },
    ],
    curve: 'secp256k1',
    publicKeyType: 'secp256k1Extended',
    chainId: '56',
    addressHasher: 'keccak256',
    explorer: {
      url: 'https://bscscan.com',
      txPath: '/tx',
      accountPath: '/address/',
      sampleTx:
        '0xb9ae2e808fe8e57171f303ad8f6e3fd17d949b0bfc7b4db6e8e30a71cc517d7e',
      sampleAccount: '0x35552c16704d214347f29Fa77f77DA6d75d7C752',
    },
    info: {
      url: 'https://www.binance.org/en/smartChain',
      source: 'https://github.com/binance-chain/bsc',
      rpc: 'https://node.bitverse.zone/bw/api/web3/node/bsc',
      documentation: 'https://eth.wiki/json-rpc/API',
    },
    iconUrl: 'https://pic.bitverse.zone/token_images/56/bnb.png',
    networkType: 'MAINNET',
    order: 2,
    popularity: 0,
    networkId: '56',
    denom: 'wei',
  },
  {
    id: 'okc',
    name: 'OKX Chain',
    coinId: 996,
    chainId: '66',
    symbol: 'OKT',
    decimals: 18,
    blockchain: 'Ethereum',
    derivation: [
      {
        path: "m/44'/60'/0'/0/0",
      },
    ],
    curve: 'secp256k1',
    publicKeyType: 'secp256k1Extended',
    addressHasher: 'keccak256',
    explorer: {
      url: 'https://www.oklink.com/en/okc',
      txPath: '/tx',
      accountPath: '/address/',
      sampleTx:
        '0x46C3A947E8248570FBD28E4FE456CC8F80DFD90716533878FB67857B95FA3D37',
      sampleAccount: '0x074faafd0b20fad2efa115b8ed7e75993e580b85',
    },
    info: {
      url: 'https://www.okx.com/okc',
      source: 'https://github.com/okex/exchain',
      rpc: 'https://exchainrpc.okex.org',
      documentation: 'https://okc-docs.readthedocs.io/en/latest',
    },
    iconUrl: 'https://pic.bitverse.zone/token_images/66/okt.png',
    networkType: 'MAINNET',
    order: 4,
    popularity: 0,
    networkId: '66',
    denom: 'wei',
  },
  {
    id: 'arbitrum',
    name: 'Arbitrum',
    coinId: 10042221,
    chainId: '42161',
    slip44: 60,
    symbol: 'ETH',
    decimals: 18,
    blockchain: 'Ethereum',
    derivation: [
      {
        path: "m/44'/60'/0'/0/0",
      },
    ],
    curve: 'secp256k1',
    publicKeyType: 'secp256k1Extended',
    addressHasher: 'keccak256',
    explorer: {
      url: 'https://arbiscan.io',
      txPath: '/tx',
      accountPath: '/address/',
      sampleTx: '',
      sampleAccount: '',
    },
    info: {
      url: 'https://arbitrum.io',
      source: 'https://github.com/OffchainLabs/arbitrum',
      rpc: 'https://node.bitverse.zone/bw/api/web3/node/arbi',
      documentation: 'https://developer.offchainlabs.com',
    },
    iconUrl: 'https://pic.bitverse.zone/chain_images/arbitrum.png',
    coinIconUrl: 'https://pic.bitverse.zone/token_images/1/eth.png',
    networkType: 'MAINNET',
    order: 5,
    popularity: 0,
    networkId: '42161',
    denom: 'wei',
  },
  {
    id: 'optimism',
    name: 'Optimism',
    displayName: 'Optimism Ethereum',
    coinId: 10000070,
    slip44: 60,
    symbol: 'ETH',
    decimals: 18,
    blockchain: 'Ethereum',
    derivation: [
      {
        path: "m/44'/60'/0'/0/0",
      },
    ],
    curve: 'secp256k1',
    publicKeyType: 'secp256k1Extended',
    chainId: '10',
    addressHasher: 'keccak256',
    explorer: {
      url: 'https://optimistic.etherscan.io',
      txPath: '/tx',
      accountPath: '/address/',
      sampleTx: '',
      sampleAccount: '',
    },
    info: {
      url: 'https://optimism.io/',
      source: 'https://github.com/ethereum-optimism/optimism',
      rpc: 'https://node.bitverse.zone/bw/api/web3/node/op',
      documentation: 'https://eth.wiki/json-rpc/API',
    },
    iconUrl: 'https://pic.bitverse.zone/chain_images/optimism.png',
    coinIconUrl: 'https://pic.bitverse.zone/token_images/1/eth.png',
    networkType: 'MAINNET',
    order: 6,
    popularity: 0,
    networkId: '10',
    denom: 'wei',
  },
  {
    id: 'avalanchec',
    name: 'Avalanche C-Chain',
    coinId: 10009000,
    symbol: 'AVAX',
    decimals: 18,
    blockchain: 'Ethereum',
    derivation: [
      {
        path: "m/44'/60'/0'/0/0",
      },
    ],
    curve: 'secp256k1',
    publicKeyType: 'secp256k1Extended',
    chainId: '43114',
    addressHasher: 'keccak256',
    explorer: {
      url: 'https://snowtrace.io',
      txPath: '/tx',
      accountPath: '/address/',
      sampleTx:
        '0x9243890b844219accefd8798271052f5a056453ec18984a56e81c92921330d54',
      sampleAccount: '0xa664325f36Ec33E66323fe2620AF3f2294b2Ef3A',
    },
    info: {
      url: 'https://www.avalabs.org/',
      client: 'https://github.com/ava-labs/avalanchego',
      clientPublic: 'https://api.avax.network/ext/bc/C/rpc',
      clientDocs: 'https://docs.avax.network/',
      rpc: 'https://node.bitverse.zone/bw/api/web3/node/avax',
    },
    iconUrl: 'https://pic.bitverse.zone/token_images/43114/avax.png',
    networkType: 'MAINNET',
    order: 7,
    popularity: 0,
    networkId: '43114',
    denom: 'wei',
  },
  {
    id: 'cronos',
    name: 'Cronos Chain',
    coinId: 10000025,
    symbol: 'CRO',
    decimals: 18,
    blockchain: 'Ethereum',
    derivation: [
      {
        path: "m/44'/60'/0'/0/0",
      },
    ],
    curve: 'secp256k1',
    publicKeyType: 'secp256k1Extended',
    chainId: '25',
    addressHasher: 'keccak256',
    explorer: {
      url: 'https://cronoscan.com',
      txPath: '/tx',
      accountPath: '/address/',
      sampleTx: '',
      sampleAccount: '',
    },
    info: {
      url: 'https://cronos.org',
      client: 'https://github.com/crypto-org-chain/cronos',
      clientPublic: 'https://evm-cronos.crypto.org',
      clientDocs: 'https://eth.wiki/json-rpc/API',
      rpc: 'https://evm.cronos.org/',
    },
    iconUrl: 'https://pic.bitverse.zone/token_images/25/cro.png',
    networkType: 'MAINNET',
    order: 8,
    popularity: 0,
    networkId: '25',
    denom: 'wei',
  },
  {
    id: 'kcc',
    name: 'KuCoin Community Chain',
    coinId: 10000321,
    symbol: 'KCS',
    decimals: 18,
    blockchain: 'Ethereum',
    derivation: [
      {
        path: "m/44'/60'/0'/0/0",
      },
    ],
    curve: 'secp256k1',
    publicKeyType: 'secp256k1Extended',
    chainId: '321',
    addressHasher: 'keccak256',
    explorer: {
      url: 'https://explorer.kcc.io/en',
      txPath: '/tx',
      accountPath: '/address/',
      sampleTx:
        '0x2f0d79cd289a02f3181b68b9583a64c3809fe7387810b274275985c29d02c80d',
      sampleAccount: '0x4446fc4eb47f2f6586f9faab68b3498f86c07521',
    },
    info: {
      url: 'https://www.kcc.io/',
      source: 'https://github.com/kcc-community/kcc',
      rpc: 'https://rpc-mainnet.kcc.network',
      documentation: 'https://docs.kcc.io/#/en-us/',
    },
    iconUrl: 'https://pic.bitverse.zone/chain_images/kcc.png',
    networkType: 'MAINNET',
    order: 9,
    popularity: 0,
    networkId: '321',
    denom: 'wei',
  },
  {
    id: 'boba',
    name: 'Boba',
    coinId: 10000288,
    symbol: 'ETH',
    decimals: 18,
    blockchain: 'Ethereum',
    derivation: [
      {
        path: "m/44'/60'/0'/0/0",
      },
    ],
    curve: 'secp256k1',
    publicKeyType: 'secp256k1Extended',
    chainId: '288',
    addressHasher: 'keccak256',
    explorer: {
      url: 'https://blockexplorer.boba.network',
      txPath: '/tx',
      accountPath: '/address/',
      sampleTx:
        '0x31533707c3feb3b10f7deeea387ff8893f229253e65ca6b14d2400bf95b5d103',
      sampleAccount: '0x4F96F50eDB37a19216d87693E5dB241e31bD3735',
    },
    info: {
      url: 'https://boba.network/',
      source: 'https://github.com/bobanetwork/boba',
      rpc: 'https://mainnet.boba.network',
      documentation: 'https://docs.boba.network/',
    },
    iconUrl: 'https://pic.bitverse.zone/chain_images/boba.png',
    coinIconUrl: 'https://pic.bitverse.zone/token_images/1/eth.png',
    networkType: 'MAINNET',
    order: 10,
    popularity: 0,
    networkId: '288',
    denom: 'wei',
  },
  {
    id: 'bitgert',
    name: 'Bitgert',
    coinId: 10032520,
    symbol: 'BRISE',
    decimals: 18,
    blockchain: 'Ethereum',
    derivation: [
      {
        path: "m/44'/60'/0'/0/0",
      },
    ],
    curve: 'secp256k1',
    publicKeyType: 'secp256k1Extended',
    chainId: '32520',
    addressHasher: 'keccak256',
    explorer: {
      url: 'https://brisescan.com',
      txPath: '/tx',
      accountPath: '/address/',
      sampleTx:
        '0x0247f616cb1d371fa45384da06c0fe70442676fad75773a3f808d03cc761417a',
      sampleAccount: '0x5f30eD8C6623BAFF9B4f73DDC8Cd524E7E99Ce85',
    },
    info: {
      url: 'https://bitgert.com',
      source: 'https://github.com/bitgert',
      rpc: 'https://mainnet-rpc.brisescan.com/',
      documentation: 'https://bitgert.zendesk.com/hc/en-us',
    },
    iconUrl: 'https://pic.bitverse.zone/token_images/32520/brise.png',
    coinIconUrl: 'https://pic.bitverse.zone/token_images/32520/brise.png',
    networkType: 'MAINNET',
    order: 11,
    popularity: 0,
    networkId: '32520',
    denom: 'wei',
  },
  {
    id: 'zksync',
    name: 'zkSync Era',
    displayName: 'zkSync Era',
    coinId: 10000324,
    slip44: 60,
    symbol: 'ETH',
    decimals: 18,
    blockchain: 'Ethereum',
    derivation: [
      {
        path: "m/44'/60'/0'/0/0",
      },
    ],
    curve: 'secp256k1',
    publicKeyType: 'secp256k1Extended',
    chainId: '324',
    addressHasher: 'keccak256',
    explorer: {
      url: 'https://explorer.zksync.io',
      txPath: '/tx/',
      accountPath: '/address/',
      sampleTx:
        '0x4216de9cd96dd9a7f02057ac38326e08e46ed411508e0133f093f5c942144ba8',
      sampleAccount: '0x3Be4F6d69625969158d8a1d8395b36F451425475',
    },
    info: {
      url: 'https://portal.zksync.io/',
      source: 'https://github.com/matter-labs/zksync',
      rpc: 'https://mainnet.era.zksync.io',
      documentation: 'https://era.zksync.io/docs',
    },
    iconUrl: 'https://pic.bitverse.zone/chain_images/zksync.png',
    networkType: 'MAINNET',
    order: 12,
    popularity: 0,
    networkId: '324',
    denom: 'wei',
  },
  {
    id: 'fantom',
    name: 'Fantom',
    coinId: 10000250,
    symbol: 'FTM',
    decimals: 18,
    blockchain: 'Ethereum',
    derivation: [
      {
        path: "m/44'/60'/0'/0/0",
      },
    ],
    curve: 'secp256k1',
    publicKeyType: 'secp256k1Extended',
    chainId: '250',
    addressHasher: 'keccak256',
    explorer: {
      url: 'https://ftmscan.com',
      txPath: '/tx/',
      accountPath: '/address/',
      sampleTx:
        '0xb0a741d882291951de1fac72e90b9baf886ddb0c9c87658a0c206490dfaa5202',
      sampleAccount: '0x9474feb9917b87da6f0d830ba66ee0035835c0d3',
    },
    info: {
      url: 'https://fantom.foundation',
      client: 'https://github.com/openethereum/openethereum',
      clientPublic: 'https://rpc.ftm.tools',
      clientDocs: 'https://eth.wiki/json-rpc/API',
      rpc: 'https://rpcapi.fantom.network',
    },
    iconUrl: 'https://pic.bitverse.zone/token_images/250/ftm.png',
    coinIconUrl: 'https://pic.bitverse.zone/token_images/250/ftm.png',
    networkType: 'MAINNET',
    order: 15,
    popularity: 0,
    networkId: '250',
    denom: 'wei',
  },
];

export const defaultNetwork = supportNetworks[0];

export default supportNetworks;
