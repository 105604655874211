import { Fragment, useContext, useEffect, useState } from 'react';
import {
  Button,
  Toast,
  Mask,
  SpinLoading,
  AutoCenter,
  DotLoading,
} from 'antd-mobile';
import { useImmer } from 'use-immer';
import { ethers } from 'ethers';
import copy from 'copy-to-clipboard';

import {
  recoverSpenderAndAmount,
  getGasLimit,
  BWWalletService,
  getProvider,
  sendContract,
} from '@bitverse-h5-wallet/bitverse-core';
import { eventBus } from '@bitverse-h5-wallet/bitverse-message-sdk';

import httpClient from '@bitverse-h5-wallet/bitverse-http-client';
import useNavigatePage from '../../hooks/use-navigate-page';
import { useCurrentNetwork } from '../../components/networks/useNetworks';

import { Header } from '../../layouts/Header';
import copyBtn from '../../assets/copyBtn.svg';
import { PasswordPicker } from '../Password';

import './index.less';
import { ProviderContext } from '../../context';
import { NX_API_Base_URL } from '../../utils';
import Decimal from 'decimal.js';
import { BitverseEnum } from '@bitverse-h5-wallet/bitverse-dapp-sdk';
import { useNavigate } from 'react-router-dom';

interface Form {
  address: string;
  amount: string;
}

const BLANK_IMAGE_URL =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAOklEQVR42mP4z8DwHwEF/wPAmcB8z+AbXABVyA8FYQBoIaQA5wIyrgE8n/wYFRiQimQAAAABJRU5ErkJggg=';

const SendContract = () => {
  const networks = useCurrentNetwork();
  const [form, updateForm] = useImmer<Form>({
    address: '',
    amount: '',
  });
  const [tokenLoading, setTokenLoading] = useState(false);
  const [txLoading, setTxLoading] = useState(false);

  const [token, setToken] = useState<any>({});
  const [gas, setGas] = useState('');
  const [pwdVisible, setPwdVisible] = useState(false);

  const { globalState } = useContext(ProviderContext);
  const [auth, setAuth] = useState<any>();
  const [gasLoading, setGasLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setAuth(globalState?.navigateParams);
  }, [globalState?.navigateParams]);

  const account = BWWalletService.instance.getCurrentWallet();

  const setGasLimit = async (value?: string) => {
    setGasLoading(true);
    try {
      const gasLimit = value || (await getGasLimit(auth));
      const provider = getProvider();
      const feeData = await provider.getFeeData();
      const { gasPrice } = feeData;
      const total = ethers.utils.formatEther(gasPrice?.mul(gasLimit));
      setGas(total);
      setGasLoading(false);
    } catch (e) {
      console.log('e', e)
      Toast.show({
        icon: 'fail',
        content: 'Cannot estimate gas',
        position: 'top',
      });
      eventBus.emit(
        BitverseEnum.ethSendTransaction,
        {},
        'Cannot estimate gas!'
      );
    }
  };

  useEffect(() => {
    if (Object.keys(auth || {}).length) {
      // 合约调用
      console.log('auth', auth)
      handleContractDetail({
        coinId: networks.coinId || 0,
        chainId: networks.chainId || 0,
        contractAddress:   auth.value  ? '' : auth.to,
      }).then((token) => {
        // 解析 data
        console.log('handleContractDetail.token', token, {
          auth,
          token
        });
        let amount;
        if (auth.value) {
          const ori = eval(auth.value).toString(10);
          amount = new Decimal(ori)
            .div(Math.pow(10, token.decimals))
            .toNumber();
        } else {
          try {
            const { amount: _amount } = recoverSpenderAndAmount(
              auth?.data,
              token?.decimals
            );
            amount = _amount;
          } catch {
            amount = 0;
          }
        }
        updateForm((draft) => {
          draft.address = auth.to;
          draft.amount = amount;
        });
        setGasLimit(auth?.gas);
      });
    }
  }, [auth]);

  const handleContractDetail = async ({
    coinId,
    chainId,
    contractAddress,
  }: {
    coinId: number;
    chainId: number | string;
    contractAddress: string;
  }) => {
    setTokenLoading(true);

    try {
      const rtn = await httpClient.post(
        `${NX_API_Base_URL}/bitverse/wallet/v1/public/asset/token/batchQuery`,
        {
          includeBalance: true,
          list: {
            coinId: coinId,
            chainId,
            contract: contractAddress, // 合约地址
            address: account.address,
          },
        }
      );
      const { result, retCode } = rtn;
      if (retCode === 0) {
        setToken(result.result?.[0] || {});
        setTokenLoading(false);
        return result.result?.[0] || {};
      }
    } catch (error) {
      console.log('[response error]: ', error);
    }
    setTokenLoading(false);
  };

  const onSubmit = async () => {
    setPwdVisible(false);
    setTxLoading(true);
    const transactionHash = await sendContract(auth);
    if (transactionHash) {
      Toast.show({
        icon: 'success',
        content: 'Transaction Successful',
        position: 'top',
      });
      eventBus.emit(BitverseEnum.ethSendTransaction, transactionHash);
    } else {
      eventBus.emit(BitverseEnum.ethSendTransaction, {}, 'Send failed!');
      Toast.show({
        icon: 'fail',
        content: 'Transaction Failed',
        position: 'top',
      });
    }
  };

  const handleCopy = (txt: string) => {
    if (copy(txt)) {
      Toast.show({
        content: 'Copy Successfully!',
        position: 'top',
      });
    } else {
      Toast.show({
        content: 'Copy failed, please manually select and right-click to copy.',
        position: 'top',
      });
    }
  };

  const onCancelBack = () => {
    eventBus.emit(BitverseEnum.ethSendTransaction, {}, 'User Cancel!');
    setTimeout(() => {
      navigate(-1);
    }, 300);
  };

  return (
    <Fragment>
      <Header pageName="Send" back={null} />
      <div className="pd16 send-container send-popup">
        {token && (
          <div className="popup-title">
            <img src={token.iconUrl || BLANK_IMAGE_URL} alt="" />
            <div>{token.symbol}</div>
          </div>
        )}
        <div className="popup-line">
          <div className="t2">Recipient</div>
          <div>{form.address}</div>
          <img onClick={() => handleCopy(form.address)} src={copyBtn} alt="" />
        </div>
        <div className="popup-line">
          <div className="t2">Quantity</div>
          <div>{`${form.amount} ${token.symbol || '--'}`}</div>
        </div>
        <div className="popup-line">
          <div className="t2">Gas</div>
          {gasLoading ? (
            <DotLoading color="primary" />
          ) : (
            <div>{`${gas} ${networks.symbol}`}</div>
          )}
        </div>
        <Button
          color="primary"
          className="w100 mt32 mb24"
          fill="solid"
          size="large"
          onClick={() => {
            setPwdVisible(true);
          }}
          disabled={!gas}
        >
          Confirm
        </Button>
        <Button className="w100" size="large" onClick={onCancelBack}>
          Cancel
        </Button>
      </div>
      <PasswordPicker
        visible={pwdVisible}
        onConfirm={onSubmit}
        onClose={() => setPwdVisible(false)}
      />

      <Mask visible={txLoading || tokenLoading}>
        <div className="tx-loading">
          <AutoCenter>
            <SpinLoading />
          </AutoCenter>
        </div>
      </Mask>
    </Fragment>
  );
};

export default SendContract;
