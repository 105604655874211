import { startWorkers } from "./snippets/wasm-bindgen-rayon-7afa899f36665473/src/workerHelpers.js";

let wasm;

const cachedTextDecoder =
  typeof TextDecoder !== "undefined"
    ? new TextDecoder("utf-8", { ignoreBOM: true, fatal: true })
    : {
        decode: () => {
          throw Error("TextDecoder not available");
        },
      };

if (typeof TextDecoder !== "undefined") {
  cachedTextDecoder.decode();
}

let cachedUint8Memory0 = null;

function getUint8Memory0() {
  if (
    cachedUint8Memory0 === null ||
    cachedUint8Memory0.buffer !== wasm.memory.buffer
  ) {
    cachedUint8Memory0 = new Uint8Array(wasm.memory.buffer);
  }
  return cachedUint8Memory0;
}

function getStringFromWasm0(ptr, len) {
  ptr = ptr >>> 0;
  return cachedTextDecoder.decode(getUint8Memory0().slice(ptr, ptr + len));
}

const heap = new Array(128).fill(undefined);

heap.push(undefined, null, true, false);

let heap_next = heap.length;

function addHeapObject(obj) {
  if (heap_next === heap.length) heap.push(heap.length + 1);
  const idx = heap_next;
  heap_next = heap[idx];

  heap[idx] = obj;
  return idx;
}

function getObject(idx) {
  return heap[idx];
}

let WASM_VECTOR_LEN = 0;

const cachedTextEncoder =
  typeof TextEncoder !== "undefined"
    ? new TextEncoder("utf-8")
    : {
        encode: () => {
          throw Error("TextEncoder not available");
        },
      };

const encodeString = function (arg, view) {
  const buf = cachedTextEncoder.encode(arg);
  view.set(buf);
  return {
    read: arg.length,
    written: buf.length,
  };
};

function passStringToWasm0(arg, malloc, realloc) {
  if (realloc === undefined) {
    const buf = cachedTextEncoder.encode(arg);
    const ptr = malloc(buf.length) >>> 0;
    getUint8Memory0()
      .subarray(ptr, ptr + buf.length)
      .set(buf);
    WASM_VECTOR_LEN = buf.length;
    return ptr;
  }

  let len = arg.length;
  let ptr = malloc(len) >>> 0;

  const mem = getUint8Memory0();

  let offset = 0;

  for (; offset < len; offset++) {
    const code = arg.charCodeAt(offset);
    if (code > 0x7f) break;
    mem[ptr + offset] = code;
  }

  if (offset !== len) {
    if (offset !== 0) {
      arg = arg.slice(offset);
    }
    ptr = realloc(ptr, len, (len = offset + arg.length * 3)) >>> 0;
    const view = getUint8Memory0().subarray(ptr + offset, ptr + len);
    const ret = encodeString(arg, view);

    offset += ret.written;
  }

  WASM_VECTOR_LEN = offset;
  return ptr;
}

let cachedInt32Memory0 = null;

function getInt32Memory0() {
  if (
    cachedInt32Memory0 === null ||
    cachedInt32Memory0.buffer !== wasm.memory.buffer
  ) {
    cachedInt32Memory0 = new Int32Array(wasm.memory.buffer);
  }
  return cachedInt32Memory0;
}

function dropObject(idx) {
  if (idx < 132) return;
  heap[idx] = heap_next;
  heap_next = idx;
}

function takeObject(idx) {
  const ret = getObject(idx);
  dropObject(idx);
  return ret;
}

function isLikeNone(x) {
  return x === undefined || x === null;
}
/**
 * @param {any} encrypted_private_key
 * @param {any} c_master_key_json
 * @returns {any}
 */
export function ecdsa_ecc_server_key_backup_verify(
  encrypted_private_key,
  c_master_key_json
) {
  try {
    const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
    wasm.ecdsa_ecc_server_key_backup_verify(
      retptr,
      addHeapObject(encrypted_private_key),
      addHeapObject(c_master_key_json)
    );
    var r0 = getInt32Memory0()[retptr / 4 + 0];
    var r1 = getInt32Memory0()[retptr / 4 + 1];
    var r2 = getInt32Memory0()[retptr / 4 + 2];
    if (r2) {
      throw takeObject(r1);
    }
    return takeObject(r0);
  } finally {
    wasm.__wbindgen_add_to_stack_pointer(16);
  }
}

/**
 * @param {any} plaintext
 * @param {any} public_key_str
 * @returns {any}
 */
export function ecdsa_ecc_encrypt(plaintext, public_key_str) {
  try {
    const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
    wasm.ecdsa_ecc_encrypt(
      retptr,
      addHeapObject(plaintext),
      addHeapObject(public_key_str)
    );
    var r0 = getInt32Memory0()[retptr / 4 + 0];
    var r1 = getInt32Memory0()[retptr / 4 + 1];
    var r2 = getInt32Memory0()[retptr / 4 + 2];
    if (r2) {
      throw takeObject(r1);
    }
    return takeObject(r0);
  } finally {
    wasm.__wbindgen_add_to_stack_pointer(16);
  }
}

/**
 * @param {any} c_id
 * @returns {any}
 */
export function ecdsa_build_sign_first_request(c_id) {
  try {
    const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
    wasm.ecdsa_build_sign_first_request(retptr, addHeapObject(c_id));
    var r0 = getInt32Memory0()[retptr / 4 + 0];
    var r1 = getInt32Memory0()[retptr / 4 + 1];
    var r2 = getInt32Memory0()[retptr / 4 + 2];
    if (r2) {
      throw takeObject(r1);
    }
    return takeObject(r0);
  } finally {
    wasm.__wbindgen_add_to_stack_pointer(16);
  }
}

/**
 * @param {any} c_message_le_hex
 * @param {any} c_master_key_json
 * @param {any} c_x_pos
 * @param {any} c_y_pos
 * @param {any} c_id
 * @param {any} c_sign_party_one_first_message
 * @param {any} c_eph_comm_witness
 * @param {any} c_eph_ec_key_pair_party2
 * @returns {any}
 */
export function ecdsa_sign_first_handle(
  c_message_le_hex,
  c_master_key_json,
  c_x_pos,
  c_y_pos,
  c_id,
  c_sign_party_one_first_message,
  c_eph_comm_witness,
  c_eph_ec_key_pair_party2
) {
  try {
    const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
    wasm.ecdsa_sign_first_handle(
      retptr,
      addHeapObject(c_message_le_hex),
      addHeapObject(c_master_key_json),
      addHeapObject(c_x_pos),
      addHeapObject(c_y_pos),
      addHeapObject(c_id),
      addHeapObject(c_sign_party_one_first_message),
      addHeapObject(c_eph_comm_witness),
      addHeapObject(c_eph_ec_key_pair_party2)
    );
    var r0 = getInt32Memory0()[retptr / 4 + 0];
    var r1 = getInt32Memory0()[retptr / 4 + 1];
    var r2 = getInt32Memory0()[retptr / 4 + 2];
    if (r2) {
      throw takeObject(r1);
    }
    return takeObject(r0);
  } finally {
    wasm.__wbindgen_add_to_stack_pointer(16);
  }
}

/**
 * @param {any} c_id
 * @returns {any}
 */
export function ecdsa_keygen_first_handle(c_id) {
  try {
    const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
    wasm.ecdsa_keygen_first_handle(retptr, addHeapObject(c_id));
    var r0 = getInt32Memory0()[retptr / 4 + 0];
    var r1 = getInt32Memory0()[retptr / 4 + 1];
    var r2 = getInt32Memory0()[retptr / 4 + 2];
    if (r2) {
      throw takeObject(r1);
    }
    return takeObject(r0);
  } finally {
    wasm.__wbindgen_add_to_stack_pointer(16);
  }
}

/**
 * @param {any} c_id
 * @param {any} c_kg_party_one_first_message
 * @param {any} c_kg_party_one_second_message
 * @returns {any}
 */
export function ecdsa_keygen_second_handle(
  c_id,
  c_kg_party_one_first_message,
  c_kg_party_one_second_message
) {
  try {
    const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
    wasm.ecdsa_keygen_second_handle(
      retptr,
      addHeapObject(c_id),
      addHeapObject(c_kg_party_one_first_message),
      addHeapObject(c_kg_party_one_second_message)
    );
    var r0 = getInt32Memory0()[retptr / 4 + 0];
    var r1 = getInt32Memory0()[retptr / 4 + 1];
    var r2 = getInt32Memory0()[retptr / 4 + 2];
    if (r2) {
      throw takeObject(r1);
    }
    return takeObject(r0);
  } finally {
    wasm.__wbindgen_add_to_stack_pointer(16);
  }
}

/**
 * @param {any} c_id
 * @returns {any}
 */
export function ecdsa_chaincode_first_handle(c_id) {
  try {
    const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
    wasm.ecdsa_chaincode_first_handle(retptr, addHeapObject(c_id));
    var r0 = getInt32Memory0()[retptr / 4 + 0];
    var r1 = getInt32Memory0()[retptr / 4 + 1];
    var r2 = getInt32Memory0()[retptr / 4 + 2];
    if (r2) {
      throw takeObject(r1);
    }
    return takeObject(r0);
  } finally {
    wasm.__wbindgen_add_to_stack_pointer(16);
  }
}

/**
 * @param {any} c_id
 * @param {any} c_party_two_paillier
 * @param {any} c_kg_party_one_second_message
 * @param {any} c_cc_party_one_first_message
 * @param {any} c_cc_ec_key_pair2
 * @param {any} c_kg_ec_key_pair_party2
 * @param {any} c_cc_party_one_second_message
 * @returns {any}
 */
export function ecdsa_chaincode_second_handle_and_return_master_key(
  c_id,
  c_party_two_paillier,
  c_kg_party_one_second_message,
  c_cc_party_one_first_message,
  c_cc_ec_key_pair2,
  c_kg_ec_key_pair_party2,
  c_cc_party_one_second_message
) {
  try {
    const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
    wasm.ecdsa_chaincode_second_handle_and_return_master_key(
      retptr,
      addHeapObject(c_id),
      addHeapObject(c_party_two_paillier),
      addHeapObject(c_kg_party_one_second_message),
      addHeapObject(c_cc_party_one_first_message),
      addHeapObject(c_cc_ec_key_pair2),
      addHeapObject(c_kg_ec_key_pair_party2),
      addHeapObject(c_cc_party_one_second_message)
    );
    var r0 = getInt32Memory0()[retptr / 4 + 0];
    var r1 = getInt32Memory0()[retptr / 4 + 1];
    var r2 = getInt32Memory0()[retptr / 4 + 2];
    if (r2) {
      throw takeObject(r1);
    }
    return takeObject(r0);
  } finally {
    wasm.__wbindgen_add_to_stack_pointer(16);
  }
}

/**
 * @param {any} c_party2_public_key_json
 * @returns {any}
 */
export function get_public_share_key(c_party2_public_key_json) {
  try {
    const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
    wasm.get_public_share_key(retptr, addHeapObject(c_party2_public_key_json));
    var r0 = getInt32Memory0()[retptr / 4 + 0];
    var r1 = getInt32Memory0()[retptr / 4 + 1];
    var r2 = getInt32Memory0()[retptr / 4 + 2];
    if (r2) {
      throw takeObject(r1);
    }
    return takeObject(r0);
  } finally {
    wasm.__wbindgen_add_to_stack_pointer(16);
  }
}

/**
 * @param {any} c_master_key_json
 * @param {any} c_x_pos
 * @param {any} c_y_pos
 * @returns {any}
 */
export function get_public_share_key_with_derive(
  c_master_key_json,
  c_x_pos,
  c_y_pos
) {
  try {
    const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
    wasm.get_public_share_key_with_derive(
      retptr,
      addHeapObject(c_master_key_json),
      addHeapObject(c_x_pos),
      addHeapObject(c_y_pos)
    );
    var r0 = getInt32Memory0()[retptr / 4 + 0];
    var r1 = getInt32Memory0()[retptr / 4 + 1];
    var r2 = getInt32Memory0()[retptr / 4 + 2];
    if (r2) {
      throw takeObject(r1);
    }
    return takeObject(r0);
  } finally {
    wasm.__wbindgen_add_to_stack_pointer(16);
  }
}

/**
 * @param {any} c_master_key_json
 * @param {any} c_x_pos
 * @param {any} c_y_pos
 * @returns {any}
 */
export function key_derive(c_master_key_json, c_x_pos, c_y_pos) {
  try {
    const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
    wasm.key_derive(
      retptr,
      addHeapObject(c_master_key_json),
      addHeapObject(c_x_pos),
      addHeapObject(c_y_pos)
    );
    var r0 = getInt32Memory0()[retptr / 4 + 0];
    var r1 = getInt32Memory0()[retptr / 4 + 1];
    var r2 = getInt32Memory0()[retptr / 4 + 2];
    if (r2) {
      throw takeObject(r1);
    }
    return takeObject(r0);
  } finally {
    wasm.__wbindgen_add_to_stack_pointer(16);
  }
}

/**
 * @param {any} name
 * @returns {any}
 */
export function test(name) {
  try {
    const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
    wasm.test(retptr, addHeapObject(name));
    var r0 = getInt32Memory0()[retptr / 4 + 0];
    var r1 = getInt32Memory0()[retptr / 4 + 1];
    var r2 = getInt32Memory0()[retptr / 4 + 2];
    if (r2) {
      throw takeObject(r1);
    }
    return takeObject(r0);
  } finally {
    wasm.__wbindgen_add_to_stack_pointer(16);
  }
}

/**
 * @param {any} data
 * @returns {any}
 */
export function test_key_gen_first_msg(data) {
  try {
    const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
    wasm.test_key_gen_first_msg(retptr, addHeapObject(data));
    var r0 = getInt32Memory0()[retptr / 4 + 0];
    var r1 = getInt32Memory0()[retptr / 4 + 1];
    var r2 = getInt32Memory0()[retptr / 4 + 2];
    if (r2) {
      throw takeObject(r1);
    }
    return takeObject(r0);
  } finally {
    wasm.__wbindgen_add_to_stack_pointer(16);
  }
}

/**
 * @param {number} data_ptr
 */
export function free_char(data_ptr) {
  wasm.free_char(data_ptr);
}

function getArrayU8FromWasm0(ptr, len) {
  ptr = ptr >>> 0;
  return getUint8Memory0().subarray(ptr / 1, ptr / 1 + len);
}
/**
 * @param {number} num_threads
 * @returns {Promise<any>}
 */
export function initThreadPool(num_threads) {
  const ret = wasm.initThreadPool(num_threads);
  return takeObject(ret);
}

/**
 * @param {number} receiver
 */
export function wbg_rayon_start_worker(receiver) {
  wasm.wbg_rayon_start_worker(receiver);
}

/**
 */
export class wbg_rayon_PoolBuilder {
  static __wrap(ptr) {
    ptr = ptr >>> 0;
    const obj = Object.create(wbg_rayon_PoolBuilder.prototype);
    obj.__wbg_ptr = ptr;

    return obj;
  }

  __destroy_into_raw() {
    const ptr = this.__wbg_ptr;
    this.__wbg_ptr = 0;

    return ptr;
  }

  free() {
    const ptr = this.__destroy_into_raw();
    wasm.__wbg_wbg_rayon_poolbuilder_free(ptr);
  }
  /**
   * @returns {number}
   */
  numThreads() {
    const ret = wasm.wbg_rayon_poolbuilder_numThreads(this.__wbg_ptr);
    return ret >>> 0;
  }
  /**
   * @returns {number}
   */
  receiver() {
    const ret = wasm.wbg_rayon_poolbuilder_receiver(this.__wbg_ptr);
    return ret;
  }
  /**
   */
  build() {
    wasm.wbg_rayon_poolbuilder_build(this.__wbg_ptr);
  }
}

async function __wbg_load(module, imports) {
  if (typeof Response === "function" && module instanceof Response) {
    if (typeof WebAssembly.instantiateStreaming === "function") {
      try {
        return await WebAssembly.instantiateStreaming(module, imports);
      } catch (e) {
        if (module.headers.get("Content-Type") != "application/wasm") {
          console.warn(
            "`WebAssembly.instantiateStreaming` failed because your server does not serve wasm with `application/wasm` MIME type. Falling back to `WebAssembly.instantiate` which is slower. Original error:\n",
            e
          );
        } else {
          throw e;
        }
      }
    }

    const bytes = await module.arrayBuffer();
    return await WebAssembly.instantiate(bytes, imports);
  } else {
    const instance = await WebAssembly.instantiate(module, imports);

    if (instance instanceof WebAssembly.Instance) {
      return { instance, module };
    } else {
      return instance;
    }
  }
}

function __wbg_get_imports() {
  const imports = {};
  imports.wbg = {};
  imports.wbg.__wbindgen_json_parse = function (arg0, arg1) {
    const ret = JSON.parse(getStringFromWasm0(arg0, arg1));
    return addHeapObject(ret);
  };
  imports.wbg.__wbindgen_json_serialize = function (arg0, arg1) {
    const obj = getObject(arg1);
    const ret = JSON.stringify(obj === undefined ? null : obj);
    const ptr1 = passStringToWasm0(
      ret,
      wasm.__wbindgen_malloc,
      wasm.__wbindgen_realloc
    );
    const len1 = WASM_VECTOR_LEN;
    getInt32Memory0()[arg0 / 4 + 1] = len1;
    getInt32Memory0()[arg0 / 4 + 0] = ptr1;
  };
  imports.wbg.__wbindgen_error_new = function (arg0, arg1) {
    const ret = new Error(getStringFromWasm0(arg0, arg1));
    return addHeapObject(ret);
  };
  imports.wbg.__wbindgen_object_drop_ref = function (arg0) {
    takeObject(arg0);
  };
  imports.wbg.__wbindgen_string_get = function (arg0, arg1) {
    const obj = getObject(arg1);
    const ret = typeof obj === "string" ? obj : undefined;
    var ptr1 = isLikeNone(ret)
      ? 0
      : passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    var len1 = WASM_VECTOR_LEN;
    getInt32Memory0()[arg0 / 4 + 1] = len1;
    getInt32Memory0()[arg0 / 4 + 0] = ptr1;
  };
  imports.wbg.__wbg_new_abda76e883ba8a5f = function () {
    const ret = new Error();
    return addHeapObject(ret);
  };
  imports.wbg.__wbg_stack_658279fe44541cf6 = function (arg0, arg1) {
    const ret = getObject(arg1).stack;
    const ptr1 = passStringToWasm0(
      ret,
      wasm.__wbindgen_malloc,
      wasm.__wbindgen_realloc
    );
    const len1 = WASM_VECTOR_LEN;
    getInt32Memory0()[arg0 / 4 + 1] = len1;
    getInt32Memory0()[arg0 / 4 + 0] = ptr1;
  };
  imports.wbg.__wbg_error_f851667af71bcfc6 = function (arg0, arg1) {
    let deferred0_0;
    let deferred0_1;
    try {
      deferred0_0 = arg0;
      deferred0_1 = arg1;
      console.error(getStringFromWasm0(arg0, arg1));
    } finally {
      wasm.__wbindgen_free(deferred0_0, deferred0_1);
    }
  };
  imports.wbg.__wbg_new_d87f272aec784ec0 = function (arg0, arg1) {
    const ret = new Function(getStringFromWasm0(arg0, arg1));
    return addHeapObject(ret);
  };
  imports.wbg.__wbg_call_eae29933372a39be = function (arg0, arg1) {
    const ret = getObject(arg0).call(getObject(arg1));
    return addHeapObject(ret);
  };
  imports.wbg.__wbindgen_jsval_eq = function (arg0, arg1) {
    const ret = getObject(arg0) === getObject(arg1);
    return ret;
  };
  imports.wbg.__wbg_self_e0b3266d2d9eba1a = function (arg0) {
    const ret = getObject(arg0).self;
    return addHeapObject(ret);
  };
  imports.wbg.__wbg_crypto_e95a6e54c5c2e37f = function (arg0) {
    const ret = getObject(arg0).crypto;
    return addHeapObject(ret);
  };
  imports.wbg.__wbindgen_is_undefined = function (arg0) {
    const ret = getObject(arg0) === undefined;
    return ret;
  };
  imports.wbg.__wbg_getRandomValues_dc67302a7bd1aec5 = function (arg0) {
    const ret = getObject(arg0).getRandomValues;
    return addHeapObject(ret);
  };
  imports.wbg.__wbg_require_0993fe224bf8e202 = function (arg0, arg1) {
    const ret = require(getStringFromWasm0(arg0, arg1));
    return addHeapObject(ret);
  };
  imports.wbg.__wbg_randomFillSync_dd2297de5917c74e = function (
    arg0,
    arg1,
    arg2
  ) {
    getObject(arg0).randomFillSync(getArrayU8FromWasm0(arg1, arg2));
  };
  imports.wbg.__wbg_getRandomValues_02639197c8166a96 = function (
    arg0,
    arg1,
    arg2
  ) {
    getObject(arg0).getRandomValues(getArrayU8FromWasm0(arg1, arg2));
  };
  imports.wbg.__wbindgen_throw = function (arg0, arg1) {
    throw new Error(getStringFromWasm0(arg0, arg1));
  };
  imports.wbg.__wbindgen_module = function () {
    const ret = __wbg_init.__wbindgen_wasm_module;
    return addHeapObject(ret);
  };
  imports.wbg.__wbindgen_memory = function () {
    const ret = wasm.memory;
    return addHeapObject(ret);
  };
  imports.wbg.__wbg_startWorkers_6fd3af285ea11136 = function (
    arg0,
    arg1,
    arg2
  ) {
    const ret = startWorkers(
      takeObject(arg0),
      takeObject(arg1),
      wbg_rayon_PoolBuilder.__wrap(arg2)
    );
    return addHeapObject(ret);
  };

  return imports;
}

function __wbg_init_memory(imports, maybe_memory) {
  imports.wbg.memory =
    maybe_memory ||
    new WebAssembly.Memory({ initial: 19, maximum: 16384, shared: true });
}

function __wbg_finalize_init(instance, module) {
  wasm = instance.exports;
  __wbg_init.__wbindgen_wasm_module = module;
  cachedInt32Memory0 = null;
  cachedUint8Memory0 = null;

  wasm.__wbindgen_start();
  return wasm;
}

function initSync(module, maybe_memory) {
  if (wasm !== undefined) return wasm;

  const imports = __wbg_get_imports();

  __wbg_init_memory(imports, maybe_memory);

  if (!(module instanceof WebAssembly.Module)) {
    module = new WebAssembly.Module(module);
  }

  const instance = new WebAssembly.Instance(module, imports);

  return __wbg_finalize_init(instance, module);
}

async function __wbg_init(input, maybe_memory) {
  if (wasm !== undefined) return wasm;

  if (typeof input === "undefined") {
    input = new URL("bitverse_core_wasm_bg.wasm", import.meta.url);
  }
  const imports = __wbg_get_imports();

  if (
    typeof input === "string" ||
    (typeof Request === "function" && input instanceof Request) ||
    (typeof URL === "function" && input instanceof URL)
  ) {
    input = fetch(input);
  }

  __wbg_init_memory(imports, maybe_memory);

  const { instance, module } = await __wbg_load(await input, imports);

  return __wbg_finalize_init(instance, module);
}

export { initSync };
export default __wbg_init;
