import { getProvider } from '@bitverse-h5-wallet/bitverse-core';
import React, { useCallback, Dispatch, useReducer } from 'react';

interface IState {
  address?: string;
  netWork?: string;
  navigateParams?: any;
}

const initValue: IState = {
  address: '',
  netWork: '',
  navigateParams: {},
};

interface IContextProviderProps {
  // bitverseProvider: any;
  jsonRpcProvider: any;
  globalState: any;
  globalDispatch: Dispatch<any>;
}

// export const reducer = (state = initState, action: any) => {};
export const ProviderContext = React.createContext<IContextProviderProps>({
  // bitverseProvider: undefined,
  jsonRpcProvider: undefined,
  globalState: undefined,
  globalDispatch: () => void 0,
});

interface DispatchValue {
  type: string;
  payload?: Partial<IState> | undefined;
}

export const ReducerContextProvider: (props: any) => JSX.Element = (
  props: any
) => {
  const reducer = useCallback((preState: any, action: DispatchValue) => {
    const { type, payload } = action;
    switch (type) {
      default:
        return preState;
      case 'get_address_action':
        return {
          ...preState,
          address: payload?.address,
        };
      case 'get_network_action':
        return {
          ...preState,
          netWork: payload?.netWork,
        };
      case 'get_navigate_action':
        return {
          ...preState,
          navigateParams: payload?.navigateParams,
        };
    }
  }, []);

  const [state, dispatch] = useReducer(reducer, initValue);
  return (
    <ProviderContext.Provider
      value={{
        // bitverseProvider,
        jsonRpcProvider: getProvider(),
        globalState: state,
        globalDispatch: dispatch,
      }}
    >
      {props.children}
    </ProviderContext.Provider>
  );
};
