export const baseUrlTestNet = 'http://api2.bitverse-dev-1.bitverse.zone';

export const baseURLMainNet = 'https://api.bitverse.zone';

export const envConfig = {
  testnet: {
    access_key: '5FdeE4CnNztmXLC9HE',
    secret: 'IAMbTXzdhPfKf4LIV0TLadtBgNT9zQu1YEsh/QWUvX0xCb2hoLlhnMWVP',
  },
  mainnet: {
    access_key: 'rjx8Har1KLXAVvw5aan5',
    secret: 'eyJhY3Rpdml0eUlkIjoiMTIzNDU2In0',
  },
};
