export enum BitverseEnum {
  ethRequestAccounts = 'eth_requestAccounts',
  ethAccounts = 'eth_accounts',
  ethChainId = 'eth_chainId',
  walletSwitchEthereumChain = 'wallet_switchEthereumChain',
  ethSignTypedData = 'eth_signTypedData',
  ethSignTypedDataV4 = 'eth_signTypedData_v4',
  personalSign = 'personal_sign',
  ethSendTransaction = 'eth_sendTransaction',
  ethSendTransactionApprove = 'eth_sendTransaction_approve',
  chainChanged = 'chainChanged',
  chainGet = 'chainGet',
  accountsChanged = 'accountsChanged',
}

export enum BitverseEnumLocal {
  dappSdkAccount = 'DAPP_SDK_ACCOUNT',
  dappChainId = 'DAPP_CHAIN_ID',
  dappRpcUrl = 'DAPP_RPC_URL',

  messageSdkMessage = 'MESSAGE_SDK_MESSAGE',
  messageSdkInit = 'MESSAGE_SDK_INIT',
  ethereumProviderSdkMessage = 'ETHEREUM_PROVIDER_SDK_MESSAGE',
  ENV = 'ENV',
}
