import { useEffect, useState, useContext } from 'react';
import { Button, Toast, Divider } from 'antd-mobile';
import { eventBus } from '@bitverse-h5-wallet/bitverse-message-sdk';
import {
  getAccountList,
  getCurrentAccount,
} from '@bitverse-h5-wallet/bitverse-core';
import { useCurrentNetwork } from '../../components/networks/useNetworks';
import { ProviderContext } from '../../context';
import bitverseLogo from '../../assets/images/bitverse-logo.svg';
import settingLogo from '../../assets/images/setting.png';
import ellipsisIcon from '../../assets/images/ellipsis-icon.svg';
import iconEye from '../../assets/images/icon_eye.svg';
import iconMenu1 from '../../assets/images/icon_menu1.svg';
import iconMenu2 from '../../assets/images/icon_menu2.svg';

import './login.less';
import copyBtn from '../../assets/copyBtn.svg';
import copy from 'copy-to-clipboard';
import { transferAddress2Display } from '../../utils';
import { BitverseEnum } from '@bitverse-h5-wallet/bitverse-dapp-sdk';

// ellipsis-icon.svg
export const Login = () => {
  const [originHost, setOriginHost] = useState<any>({});
  const [address, setAddress] = useState('');
  const networkInfo = useCurrentNetwork();
  const { globalState } = useContext(ProviderContext);
  const userEmail = localStorage.getItem('bitverse_user_info') || '{}';

  useEffect(() => {
    getCurrentAccount().then((item) => {
      setAddress(item);
    });
  }, []);

  useEffect(() => {
    setOriginHost(globalState.navigateParams);
  }, [globalState.navigateParams]);

  const handleCopy = (txt: string) => {
    if (copy(txt)) {
      Toast.show({
        content: 'Copy Successfully!',
        position: 'top',
      });
    } else {
      Toast.show({
        content: 'Copy failed, please manually select and right-click to copy.',
        position: 'top',
      });
    }
  };
  return (
    <div className="login-page pd16">
      <div className="header">
        <div className="account">
          <div>
            <img src={bitverseLogo} alt="" className="bitverse" />
          </div>
          <div className="user-info ml10">
            <div>{JSON.parse(userEmail).email}</div>
            <div className="address jcsb df">
              <div>{transferAddress2Display(address)}</div>
              <img onClick={() => handleCopy(address)} src={copyBtn} alt="" />
            </div>
          </div>
        </div>
        <div>
          <img src={settingLogo} alt="" />
        </div>
      </div>
      <div className="connect-logo">
        <div>
          <img src={bitverseLogo} alt="" className="product" />
        </div>
        <div>
          <img src={ellipsisIcon} alt="" />
        </div>
        <div>
          <img src={originHost.icon} alt="" className="product" />
        </div>
      </div>
      <div className="connect-header">
        <div className="auth">Authorize to Connect</div>
        <div className="url">{originHost.url}</div>
        <div className="tip">Request to connect to your Bitverse wallet</div>
        <Divider />

        <div className="content">
          <div className="content-item jcsb df">
            <img src={iconEye} alt="" />
            <div>
              This application will be able to view your address and email
            </div>
          </div>
          <div className="content-item jcsb df">
            <img src={iconMenu1} alt="" />
            <div>This application is not owned or operated by Bitverse</div>
          </div>
          <div className="content-item jcsb df">
            <img src={iconMenu2} alt="" />
            <div>
              This application cannot transfer your assets without your
              authorization
            </div>
          </div>
          <Divider />

          <div className="bottom-tip jcsb df">
            After connecting you will leave Bitverse and jump to:
            {originHost.url}
          </div>
        </div>
      </div>
      <div className="df jcsb">
        <Button
          color="primary"
          className="cancel-button login-btn"
          fill="outline"
          size="large"
          onClick={async () => {
            //todo 不知道怎么取消,我也不知道
            eventBus.emit(BitverseEnum.ethRequestAccounts, [], 'User Cancel');
          }}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          className="confirm-button login-btn"
          fill="solid"
          size="large"
          onClick={async () => {
            eventBus.emit(BitverseEnum.chainGet, networkInfo);
            eventBus.emit(BitverseEnum.ethRequestAccounts, [address]);
          }}
        >
          Connect
        </Button>
      </div>
    </div>
  );
};
