import React, { useState } from 'react';
import { CloseCircleFill } from 'antd-mobile-icons';
import { Button, Image } from 'antd-mobile';
import './index.less';

import bitverseLogo from '../../assets/images/bitverse-logo.svg';

export const Download = () => {
  const key = 'bitverse_download_close';
  const [isShowDownload, setIsShowDownload] = useState(
    localStorage.getItem(key) || 0
  );

  const closeDownloadPopup = () => {
    setIsShowDownload(1);
    localStorage.setItem(key, '1');
  };

  return (
    <>
      {!Number(isShowDownload) && (
        <div className="download-wrap df aic">
          <CloseCircleFill
            fontSize={14}
            color="#595d61"
            className="cp"
            onClick={closeDownloadPopup}
          />
          <Image
            src={bitverseLogo}
            width={40}
            height={40}
            style={{ borderRadius: 8, margin: '0 8px' }}
            fit="cover"
          />
          <div className="f1 fs12">
            <p className="name">Bitverse</p>Download and receive web3 benefits!
          </div>
          <a href="https://www.bitverse.zone/download" target="_blank">
            <Button color="primary" fill="solid">
              Get
            </Button>
          </a>
        </div>
      )}
    </>
  );
};
