import { FC, useState } from 'react';
import { Card } from '../../components/Card';
import './index.less';
import { recoverAddress, signMessage } from '@bitverse-h5-wallet/bitverse-core';

import { Button } from 'antd-mobile';

const exampleMessage = 'Hello world';

export const SignMessageDemo: FC = () => {
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [sigResult, setSigResult] = useState('');
  const [address, setAddress] = useState('');

  const signEIP191Message = async () => {
    setLoading(true);
    const signatureHex = await signMessage(exampleMessage);
    setSigResult(signatureHex || '');
    setLoading(false);
  };

  const verifyEIP191Message = () => {
    if (!sigResult) {
      console.log('请先签名消息, 再验证');
      return;
    }
    setLoading2(true);

    const originAddress = recoverAddress(sigResult, exampleMessage);

    setAddress(originAddress);
    setLoading2(false);
  };

  return (
    <Card title="测试 消息签名: personal_sign ✅">
      <Button onClick={signEIP191Message} color="success" loading={loading}>
        3.1 消息签名: personalSign
      </Button>
      <div className="sig_result">
        <div className="sig_title">签名结果: </div>
        <div className="sig">{sigResult}</div>
      </div>
      <Button onClick={verifyEIP191Message} color="success" loading={loading2}>
        3.2 验证消息签名: verifyEIP191Message
      </Button>
      <div className="sig_result">
        <div className="sig_title">wallet address: </div>
        <div className="sig">{address}</div>
      </div>
    </Card>
  );
};
