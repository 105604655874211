import { FC, useState } from 'react';
import { Card } from '../../components/Card';
import { Button } from 'antd-mobile';
import {
  AccountListType,
  BWWalletService,
  KeygenResultType,
  PasswordService,
  WalletListType,
  getAccountList,
  getCurrentAccount,
  getFromStorage,
  keygenStorageKey,
  mpcKeyGen,
  setToStorage,
} from '@bitverse-h5-wallet/bitverse-core';
import ReactJson from 'react-json-view';
import useNavigatePage from '../../hooks/use-navigate-page';

export const WalletManagerDemo: FC = () => {
  const { navigateToPage } = useNavigatePage();

  const [loading, setLoading] = useState(false);
  const [walletAddress, setWalletAddress] = useState('');
  const [walletList, setWalletList] = useState<AccountListType>();

  async function testKeyGenClick() {
    setLoading(true);
    console.log('[开始执行 keygen ]');

    // const keygenResult = await mpcKeyGen();
    if (!(await PasswordService.instance.verify())) {
      navigateToPage('auth');
      return;
    }
    const password = await PasswordService.instance.getPassword();
    const keygenResult = await BWWalletService.instance.createMpcWallet(
      password
    );

    if (keygenResult) {
      console.log('keygen 成功, 钱包信息已存储至 localstorage');
    }
    setLoading(false);
  }

  const getAccount = () => {
    const account = BWWalletService.instance.getCurrentWallet();
    setWalletAddress(account.address);
    return walletAddress;
  };

  const getWalletList = () => {
    const accountList = BWWalletService.instance.getAllWallets();
    setWalletList(accountList);
    return accountList;
  };

  return (
    <div className="wallet-mamager">
      <Card title="钱包创建、管理流程 ✅">
        <div>
          <Button
            type="button"
            color="success"
            onClick={testKeyGenClick}
            loading={loading}
          >
            1.1 keygen (生成一个全新的钱包): mpcKeyGen
          </Button>
        </div>
        <div className="meta">
          <Button type="button" color="success" onClick={getAccount}>
            1.2 获取当前钱包(第0个): getCurrentAccount
          </Button>
          <p>{walletAddress}</p>
        </div>
        <div className="meta">
          <Button type="button" color="success" onClick={getWalletList}>
            1.3 获取钱包列表: getAccountList
          </Button>
        </div>
        <div>
          {walletList && <ReactJson src={{ walletList }} theme="monokai" />}
        </div>
      </Card>
    </div>
  );
};
