export const COIN_CONFIG:{[key: string]: {img:string, fullName:string}} = {
  'BTC': {
    img: '',
    fullName: 'Bitcoin'
  },
  'ETH': {
    img: 'https://pic.bitverse.zone/token_images/1/eth.png',
    fullName: 'Ethereum'
  },
  'BIT': {
    img: '',
    fullName: 'BIT'
  }
}