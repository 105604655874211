import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { RightOutline } from 'antd-mobile-icons';
import { Dialog } from 'antd-mobile';
import {
  BWWalletService,
  PasswordService,
  getAccountList,
} from '@bitverse-h5-wallet/bitverse-core';
import './index.less';
import { AuthContext } from '../../contexts/AuthContext';
import httpClient from '@bitverse-h5-wallet/bitverse-http-client';
import { NX_API_Base_URL } from '../../utils';

export const Set = () => {
  const navigate = useNavigate();
  const { backupStatus } = BWWalletService.instance.getCurrentWallet();
  const { setIsLogin } = useContext(AuthContext);

  const signOut = async () => {
    const accountList = await getAccountList();
    let hintMsg =
      'You have not backed up your wallet, and you will lose wallet-related data if you log out. Are you sure you want to log out?';
    if (backupStatus?.google || accountList.length === 0) {
      hintMsg =
        'Exit will clear local data, please confirm whether you want to exit';
    }
    Dialog.confirm({
      content: hintMsg,
      cancelText: 'cancel',
      confirmText: 'confirm',
      onConfirm: async () => {
        localStorage.clear();
        Promise.all([
          PasswordService.instance.clearPassword(),
          httpClient.post(
            `${NX_API_Base_URL}/bitverse/wallet/v1/private/user/logout`
          ),
        ]);
        setIsLogin(false);
        navigate('/welcome');
      },
    });
  };

  return (
    <div className="set-wrap pd16">
      <div className="df aic item cp" onClick={() => navigate('/backup')}>
        <label className="f1">BackUp Wallet</label>
        <RightOutline color="#81858c" />
      </div>
      <div className="df aic item cp" onClick={() => navigate('/recovery')}>
        <label className="f1">Recovery Wallet</label>
        <RightOutline color="#81858c" />
      </div>
      {/* <div className="df aic item cp" onClick={() => navigate('/app/home')}>
        <label className="f1">Test</label>
        <RightOutline color="#81858c" />
      </div> */}
      <div className="df aic item cp" onClick={signOut}>
        <label className="f1">Sign Out</label>
        <RightOutline color="#81858c" />
      </div>
    </div>
  );
};
