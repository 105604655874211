import clockIcon from '../../../assets/clockIcon.svg';
import receivedIcon from '../../../assets/receivedIcon.svg';
import sentIcon from '../../../assets/sentIcon.svg';

interface TransactionStatusTS {
  [key: string]: {
    text: string;
    icon: string;
  };
}

// 交易列表状态
const TRANSACTION_STATUS: TransactionStatusTS = {
  IN: {
    text: 'Received',
    icon: receivedIcon,
  },
  PENDING: {
    text: 'Sent',
    icon: clockIcon,
  },
  OUT: {
    text: 'Sent',
    icon: sentIcon,
  },
};

export { TRANSACTION_STATUS };
