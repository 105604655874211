import MD5 from 'crypto-js/md5';
export const getBaseDomain = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return process.env.NX_API_Base_URL;
  // return process.env['NODE_ENV'] === 'production'
  //   ? 'https://api.bitverse.zone'
  //   : 'https://api2.bitverse-dev.bitverse.zone';
};

export function getDeviceFingerprint() {
  // 获取浏览器的 User-Agent
  const userAgent = window.navigator.userAgent;

  // 获取浏览器的语言
  const language = window.navigator.language;

  // 获取屏幕的宽度和高度
  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;

  // 获取插件列表
  const plugins = Array.from(window.navigator.plugins).map(
    (plugin) => plugin.name
  );

  // 将获取到的信息拼接在一起
  const fingerprint = `${userAgent}-${language}-${screenWidth}-${screenHeight}-${plugins.join(
    ','
  )}`;
  const md5Fingerprint = MD5(fingerprint).toString();
  return md5Fingerprint;
}
