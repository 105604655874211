/* eslint-disable @typescript-eslint/no-empty-function */
import { Toast } from 'antd-mobile';
import { createContext, useState, FC, ReactNode } from 'react';
import { PasswordService } from '@bitverse-h5-wallet/bitverse-core';
import { useNavigate } from 'react-router-dom';

type AuthContextType = {
  auth: (input: string) => void;
  addAuthCallbackTask: (callback: () => void) => void;
  executeTodoTasks: () => void;
  isLogin: boolean;
  setIsLogin: (isLogin: boolean) => void;
};

export const AuthContext = createContext<AuthContextType>({
  auth: (input: string) => {},
  addAuthCallbackTask: () => {},
  executeTodoTasks: () => {},
  isLogin: false,
  setIsLogin: (isLogin: boolean) => {}
});

type AuthProviderProps = {
  children: ReactNode;
};

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [authCallbackTasks, setAuthCallbackTasks] = useState<(() => void)[]>(
    []
  );
  const [isLogin, setIsLogin] = useState<boolean>(false)
  const passwordInstance = PasswordService.instance;

  const navigate = useNavigate();

  const executeTodoTasks = () => {
    if (!authCallbackTasks?.length) return;
    const task = authCallbackTasks.pop();
    setAuthCallbackTasks([...authCallbackTasks]);
    // 执行最新任务
    task?.();
  };

  // 登录方法
  const auth = async (input: string) => {
    const hasPwd = await passwordInstance.hasPassword();
    // 校验密码
    if (hasPwd && !(await PasswordService.instance.verify(input))) {
      Toast.show({
        icon: 'fail',
        content: 'Password error!',
        position: 'bottom',
      });
      return;
    }
    !hasPwd && (await PasswordService.instance.setPassword(input));
    // 当登录成功后，执行任务
    if (authCallbackTasks.length) {
      executeTodoTasks();
    } else {
      // 页面刷新会丢失任务时，默认进入首页
      navigate('/app/wallet');
    }
  };

  // 添加验证密码/登录成功后回调任务
  const addAuthCallbackTask = (callback: () => void) => {
    setAuthCallbackTasks((prevTasks) => [...prevTasks, callback]);
  };

  return (
    <AuthContext.Provider
      value={{ auth, addAuthCallbackTask, executeTodoTasks, isLogin, setIsLogin}}
    >
      {children}
    </AuthContext.Provider>
  );
};
