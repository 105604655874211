import { AutoCenter, SpinLoading } from 'antd-mobile';
import { useLocation } from 'react-router-dom';
import React, { useEffect, useState, useRef } from 'react';
import useNavigatePage from '../../hooks/use-navigate-page';
import './index.less';
import { DETAIL_STATUS } from './configuration/constant';
import { Header } from '../../layouts/Header';
import { RightOutline } from 'antd-mobile-icons';
import { useNetwork } from '../../components/networks/useNetworks';
import httpClient from '@bitverse-h5-wallet/bitverse-http-client';
import { NX_API_Base_URL } from '../../utils';
import dayjs from 'dayjs';
import { BWWalletService } from '@bitverse-h5-wallet/bitverse-core';

interface TransactionDetailItmTS {
  status: string;
  time: string;
  value: string;
  symbol: string;
  fee: string;
  to: string;
  from: string;
  hash: string;
}

const TokenDetail: React.FC = () => {
  const location = useLocation();
  const { navigateToPage } = useNavigatePage();
  const { hash, contract, iconUrl, balance, chainId } = location.state || {};
  const network = useNetwork(chainId)?.network;
  const intervalIdRef = useRef<any>(null);
  const [detail, setDetail] = useState<TransactionDetailItmTS>(
    {} as TransactionDetailItmTS
  );
  const [loading, setLoading] = useState(false);
  const address = BWWalletService.instance.getCurrentWallet()?.address;

  const handleCheckDetailInfo = () => {
    window.open(
      `${network.explorer.url}${network.explorer.txPath}${detail.hash || hash}`
    );
  };

  useEffect(() => {
    if (!intervalIdRef.current) {
      handleTransactionDetail(); // 首次调用
    }
    // 启动轮询，每5秒执行一次
    intervalIdRef.current = setInterval(handleTransactionDetail, 5000);

    // 返回清理函数以取消轮询
    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    };
  }, []);

  // 获取当前交易信息
  const handleTransactionDetail = async () => {
    !detail && setLoading(true);
    try {
      const rtn = await httpClient.post(
        `${NX_API_Base_URL}/bitverse/wallet/v1/public/trade/transaction/get`,
        {
          coinId: network.coinId,
          chainId: network.chainId,
          hash,
          address,
          contract,
        }
      );
      const { result, retCode } = rtn;
      if (retCode === 0) {
        // 如果是非 PENDING & CONFIRMING，关闭轮询
        if (!['PENDING', 'CONFIRMING'].includes(result?.status)) {
          clearInterval(intervalIdRef.current);
        }
        setDetail(result);
        !detail && setLoading(false);
      }
    } catch (error) {
      console.log('[response error]: ', error);
    }
  };

  const handleBackToTokenDetail = () => {
    if (detail.symbol) {
      navigateToPage('detail', {
        state: {
          symbol: detail.symbol,
          balance,
          contract,
          iconUrl,
          chainId: chainId,
        },
      });
    } else {
      navigateToPage('home');
    }
  };

  return (
    <div className="detail-container">
      <Header
        pageName="Detail"
        handleBackClick={() => handleBackToTokenDetail()}
      ></Header>
      {loading ? (
        <AutoCenter className="loading mt16">
          <SpinLoading />
        </AutoCenter>
      ) : (
        <div className="page-content">
          <AutoCenter className="status-info tac mb16">
            <img
              src={
                DETAIL_STATUS[detail?.status]?.icon || DETAIL_STATUS.NODATA.icon
              }
              alt=""
            />
            <p
              style={{
                color:
                  DETAIL_STATUS[detail?.status]?.color ||
                  DETAIL_STATUS.NODATA.color,
              }}
              className="fs18 fw600 mb16 mt16"
            >
              {DETAIL_STATUS[detail?.status]?.text || DETAIL_STATUS.NODATA.text}
            </p>
            <p className="t2 fs12">
              {detail.time && Number(detail.time) !== 0
                ? dayjs.unix(Number(detail.time)).format('YYYY-MM-DD HH:mm:ss')
                : ''}
            </p>
          </AutoCenter>
          <div className="content-detail">
            {detail.value ? (
              <>
                <div className="block-background itm mb24 df jcsb fs14">
                  <span>Total Amount</span>
                  <span>{`${detail.value} ${detail.symbol}`}</span>
                </div>
                <div className="block-background fs12">
                  <div className="itm mb24 df jcsb">
                    <span>Gas Fee</span>
                    <span>{`${detail.fee} ${detail.symbol}`}</span>
                  </div>
                  <div className="itm mb24 df jcsb">
                    <span>Recipient</span>
                    <span>{detail.to}</span>
                  </div>
                  <div className="itm mb24 df jcsb">
                    <span>Withdraw From</span>
                    <span>{detail.from}</span>
                  </div>
                </div>
              </>
            ) : null}
            <div className="block-background fs12">
              <div className="itm mb24 df jcsb">
                <span>TXID</span>
                <span>{detail.hash || hash}</span>
              </div>
              <div className="itm mb24 df jcsb">
                <span>View Transaction Details</span>
                <RightOutline onClick={handleCheckDetailInfo} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default TokenDetail;
