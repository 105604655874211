import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import './assets/styles/global.less';
// import { ReducerContextProvider } from './context';
// import { AuthProvider } from './contexts/AuthContext';
import http from '@bitverse-h5-wallet/bitverse-http-client';
// import { MPCWalletBackupRecoverService } from '@bitverse-h5-wallet/bitverse-core';

import init, {
  // initThreadPool,
  test,
  ecdsa_keygen_first_handle,
  ecdsa_keygen_second_handle,
  test_key_gen_first_msg,
  ecdsa_chaincode_first_handle,
  ecdsa_chaincode_second_handle_and_return_master_key,
  ecdsa_build_sign_first_request,
  ecdsa_sign_first_handle,
  ecdsa_ecc_server_key_backup_verify,
  ecdsa_ecc_encrypt,
  get_public_share_key,
  get_public_share_key_with_derive,
  key_derive,
  // } from './pkg/bitverse_core_wasm';
} from 'libs/bitverse-wasm/pkg-rayon/bitverse_core_wasm';
import { ReducerContextProvider } from './context';
import { AuthProvider } from './contexts/AuthContext';

// import { testlib } from '@bitverse-h5-wallet/testlib';
// console.log(MPCWalletBackupRecoverService, 'MPCWalletBackupRecoverService');
// console.log('process', process);
init();

console.log(import.meta.url);
console.log(process.env, '------ ');

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

console.log('http:', http);

root.render(
  <StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <ReducerContextProvider>
          <App />
          {/* bitverse */}
        </ReducerContextProvider>
      </AuthProvider>
    </BrowserRouter>
  </StrictMode>
);
