// 数字处理
import DEC from 'decimal.js';

function toNumber(val: any) {
  if (!val || Number.isNaN(Number(val)) || !new DEC(Number(val)).isFinite()) {
    return 0;
  }
  return val;
}

/**
 * 千分位格式化 高精度，如果不传dp会把0.0000 => 0
 */
function toThousands(value: any, dp: number) {
  const amount = handleReturn(new DEC(toNumber(value)), dp);
  let regexp = '(\\d)(?=(\\d{3})+\\.)';
  if (amount.indexOf('.') === -1) {
    regexp = '(\\d)(?=(\\d{3})+$)';
  }
  return amount.replace(new RegExp(regexp, 'g'), '$1,');
}

/**
 * 加法
 *
 * @param {string} s1 第一个值
 * @param {string} s2 第二个值
 * @param {int} dp 小数点后位数
 *
 */
function add(s1: any, s2: any, dp?: any) {
  return handleReturn(DEC.add(toNumber(s1), toNumber(s2)), dp);
}

/**
 * 加法 多个参数相加
 *
 * @param {array} arr 第一个值,是个数组，里面是多项需要相加的数
 * @param {int} dp 小数点后位数
 *
 */
function addMoreArgs(arr: any, dp?: any) {
  if (Array.isArray(arr)) {
    const total = arr.reduce(
      (prev, cur) => DEC.add(toNumber(prev), toNumber(cur)),
      {}
    );
    return handleReturn(total, dp);
  }
  throw new Error('the first arg of function addMoreArgs() must be an array ');
}

/**
 * 减法
 *
 * @param {string} s1 第一个值
 * @param {string} s2 第二个值
 * @param {int} dp 小数点后位数
 *
 */
function sub(s1: any, s2: any, dp?: any) {
  return handleReturn(DEC.sub(toNumber(s1), toNumber(s2)), dp);
}

/**
 * 乘法
 *
 * @param {string} s1 第一个值
 * @param {string} s2 第二个值
 * @param {int} dp 小数点后位数
 *
 */
function mul(s1: any, s2: any, dp?: any) {
  return handleReturn(DEC.mul(toNumber(s1), toNumber(s2)), dp);
}

/**
 * 除法
 *
 * @param {string} s1 第一个值
 * @param {string} s2 第二个值
 * @param {int} dp 小数点后位数
 *
 */
function div(s1: any, s2: any, dp?: any) {
  return handleReturn(toNumber(DEC.div(toNumber(s1), toNumber(s2))), dp);
}

/**
 * 保留小数位，不足补0
 * @param {*} val
 * @param {*} dp
 * @returns
 */
function intercept(val: any, dp: any) {
  return handleReturn(new DEC(toNumber(val)), dp);
}

/**
 * 小于
 * @param {*} s1 第一个值
 * @param {*} s2 第二个值
 * @returns
 */
function lt(s1: number | DEC, s2: number) {
  return new DEC(toNumber(s1)).lt(toNumber(s2));
}

/**
 * 等于
 * @param {*} s1 第一个值
 * @param {*} s2 第二个值
 * @returns
 */
function eq(s1: any, s2: any) {
  return new DEC(toNumber(s1)).eq(toNumber(s2));
}

// 处理返回结果
function handleReturn(result: number | DEC, dp: any) {
  let strResult = result.toString();
  if (result.toFixed && (dp || typeof dp === 'number')) {
    const dpTmp = Number(toNumber(dp));
    if (lt(result, 0)) {
      strResult = result.toFixed(dpTmp, DEC.ROUND_UP);
    } else {
      strResult = result.toFixed(dpTmp, DEC.ROUND_DOWN);
    }
  } else if (result.toFixed) {
    strResult = result.toFixed();
  }
  return strResult;
}

export {
  add,
  addMoreArgs,
  sub,
  mul,
  div,
  toThousands,
  intercept,
  toNumber,
  lt,
  eq,
};
