import { Networks } from '@bitverse-h5-wallet/bitverse-core';

export const useNetworks = () => {
  const allNetworks = Networks.instance.getNetworks();
  return allNetworks;
};

export const useNetwork = (chainId?: string) => {
  return {
    network: chainId
      ? Networks.instance.getNetworkByChainId(String(chainId))
      : Networks.instance.getCurrentNetwork(),
  };
};

export const useCurrentNetwork = () => {
  const currentNetwork = Networks.instance.getCurrentNetwork();
  return currentNetwork;
};

export const switchNetwork = (chainId: string) => {
  Networks.instance.switchNetwork(chainId);
};
