import { Input, Toast, Button, SpinLoading, AutoCenter } from 'antd-mobile';
import React, { useEffect, useState } from 'react';
import './index.less';
import copyBtn from '../../assets/copyBtn.svg';
import { EditSOutline } from 'antd-mobile-icons';
import { handlePageInfo } from '../Authorization/configuration';
import copy from 'copy-to-clipboard';
import { Header } from '../../layouts/Header';

interface Props {
  onConfirm: () => void;
  updateData?: (value: string) => void;
  detail: detailTS[];
  type: 'auth' | 'sign' | 'confirm';
  loading: boolean;
  pageLoading?: boolean;
}

interface detailTS {
  name: string;
  info: string;
  isCopy: boolean;
  canEdit: boolean;
  icon?: string;
  redirectLink: string;
}

const handleCopy = (txt: string) => {
  if (copy(txt)) {
    Toast.show({
      content: 'Copy Successfully!',
      position: 'top',
    });
  } else {
    Toast.show({
      content: 'Copy failed, please manually select and right-click to copy.',
      position: 'top',
    });
  }
};

const Authorization: React.FC<Props> = (props) => {
  const { detail, type, loading, pageLoading, onConfirm, updateData } = props;
  const { title, tipTitle, tipContent, btnTxt } = handlePageInfo(type);
  const [editMode, setEditMode] = useState(false);
  const [editModeTitle, setEditModeTitle] = useState('');
  const [editVal, setEditVal] = useState('');

  useEffect(() => {
    const data = JSON.parse(JSON.stringify(detail));
    const editItm = data.find((itm) => itm.name === editModeTitle);
    setEditVal(editItm?.info || '');
  }, [detail, editModeTitle]);

  // 更新编辑项
  const handleUpdateEditItm = () => {
    updateData && updateData(editVal);
    setEditMode(false);
  };

  const handleChangeEditItm = (val: string) => {
    const value = val.replace(/[^\d^.]+/g, '');
    setEditVal(value);
  };

  return (
    <div className="authorization-container fs14 fw300">
      <Header pageName={editMode ? editModeTitle : title} back={null}></Header>
      <div className="page-content">
        {pageLoading ? (
          <AutoCenter style={{ marginTop: 200 }}>
            <SpinLoading />
            <div className="mt8">loading...</div>
          </AutoCenter>
        ) : editMode ? (
          <Input
            value={editVal}
            className="edit-input"
            placeholder="Please enter"
            onChange={handleChangeEditItm}
            clearable
          />
        ) : (
          <>
            <div className="content-tip green">
              <h3>{tipTitle}</h3>
              <p>{tipContent}</p>
            </div>
            <div className="content-detail t2">
              {detail.map((itm: detailTS, idx) => {
                return (
                  <div className="row df" key={idx}>
                    <span className="label-name">{itm.name}</span>
                    <div className="f1">
                      <span className="detail">
                        {typeof itm.info === 'string' ? itm.info : JSON.stringify(itm.info)}
                        {itm.isCopy ? (
                          <img
                            onClick={() => handleCopy(typeof itm.info === 'string' ? itm.info : JSON.stringify(itm.info))}
                            src={copyBtn}
                            alt=""
                          />
                        ) : null}
                      </span>
                      {itm.canEdit && !loading ? (
                        <EditSOutline
                          onClick={() => {
                            setEditMode(true);
                            setEditModeTitle(itm.name);
                          }}
                        />
                      ) : null}
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
        {pageLoading ? null : (
          <Button
            loading={loading}
            color="primary"
            className="w100 mt32"
            fill="solid"
            onClick={() => {
              editMode ? handleUpdateEditItm() : onConfirm();
            }}
          >
            {editMode ? 'Confirm' : btnTxt}
          </Button>
        )}
      </div>
    </div>
  );
};
export default Authorization;
