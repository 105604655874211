import { get_public_share_key_with_derive } from 'libs/bitverse-wasm/pkg-rayon/bitverse_core_wasm';
import { BigNumber, ethers } from 'ethers';
import { Transaction } from '@ethereumjs/tx';
import { Common } from '@ethereumjs/common';
import {
  eth_gasPrice,
  eth_getTransactionCount,
  getProvider,
  sendRawTransaction,
} from './chain-utils';
import { buf2hex, paddingLeft } from './convert-utils';
import { mpcSign } from './mpc-utils';
import { AccountListType, MasterKeyType } from '../types/types';
import { BWWalletService } from '../bwWalletService';
import { Network, Networks } from '../networks';
import { PasswordService } from '../passwordService';

export async function sendTransactionFromBitverse(
  transactionObj: any,
  callback?: (txResult: any) => void,
  network?: Network
): Promise<string | undefined> {
  console.log('[开始执行 交易]');

  const chainId = Number((network ?? Networks.instance.getCurrentNetwork()).chainId);

  const { address: walletAddress } =
    BWWalletService.instance.getCurrentWallet();
  // const password = PasswordService.instance.password;
  if (!(await PasswordService.instance.verify())) {
    window.location.href = '/pwd';
    return;
  }
  const password = await PasswordService.instance.getPassword();
  const keyinfo = await BWWalletService.instance.getCurrentWalletMasterKeyInfo(
    password
  );

  if (!keyinfo) return;
  const { id, master_key } = keyinfo;

  // const walletAddress = await walletAddressFromMk(master_key);
  console.log(`\n\nwalletAddress: ${walletAddress}\n\n`);

  const nonce = await eth_getTransactionCount(walletAddress, network);
  const gas_price = await eth_gasPrice(network);

  const newTransactionObj = {
    ...transactionObj,
    gasPrice: gas_price,
    nonce: nonce,
  };

  console.log('最终交易对象', newTransactionObj);
  const txFromData = Transaction.fromTxData(newTransactionObj, {
    common: Common.custom({ chainId: chainId }), // polygon mumbai
    // common: Common.custom({ chainId: 5 }), // goerli
  });

  const signData = buf2hex(txFromData.getMessageToSign(true));

  const signature = await mpcSign({ id, master_key, msg: signData });

  if (!signature) {
    console.error('signature 为空');
    return;
  }

  const bufferR = paddingLeft(signature.r);
  const bufferS = paddingLeft(signature.s);

  // TODO : 让用户确认签名
  // @ts-ignore
  const processedTx = txFromData._processSignature(
    BigInt(signature.recid + 27), // 这里需要处理
    bufferR,
    bufferS
  );

  const senderAddress = processedTx.getSenderAddress().toString().toLowerCase();

  /// ecrecover地址是否是钱包地址
  if (walletAddress !== senderAddress) {
    console.error(
      `senderAddress${senderAddress} not equal walletAddress${walletAddress} `
    );
  }
  const serializedTransactionStr = processedTx.serialize().toString('hex');
  console.log(`serializedTransactionStr: 0x${serializedTransactionStr}`);

  const result = await sendRawTransaction(
    '0x' + serializedTransactionStr,
    callback,
    network
  );

  console.log('[广播交易 result]', result);

  return result;
}

export async function walletAddressFromMk(mk: MasterKeyType) {
  const derivedPubKey = await get_public_share_key_with_derive(
    mk,
    (60).toString(16),
    (0).toString(16)
  );

  const publicKey = ethers.utils.computePublicKey('0x' + derivedPubKey);
  const pubKey = publicKey.substring(4);

  const hash = ethers.utils.keccak256('0x' + pubKey);
  const walletAddresss = '0x' + hash.substring(26);
  return walletAddresss;
}

/**
 *
 * @param recipient 转账给谁
 * @param value 转账金额
 * @returns 交易数据
 */
export function buildErc20TxData(recipient: string, value: string) {
  const abi2 = [
    {
      inputs: [
        { internalType: 'address', name: 'recipient', type: 'address' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' },
      ],
      name: 'transfer',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
  ];

  const args = [recipient, value];

  // const encodedParams = ethers.utils.defaultAbiCoder.encode(
  //   ['address', 'uint256'],
  //   args
  // );
  const iface = new ethers.utils.Interface(abi2);
  const encodedFunctionData = iface.encodeFunctionData('transfer', [
    args[0],
    args[1],
  ]);

  return encodedFunctionData;
}

export const buildErc20TxData2 = async (
  contractAddress: string,
  toAddress: string,
  abi: any,
  value: BigNumber
) => {
  const jsonRpcProvider = getProvider();
  const erc20Contract_rw = new ethers.Contract(
    contractAddress,
    abi,
    jsonRpcProvider
  );

  const transferData = erc20Contract_rw.interface.encodeFunctionData(
    'transfer',
    [toAddress, value]
  );
  return transferData;
};

/**
 *
 * @param spender 授权给谁，合约地址(比如红包合约)，比如授权 USDT 给红包合约
 * @param value 授权金额
 * @returns 交易数据
 */
export function buildApproveTxData(
  spender: string,
  value: string,
  decimal: number
) {
  const abi = [
    {
      inputs: [
        { internalType: 'address', name: 'spender', type: 'address' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' },
      ],
      name: 'approve',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
  ];

  const args = [spender, ethers.utils.parseUnits(value, decimal)];

  // const encodedParams = ethers.utils.defaultAbiCoder.encode(
  //   ['address', 'uint256'],
  //   args
  // );
  const iface = new ethers.utils.Interface(abi);
  const encodedFunctionData = iface.encodeFunctionData('approve', [
    args[0],
    args[1],
  ]);

  return encodedFunctionData;
}

// export const makeApprove = async (
//   transactionObj: any,
//   decimal: number,
//   cb: (
//     spender: string,
//     amount: string
//   ) => Promise<{ spender: string; amount: string }>
// ) => {
//   const data = transactionObj.data || '';

//   if (!data.startsWith(approveMethodId) || data.length !== 138) {
//     return console.error('不是 Token 合约，无法授权');
//   }

//   const { spender, amount } = recoverSpenderAndAmount(data, decimal);

//   console.log('spender', spender);
//   console.log('amount', amount);

//   // TODO: approve 弹窗
//   // 如果用户修改，需要构造 data，使用 buildApproveData，spender is to，amount is value
//   const { spender: newSpender, amount: newAmount } = await cb(spender, amount);

//   const newTransactionObj = {
//     ...transactionObj,
//     data: await buildApproveTxData(newSpender, newAmount, decimal),
//   };

//   await sendTransactionFromBitverse(newTransactionObj);
// };

/**
 *
 * @param erc20Contract 是 ERC20 合约地址，比如 usdt，或者 dummy
 * @param spender 你要授权的 DApp 合约地址（被授权的合约地址），比如红包合约地址
 * @param amount 授权金额，你给 DApp 合约授权的金 ERC20 合约金额
 * @param decimal ERC20 合约的小数位数，精度
 * @returns
 */
export const sendApprove = async (
  erc20Contract: string,
  spender: string,
  amount: string,
  decimal: number
) => {
  const txData = buildApproveTxData(spender, amount, decimal);
  console.log('[sendApprove] txData', txData);

  const gasLimit = await getGasLimit({
    to: erc20Contract,
    data: txData,
  });

  const transactionRaw = {
    // 如果 to 为 null，则表示这是一笔合约创建交易；
    // 如果 to 为主钱包地址，则表示这是一笔主链币交易；
    // 如果 to 属性为 ERC20 代币的合约地址，则表示这是一笔 ERC20 代币交易。
    to: erc20Contract,
    // 合约方法+参数 encode 后的值
    data: txData,
    gasLimit: gasLimit,
    // 固定为0
    value: '0x0',
  };

  const result = await sendTransactionFromBitverse(transactionRaw);
  return result;
};

// 0xa7bd54c7db7499f4d372b15d468714a8ab0d71d2

export const sendContract = async (data: any, cb?: any) => {
  const gasLimit = await getGasLimit(data);
  const transactionRaw = {
    ...data,
    gasLimit,
  };
  const result = await sendTransactionFromBitverse(transactionRaw, cb);
  return result;
};

/**
 *
 * @param erc20Contract 是 ERC20 合约地址，比如 usdt，或者 dummy
 * @param recipient 接收 ERC20 的钱包地址，可以是个人钱包地址，也可以是合约地址
 * @param amount 授权金额，你给 DApp 合约授权的金 ERC20 合约金额
 * @param decimal ERC20 合约的小数位数，精度
 * @returns
 */
export const sendErc20Transfer = async (
  erc20Contract: string,
  recipient: string,
  amount: string,
  cb?: any,
  network?: Network
) => {
  const txData = buildErc20TxData(recipient, amount);
  const gasLimit = await getGasLimit({ to: erc20Contract, data: txData }, network);

  const transactionRaw = {
    // 如果 to 为 null，则表示这是一笔合约创建交易；
    // 如果 to 为主钱包地址，则表示这是一笔主链币交易；
    // 如果 to 属性为 ERC20 代币的合约地址，则表示这是一笔 ERC20 代币交易。
    to: erc20Contract,
    // 合约方法+参数 encode 后的值
    data: txData,
    gasLimit: gasLimit,
    // 固定为0
    // value: '0x0',
  };
  const result = await sendTransactionFromBitverse(transactionRaw, cb, network);
  return result;
};

// export const getGasLimit_old = async (
//   contractAddress: string,
//   to: string,
//   contractAbi: any,
//   jsonRpcProvider: any,
//   // signer: any,
//   functionName: string
// ): Promise<BigNumber> => {
//   // const contractAddress = '0x1234567890123456789012345678901234567890';
//   // const contractAbi = abi; // ERC20 智能合约 ABI

//   // 创建合约对象
//   const contract = new ethers.Contract(
//     contractAddress,
//     contractAbi,
//     jsonRpcProvider
//   );

//   // 调用需要估算 gas 上限的函数
//   // const functionName = 'transfer';
//   // const toAddress = address; // 接收代币的地址
//   const amount = ethers.utils.parseUnits('0.001', 18); // 要发送的代币数量，18 表示小数点后 18 位

//   const gasLimit = await contract.estimateGas[functionName](to, amount);

//   console.log('gasLimit', gasLimit);
//   return gasLimit.mul(1.5);
// };

export type GasLimitTransactionRaw = {
  data?: string; // '0x1249c58b'; 如果是合约，就是合约方法+参数 encode 后的值
  from?: string; // from: '0xa7bd54C7db7499F4D372b15d468714a8ab0D71d2'; 交易发起人，就是钱包地址
  to: string; // to:'0x0D47C991460a91Ca6c8af0bF2f7556D33e9eC060',交易接收人，如果是合约，就是合约地址
  value?: string; // value: '0x2386f26fc10000', // 交易金额，如果是合约，就是要传入的主链币金额
};

export const getGasLimit = async (
  txData: GasLimitTransactionRaw,
  network?: Network
): Promise<string> => {
  const jsonRpcProvider = getProvider(network);
  const account = await getCurrentAccount();

  const gasLimit = await jsonRpcProvider.estimateGas({
    from: account,
    ...txData,
  });

  console.log(
    `\n 【wallet core】【wallet-utils】  getGasLimit : ${ethers.utils.hexlify(
      gasLimit
    )}  ---  gasLimit.toNumber()\n`
  );
  return ethers.utils.hexlify(gasLimit.mul(3));
};

export const getCurrentAccount = async (): Promise<string> => {
  const { address } = BWWalletService.instance.getCurrentWallet();
  return address;
};

/**
 * 获取钱包账户列表,给UI层使用
 * @returns 账户列表
 */
export const getAccountList = async (): Promise<AccountListType> => {
  const accountList = BWWalletService.instance.getAllWallets();
  return accountList;
};
