import init, {
  // initThreadPool,
  test,
  ecdsa_keygen_first_handle,
  ecdsa_keygen_second_handle,
  test_key_gen_first_msg,
  ecdsa_chaincode_first_handle,
  ecdsa_chaincode_second_handle_and_return_master_key,
  ecdsa_build_sign_first_request,
  ecdsa_sign_first_handle,
  ecdsa_ecc_server_key_backup_verify,
  ecdsa_ecc_encrypt,
  get_public_share_key,
  get_public_share_key_with_derive,
  key_derive,
  // } from '@bitverse-h5-wallet/bitverse-wasm';
  // } from 'libs/bitverse-wasm/pkg-rayon/bitverse_core_wasm';
} from 'libs/bitverse-wasm/pkg-no-rayon/bitverse_core_wasm';
import * as Comlink from 'comlink';

// Temporary hack for getRandomValues() error
const getRandomValues = crypto.getRandomValues;
crypto.getRandomValues = function <T extends ArrayBufferView | null>(
  array: T
): T {
  const buffer = new Uint8Array(array as unknown as Uint8Array);
  const value = getRandomValues.call(crypto, buffer);
  // @ts-ignore
  (array as unknown as Uint8Array).set(value);
  return array;
};

// const tt = function () {
//     let s = test("Hello");
//     console.log(s)
// }
console.log('Worker is initializing...');
void (async function () {
  await init();
  // await initThreadPool(navigator.hardwareConcurrency);
  // await initThreadPool(1);
  self.postMessage({ ready: true });
})();

Comlink.expose({
  test,
  ecdsa_keygen_first_handle,
  ecdsa_keygen_second_handle,
  test_key_gen_first_msg,
  ecdsa_chaincode_first_handle,
  ecdsa_chaincode_second_handle_and_return_master_key,
  ecdsa_build_sign_first_request,
  ecdsa_sign_first_handle,
  ecdsa_ecc_server_key_backup_verify,
  ecdsa_ecc_encrypt,
  get_public_share_key,
  get_public_share_key_with_derive,
  key_derive,
});
