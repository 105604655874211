import supportNetworks, { defaultNetwork } from './supportNetworks';
import { Storage } from '../storage';
import { eventBus } from '@bitverse-h5-wallet/bitverse-message-sdk';

export type Network = typeof supportNetworks[0];
// interface NetworksState {
//   networks: Network[];
// }
interface CurrentNetworksState {
  currentNetworkId: Network['id'];
}

// const networksStore = new Storage<NetworksState>(
//   {
//     networks: supportNetworks,
//   },
//   'networks'
// );
const currentNetworksStore = new Storage<CurrentNetworksState>(
  {
    currentNetworkId: '',
  },
  'BWNetworkStorage'
);

class Networks {
  private static _instance: Networks;
  defaultNetwork: Network = supportNetworks[0];

  public static get instance(): Networks {
    if (!this._instance) {
      this._instance = new Networks();
    }
    return this._instance;
  }

  public switchNetwork(_chainId: string) {
    const networks = this.getNetworks();
    const index = networks.findIndex(({ chainId }) => chainId === _chainId);
    if (index === -1) {
      throw new Error('Network not found');
    }
    eventBus.emit('chainChanged', networks[index]);
    currentNetworksStore.setItem('currentNetworkId', networks[index].id);
  }
  public getCurrentNetwork(): Network {
    const currentNetworkId = currentNetworksStore.getItem('currentNetworkId');
    const currentNetwork = this.getNetworks().find(
      (network) => network.id === currentNetworkId
    );
    if (currentNetwork) return currentNetwork;

    currentNetworksStore.setItem('currentNetworkId', defaultNetwork['id']);
    return defaultNetwork;
  }

  public getNetworks(): Network[] {
    const storedNetworks = supportNetworks;
    return storedNetworks;
  }
  public getNetworkByChainId(chainId: string): Network {
    const storedNetworks = supportNetworks;
    return (
      storedNetworks.find((network) => network.chainId === chainId) ??
      this.defaultNetwork
    );
  }
  public isNetworkExist(chainId: string): boolean {
    const storedNetworks = supportNetworks;
    return !!storedNetworks.find((network) => network.chainId === chainId);
  }
  public addNetworks(networks: Network[]) {
    // const prevNetworks = this.getNetworks();
    // if (
    //   prevNetworks.find(({ chainId }) =>
    //     networks.map(({ chainId }) => chainId).includes(chainId)
    //   )
    // ) {
    //   throw new Error('Network already exists');
    // }
    // const newNetworks = [...networks, ...prevNetworks].filter(
    //   (network, index, self) =>
    //     //删除重复项
    //     self.findIndex((n) => n.chainId === network.chainId) === index
    // );
    // networksStore.setItem('networks', newNetworks);
    // const addedNetworks = newNetworks.filter(
    //   (newNetwork) =>
    //     !prevNetworks.find(
    //       (prevNetwork) => prevNetwork.chainId === newNetwork.chainId
    //     )
    // );
    // return addedNetworks;
  }

  public hasNetwork(networkChainId: Network['chainId']) {
    const networks = this.getNetworks();
    return networks.some((network) => network.chainId === networkChainId);
  }
}

export { supportNetworks, Networks };
