import { Crypto } from '@peculiar/webcrypto';
import { v4 as uuidv4 } from 'uuid';
import { bytesToHex, hexToBytese } from '../lib/utils';

const crypto = new Crypto();

interface EncypedMap {
  id: string;
  type: string;
  cryptoInfo: {
    cipher: 'aes-128-ctr';
    cipherparams: { iv: string };
    ciphertext: string;
  };
}

// 加密方式基于 https://github.com/PeculiarVentures/webcrypto-docs/blob/master/AES_CTR.md

class ScryptGenerator {
  public static async encrypt(
    password: string,
    walletData: any,
    id = uuidv4(),
    type = 'mpc_client'
  ): Promise<string> {
    try {
      // 输入数据
      const data = JSON.stringify(walletData);
      // 转换为Uint8Array
      const encoder = new TextEncoder();
      const encodedData = encoder.encode(data);
      // 转换为ArrayBuffer
      const dataArrayBuffer = encodedData.buffer;
      const passwordBytes = encoder.encode(password);

      // 创建一个长度为 16 的 Uint8Array
      const buffer = new Uint8Array(16);
      buffer.set(passwordBytes.subarray(0, Math.min(passwordBytes.length, 16)));

      // 密钥
      const key = await crypto.subtle.importKey(
        'raw',
        buffer,
        'AES-CTR',
        false,
        ['encrypt', 'decrypt']
      );

      // 初始化向量
      const iv = crypto.getRandomValues(new Uint8Array(16));

      // 加密算法参数
      const encryptAlgorithm = { name: 'AES-CTR', counter: iv, length: 128 };

      // 执行加密操作
      const encryptedData = await crypto.subtle.encrypt(
        encryptAlgorithm,
        key,
        dataArrayBuffer
      );

      const map = {
        cryptoInfo: {
          cipher: 'aes-128-ctr',
          cipherparams: { iv: bytesToHex(iv) },
          ciphertext: bytesToHex(encryptedData),
        },
        id,
        type: type,
      };

      const result = JSON.stringify(map);
      return result;
    } catch (error) {
      console.log('error', error);
      return '';
    }
  }
  public static async decrypt(password: string, encypedJson: string) {
    try {
      const map = JSON.parse(encypedJson) as EncypedMap;
      const { cryptoInfo } = map;
      const encryptedData = hexToBytese(cryptoInfo.ciphertext);
      const iv = hexToBytese(cryptoInfo.cipherparams.iv);

      const encoder = new TextEncoder();
      const passwordBytes = encoder.encode(password);

      // 创建一个长度为 16 的 Uint8Array
      const buffer = new Uint8Array(16);
      buffer.set(passwordBytes.subarray(0, Math.min(passwordBytes.length, 16)));

      // 密钥
      const key = await crypto.subtle.importKey(
        'raw',
        buffer,
        'AES-CTR',
        false,
        ['encrypt', 'decrypt']
      );

      // 解密算法参数
      const decryptAlgorithm = { name: 'AES-CTR', counter: iv, length: 128 };

      // 执行解密操作
      const decryptedData = await crypto.subtle.decrypt(
        decryptAlgorithm,
        key,
        encryptedData
      );

      // 将解密后的数据转换为字符串
      const decoder = new TextDecoder();
      const decryptedText = decoder.decode(decryptedData);

      // 解密后的数据
      const decryptedObj = JSON.parse(decryptedText);
      return decryptedObj;
    } catch (error) {
      return null;
    }
  }
}

export { ScryptGenerator };
