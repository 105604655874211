import { FC, useState } from 'react';
import { v4TestData } from './testData';
import { Buffer } from 'buffer';
import { keccak256 } from 'ethereum-cryptography/keccak';

import { ethers, utils } from 'ethers';
import './index.less';
import { Card } from '../../components/Card';
import { utf8ToBytes } from 'ethereum-cryptography/utils';
import {
  MessageTypes,
  recoverTypedSignature,
  SignTypedDataVersion,
  TypedMessage,
} from '@metamask/eth-sig-util';
import {
  BWSecureStorage,
  BWWalletService,
  KeygenResultType,
  ScryptGenerator,
  WalletListType,
  buf2hex,
  getCurrentAccount,
  getFromStorage,
  mpcSign,
  stringToArray,
  paddingLeft,
  PasswordService,
  signMessage,
  recoverAddress,
  recoverAddressEip712,
  getProvider,
  getTypedDataPayload,
  signTypedDataV4,
} from '@bitverse-h5-wallet/bitverse-core';

import { TypedDataUtils } from '@metamask/eth-sig-util';
import { bufferToHex } from 'ethereumjs-util';
import { _TypedDataEncoder } from 'ethers/lib/utils';
import { Button, Space } from 'antd-mobile';
import BitverseProvider from '@bitverse-h5-wallet/bitverse-ethereum-provider';

interface Form {
  address: string;
  amount: string;
}

const bProvider = new BitverseProvider();
window.ethereum = bProvider;

export const TypedSignDemo: FC = () => {
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [MetamaskSig, setMetamaskSig] = useState('');
  const [MPCSig, setMPCSig] = useState('');
  const [MetamaskRecoverAddress, setMetamaskRecoverAddress] = useState('');
  const [MPCSigRecoverAddress, setMPCSigRecoverAddress] = useState('');

  const MetamaskTypedSign = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    await provider.send('eth_requestAccounts', []);

    const signer = provider.getSigner();
    const { domain, types, value } = v4TestData;

    const signature = await signer._signTypedData(domain, types, value);
    setMetamaskSig(signature);
    console.log(signature);
  };

  const verifyMetamaskTypedSign = async () => {
    const { domain, types, value } = v4TestData;

    const d: TypedMessage<any> = {
      types: types,
      primaryType: 'Mail',
      domain: domain,
      message: value,
    };

    let recovered = recoverTypedSignature({
      data: d,
      signature: MetamaskSig,
      version: SignTypedDataVersion.V4,
    });
    console.log('recovered by bitverse:', recovered);

    // 验签函数
    const verifySignature = async (signature) => {
      // 使用 ethers.js 的 recoverAddress 方法来恢复签名者的地址
      const signerAddress = ethers.utils.verifyTypedData(
        domain,
        types,
        value,
        signature
      );

      recovered = signerAddress;

      console.log(`recovered by ethers: ${signerAddress}`);

      // 这里你可以进一步验证 signerAddress 是否是你预期的地址
    };
    await verifySignature(MetamaskSig);

    setMetamaskRecoverAddress(recovered);
    setLoading2(false);
  };

  const signTypedMessageMPC = async () => {
    const { domain, types, value } = v4TestData;
    const typedDataJson = await getTypedDataPayload(domain, types, value);
    const signs = await signTypedDataV4(typedDataJson);
    setMPCSig(signs as string);
    console.log('signTypedMessageMPC result:', signs);
    return signs;
  };

  const verifyTypeMessageMPC = async () => {
    const recovered = await recoverAddressEip712(MPCSig, v4TestData);

    console.log('recovered:', recovered);

    console.log('originAddress = ', recovered);
    setMPCSigRecoverAddress(recovered);
  };

  const verifyTypeMessageMPCWithJohnny = async () => {
    const { domain, types, value } = {
      types: {
        EIP712Domain: [
          { name: 'name', type: 'string' },
          { name: 'version', type: 'string' },
        ],
        LoginType: [
          { name: 'wallet', type: 'string' },
          { name: 'nonce', type: 'string' },
          { name: 'contents', type: 'string' },
        ],
      },
      domain: {
        name: 'Desyn Protocol',
        version: '1',
        chainId: 60,
        verifyingContract: '',
      },
      value: {
        contents: 'I want to login on Desyn Protocol',
        nonce: '731264331',
        wallet: '0x48310640ba79362968dc0195d6079d90bdd77f83',
      },
    };

    const d: TypedMessage<any> = await getTypedDataPayload(
      domain,
      types,
      value
    );

    const MPCSig =
      '0xf88f8da0785cd9d2627cc95006aea2830284fce751f48915e5850f29224677d661cd3cd733eb50faf26ed78528e82fd25a702380228b8499891907423e65b6341c';

    const recovered = await recoverAddressEip712(MPCSig, {
      domain,
      types,
      value,
    });

    console.log('recovered:', recovered);

    console.log('originAddress = ', recovered);
    setMPCSigRecoverAddress(recovered);
    setLoading2(false);
  };

  async function preHash(typedDataJson, version: string) {
    // Parse the JSON to get the typed data object
    // const typedData = JSON.parse(typedDataJson);
    const { domain, types, value } = v4TestData;

    const payload = await getTypedDataPayload(domain, types, value);

    if (version === 'V1') {
      // Version 1 is not supported by eth-sig-util, you would need to implement this if necessary
      throw new Error('EIP-712 version 1 is not supported');
    } else if (version === 'V3') {
      // Version 3 is not directly supported by eth-sig-util, you would need to implement this if necessary
      throw new Error('EIP-712 version 3 is not supported');
    } else if (version === 'V4') {
      // Use eth-sig-util to hash the typed data for version 4
      const hash = TypedDataUtils.eip712Hash(payload, SignTypedDataVersion.V4);
      return bufferToHex(hash);
    } else {
      throw new Error('Unsupported EIP-712 version');
    }
  }

  return (
    <div className="type-sign">
      <Card title="测试 消息签名: eth_signTypedData ❌">
        <Space block>
          <Button
            onClick={async () => {
              const ethereum = window.ethereum;

              const accounts = await ethereum.request({
                method: 'eth_requestAccounts',
              });
              console.log(accounts, 'accounts');
            }}
          >
            链接钱包
          </Button>
        </Space>
        <Space direction="vertical">
          <button onClick={MetamaskTypedSign}>
            4.1 签名: Metamask TypedSign
          </button>
          <button onClick={verifyMetamaskTypedSign}>
            4.2 验签: Metamask TypedSign
          </button>
          <div style={{ wordBreak: 'break-word' }}>
            metamask Sign: {MetamaskSig}
          </div>
          <div style={{ wordBreak: 'break-word' }}>
            metamask address{MetamaskRecoverAddress}
          </div>
        </Space>

        <Space direction="vertical">
          <Button color="success" onClick={signTypedMessageMPC}>
            4.3 签名: MPC TypedSign
          </Button>
          <Button color="success" onClick={verifyTypeMessageMPC}>
            4.4 验签: MPC TypedSign
          </Button>
          <div style={{ wordBreak: 'break-word' }}>
            MPC Sign Result: {MPCSig}
          </div>
          <div style={{ wordBreak: 'break-word' }}>
            MPC wallet address: {MPCSigRecoverAddress}
          </div>
        </Space>
        <button onClick={verifyTypeMessageMPCWithJohnny}>
          4.4 验签: MPC verifyTypeMessageMPCWithJohnny
        </button>
      </Card>
    </div>
  );
};
