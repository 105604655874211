import successIcon from '../../../assets/successIcon.svg';
import pendingIcon from '../../../assets/pendingIcon.svg';
import failedIcon from '../../../assets/failedIcon.svg';
import noDataIcon from '../../../assets/noData.svg';

interface DetailStatusTS {
  [key: string]: {
    text: string;
    icon: string;
    color: string;
  };
}

// 详情页状态
const DETAIL_STATUS: DetailStatusTS = {
  CONFIRMED: {
    text: 'Successful',
    icon: successIcon,
    color: '#56FAA5',
  },
  PENDING: {
    text: 'Pending',
    icon: pendingIcon,
    color: '#FFC701',
  },
  CONFIRMING: {
    text: 'Pending',
    icon: pendingIcon,
    color: '#FFC701',
  },
  FAILED: {
    text: 'Operation',
    icon: failedIcon,
    color: '#F83B69',
  },
  NODATA: {
    text: '',
    icon: noDataIcon,
    color: '#56FAA5',
  },
};

export { DETAIL_STATUS };
