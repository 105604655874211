import { useContext, useEffect, useState } from 'react';
import './index.less';
import { Button } from 'antd-mobile';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';

const count = 5;
const AuthFailed = () => {
  const [countDownSecond, setCountDownSecond] = useState(count);
  const navigate = useNavigate();
  const { setIsLogin } = useContext(AuthContext)

  const goLogin = () => {
    navigate('/');
  };

  useEffect(() => {
    setIsLogin(false)
    let time = count;
    const timer = setInterval(() => {
      if (time > 0) {
        time--;
        setCountDownSecond(time);
      } else {
        goLogin();
        clearInterval(timer);
      }
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="auth-failed">
      <p className="fs16 lh24 pd12 auth-failed-text">
        You are not logged in or your login status has expired, redirect to
        login page in {countDownSecond} seconds
      </p>
      <Button className="mt16" fill="none" color="primary" onClick={goLogin}>
        Login Now
      </Button>
    </div>
  );
};

export default AuthFailed;
