import React from 'react';
import { Card } from '../../components/Card';
import {
  BWSecureStorage,
  PasswordService,
  ScryptGenerator,
  mpcKeyGen,
} from '@bitverse-h5-wallet/bitverse-core';
import ReactJson from 'react-json-view';
import { useNavigate } from 'react-router-dom';

import { Space, Button, Toast } from 'antd-mobile';
import { SignMessageDemo } from './SignMessage';
import { TypedSignDemo } from './TypedSignDemo';
import { SignTxDemo } from './SignTxDemo';
import { WalletManagerDemo } from './WalletManagerDemo';
import useNavigatePage from '../../hooks/use-navigate-page';

export const Demo = () => {
  const [keygenLoading, setKeygenLoading] = React.useState(false);
  const [keygenResultJson, setKeygenResultJson] = React.useState<any>(null);
  const [keygenencryptedResultJson, setKeygenencryptedResultJson] =
    React.useState<any>(null);

  const navigate = useNavigate();
  const { navigateToPage } = useNavigatePage();

  async function testKeyGenSecureStorageClick(): Promise<void> {
    setKeygenLoading(true);
    setKeygenResultJson(null);
    setKeygenencryptedResultJson(null);
    console.log('[开始执行 keygen ]');

    const keygenResult = await mpcKeyGen();
    if (!keygenResult) return;
    setKeygenResultJson(keygenResult);

    const id = keygenResult.id;
    const walletMap = {
      curv: 'secp256k1',
      keyinfo: keygenResult,
    };

    if (!(await PasswordService.instance.verify())) {
      navigateToPage('auth');
      return;
    }
    const password = await PasswordService.instance.getPassword();
    const encrypted = await ScryptGenerator.encrypt(
      password,
      [JSON.stringify(walletMap)],
      id
    );
    console.log('encrypted ', encrypted);
    //  --------- 测试解密 start ---------
    const testdecryptencrypted = await ScryptGenerator.decrypt(
      password,
      encrypted
    );
    console.log('testdecryptencrypted ', testdecryptencrypted);
    //  --------- 测试解密 end ---------
    setKeygenencryptedResultJson(JSON.parse(encrypted));
    setKeygenLoading(false);
  }

  const handleSecureStorage = async () => {
    const id = keygenencryptedResultJson.id;
    const encryptedStr = JSON.stringify(keygenencryptedResultJson);
    BWSecureStorage.write(id, encryptedStr);
    Toast.show({
      icon: 'success',
      content: '存储成功,在localstorage中查看',
    });
  };

  return (
    <div className="home" style={{ color: '#000', padding: '0 30px' }}>
      <h1 style={{ color: '#fff', textAlign: 'center', padding: 20 }}>
        交易、签名、wasm、加密存储
      </h1>

      <WalletManagerDemo />

      <SignTxDemo />

      <SignMessageDemo />

      <TypedSignDemo />

      {/* <Card title="测试 keygenResult 安全存储 ✅">
        <Space direction="vertical">
          <Space direction="vertical">
            <Button
              onClick={testKeyGenSecureStorageClick}
              loading={keygenLoading}
              loadingText="keygen生成中"
            >
              创建keygen 并加密
            </Button>
          </Space>
          {keygenResultJson && (
            <Space direction="vertical">
              <div>
                <p>keygenResult</p>
                <ReactJson src={keygenResultJson} theme="monokai" />
              </div>
            </Space>
          )}
          {keygenencryptedResultJson && (
            <Space direction="vertical">
              <div>
                <p>keygenResult 加密后结果</p>
                <ReactJson src={keygenencryptedResultJson} theme="monokai" />
              </div>
            </Space>
          )}
          {keygenencryptedResultJson && (
            <Space direction="vertical">
              <Button onClick={handleSecureStorage} style={{ color: '#000' }}>
                加密结果存储
              </Button>
            </Space>
          )}
        </Space>
      </Card> */}
      <Card title="测试钱包备份">
        <Button
          size="small"
          color="primary"
          onClick={() => navigate('/backup')}
        >
          立即备份
        </Button>
      </Card>
      <Card title="测试钱包恢复">
        <Button
          size="small"
          color="primary"
          onClick={() => navigate('/recovery')}
        >
          立即恢复
        </Button>
      </Card>
    </div>
  );
};
