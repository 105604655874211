import { BitverseEnum } from '@bitverse-h5-wallet/bitverse-dapp-sdk';
import { useContext } from 'react';
import { NavigateOptions, useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { PasswordService } from '@bitverse-h5-wallet/bitverse-core';
import { ProviderContext } from '../context';

export type PAGE_NAME =
  | 'signUp'
  | 'login'
  | 'auth'
  | 'send'
  | 'receive'
  | 'detail'
  | 'status'
  | 'home'
  | 'welcome';

export enum STAGE {
  CREATE = 'create',
  RECOVERY = 'recovery',
  SEND = 'send',
  RECEIVE = 'receive',
}

const useNavigatePage = () => {
  const navigate = useNavigate();
  const { addAuthCallbackTask, isLogin } = useContext(AuthContext);
  const { globalDispatch } = useContext(ProviderContext);
  const params = new URLSearchParams(window.location.search);
  const source = params.get('source') || '';

  const navigateToPage = (pageName: PAGE_NAME, options?: NavigateOptions) => {
    let to;
    switch (pageName) {
      case 'signUp':
        to = `/email-address?type=${STAGE.CREATE}&source=${source}`;
        break;
      case 'login':
        to = `/email-address?type=${STAGE.RECOVERY}&source=${source}`;
        break;
      case 'auth':
        to = `/pwd?source=${source}`;
        break;
      case 'send':
        to = `/select-coin?type=${STAGE.SEND}&source=${source}`;
        break;
      case 'receive':
        to = `/receive?source=${source}`;
        break;
      case 'detail':
        to = `/token-detail?source=${source}`;
        break;
      case 'status':
        to = `/detail-status?source=${source}`;
        break;
      case 'home':
        to = `/app/wallet?source=${source}`;
        break;
      case 'welcome':
        to = `/?source=${source}`;
    }
    navigate(to, options);
  };

  const runRouterChange = async (path: string, _params: any) => {
    globalDispatch({
      type: 'get_navigate_action',
      payload: {
        navigateParams: _params,
      },
    });
    let callback;

    switch (path) {
      case BitverseEnum.ethRequestAccounts:
        callback = () => navigate(`/login`);
        break;
      case BitverseEnum.personalSign:
        callback = () => navigate(`/sign`);
        break;
      case BitverseEnum.ethSignTypedData:
        callback = () => navigate(`/signTypedData`);
        break;
      case BitverseEnum.ethSignTypedDataV4:
        callback = () => navigate(`/signTypedData`);
        break;
      case BitverseEnum.ethSendTransactionApprove:
        callback = () => navigate(`/authorization`);
        break;
      case BitverseEnum.ethSendTransaction:
        console.log('eth_sendTransaction:', params);
        callback = () => navigate(`/send-contract`);
        break;
      case BitverseEnum.walletSwitchEthereumChain:
        callback = () => navigate(`/switch-chain`);
        break;
      default:
        callback = () => alert(`未知路由, 请处理${path}`);
        break;
    }
    if (!isLogin) {
      addAuthCallbackTask(callback);
    } else if (!(await PasswordService.instance.verify())) {
      addAuthCallbackTask(callback);
      navigateToPage('auth');
    } else {
      callback();
    }
  };

  return {
    navigateToPage,
    runRouterChange,
  };
};

export default useNavigatePage;
