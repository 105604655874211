import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from './Header';
import { Footer } from './Footer';
import NetworkSwitcher from '../components/networks/NetworkSwitcher';
import LeftMenu from './LeftMenu';
import { Download } from '../components/Download';
import './index.less';

export default function AppLayout() {
  return (
    <div className="layout-container">
      <Download />
      <Header back={null} right={<NetworkSwitcher />} left={<LeftMenu />} />
      <div className="content-wrap">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}
