import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { data } from './recover';
// import { browser } from 'webextension-polyfill-ts';
// import NodeRSA from 'node-rsa';
import { Button, Radio, Toast } from 'antd-mobile';
import { Header } from '../../layouts/Header';
import '../WalletBackUp/index.less';
import {
  PasswordService,
  RecoverService,
} from '@bitverse-h5-wallet/bitverse-core';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import useNavigatePage from '../../hooks/use-navigate-page';
import { getGoogleUserInfo } from '../../utils';
const typeMap: any = {
  1: 'iCloud',
  2: 'Google Drive',
  3: 'Drop Box',
};

const groupBy = (arr: any, key: any) =>
  arr.reduce((acc: any, item: any) => {
    const k = item[key];
    if (!acc[k]) {
      acc[k] = [];
    }
    acc[k].push(item);
    return acc;
  }, {});

const RecoveryBaseWallet = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { navigateToPage } = useNavigatePage();

  const [wallets, setWallets] = useState([]);
  const [isGohome, setIsGohome] = useState(false);
  const userEmail = localStorage.getItem('bitverse_user_info') || '{}';
  const [user, setCurrentUser] = useState<{
    accessToken: string;
    isUserLoggedIn: boolean;
  }>({ accessToken: '', isUserLoggedIn: false });
  const recoverInstance = useRef(RecoverService.instance);
  const currentWallet = useRef<any>(null);
  const [loading, setLoading] = useState(false);

  const login = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      console.log('codeResponse', codeResponse);
      user.accessToken = codeResponse.access_token;
      user.isUserLoggedIn = true;
      setCurrentUser({ ...user }); //this comes from a React context
    },
    onError: (error) => {
      console.log('error', error);
    },
    flow: 'implicit',
    scope: 'https://www.googleapis.com/auth/drive.file',
  });

  const getWalletRecoverList = useCallback(async () => {
    try {
      setLoading(true);
      const result = await recoverInstance.current.getWalletRecoverList();
      formattedData(result);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  }, []);

  const handleBtnClick = async (item: any) => {
    if (!(await PasswordService.instance.verify())) {
      window.location.href = '/pwd';
      return;
    }
    currentWallet.current = item;
    login();
  };

  const formattedData = (result: any) => {
    const walletsMap = groupBy(result, 'walletId');
    const wallets: any = [];
    Object.keys(walletsMap).forEach((key) => {
      if (walletsMap[key].length > 0) {
        const defaultWallet = walletsMap[key][0];
        wallets.push({
          walletId: defaultWallet.walletId,
          walletName: defaultWallet.walletName,
          cloudDisk: walletsMap[key],
        });
      }
    });
    setWallets(wallets);
  };

  useEffect(() => {
    (async () => {
      getWalletRecoverList();
    })();
  }, []);

  const toastContent = (toastTxt, email) => {
    return (
      <div className="mt8">
        <div className="fs16 tac">{toastTxt}</div>
        <div className="fs12 mt8 t2">
          recovery email:<p>{email}</p>
        </div>
      </div>
    );
  };

  useEffect(() => {
    (async () => {
      if (user.isUserLoggedIn) {
        const googleUserInfo = await getGoogleUserInfo(user.accessToken);
        try {
          if (!(await PasswordService.instance.verify())) {
            navigateToPage('auth');
            return;
          }
          const password = await PasswordService.instance.getPassword();
          Toast.show({
            maskClassName: 'customize-toast',
            content: toastContent(
              `${currentWallet.current.walletName} in recovery`,
              googleUserInfo?.email
            ),
            icon: 'loading',
            duration: 0,
            maskClickable: false,
          });
          await recoverInstance.current.handleRecover({
            walletBackUp: currentWallet.current,
            password,
            cloudType: 2,
            googleAuthToken: user.accessToken,
          });

          setIsGohome(true);
          Toast.show({
            maskClassName: 'customize-toast',
            icon: 'success',
            content: toastContent(
              `${currentWallet.current.walletName} recovery success`,
              googleUserInfo?.email
            ),
          });
          // setWallets((wallets) => [...wallets]);
          getWalletRecoverList();
        } catch (error: any) {
          Toast.show({
            maskClassName: 'customize-toast',
            icon: 'fail',
            content: toastContent(error?.message, googleUserInfo?.email),
          });
        }
      }
    })();
  }, [user]);

  return (
    <>
      <div className="recovery-wrap ">
        <Header
          pageName="Recovery Wallet"
          handleBackClick={() => navigate('/app/wallet')}
        />
        <div className="pd16 mt12">
          <div className="fs12 t2">The cloud disk associated with</div>
          <div className="fs16 mt12 mb22">{JSON.parse(userEmail).email}</div>
          {wallets.length === 0 ? (
            <div className="">
              <div className="fs12 t2" style={{ marginTop: 60 }}>
                No recoverable wallet, please create yours first
              </div>
              {/* <Button
                onClick={() => navigate('/backup')}
                color="primary"
                className="mt16"
                fill="solid"
                size="large"
              >
                BackUp Wallet
              </Button> */}
            </div>
          ) : (
            wallets.map((item: any) => {
              return (
                <div key={item.walletId} className="g-item-list">
                  <div className="wallet-name df aic bborder">
                    {item.walletName}
                  </div>
                  {item.cloudDisk.map((i: any) => {
                    if (i.recoverStatus && !isGohome) {
                      setIsGohome(true);
                    }
                    return (
                      <div
                        className="df aic cloud-disk-item"
                        key={i.cloudDiskType}
                      >
                        <div
                          className={`icon-wrap mr10 ${typeMap[
                            i.cloudDiskType
                          ].replace(' ', '')}`}
                        ></div>
                        <div className="f1">{typeMap[i.cloudDiskType]}</div>
                        {i.recoverStatus ? (
                          <>
                            <Radio checked />
                            &nbsp;Restored
                          </>
                        ) : (
                          <Button
                            size="small"
                            color="primary"
                            fill="outline"
                            disabled={i.cloudDiskType !== 2}
                            onClick={() => handleBtnClick(i)}
                          >
                            Sync now
                          </Button>
                        )}
                      </div>
                    );
                  })}
                </div>
              );
            })
          )}
          {isGohome && (
            <div
              className="fs12 cp w100 mt24 later-btn"
              onClick={() => navigate('/app/wallet')}
            >
              Go Home
            </div>
          )}
        </div>
      </div>
      {loading && (
        <div className="loading-box">
          <div className="loading-content">
            <span className="loader"></span>
          </div>
        </div>
      )}
    </>
  );
};

// bitverse
//  312636396063-31ftqi8mu7ujsqfki7377e1rf24cn7e2.apps.googleusercontent.com
// jeremy
// 905461873361-il7pfdcg4b6tio0j11ug98t45fajshi4.apps.googleusercontent.com
export const RecoveryWallet = () => {
  return (
    <GoogleOAuthProvider clientId="312636396063-31ftqi8mu7ujsqfki7377e1rf24cn7e2.apps.googleusercontent.com">
      <RecoveryBaseWallet />
    </GoogleOAuthProvider>
  );
};
