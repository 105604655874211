import React, { useEffect } from 'react';

export const Test = () => {
  useEffect(() => {
    fetch('/localapi/api')
      .then((res) => res.json())
      .then((res) => console.log(res));
  }, []);
  // 写一个 fetch 调用 /api 接口的代码

  return (
    <div className="home" style={{ color: '#000', padding: '0 30px' }}>
      <button
        onClick={() => {
          console.log('test');
        }}
      >
        test
      </button>
    </div>
  );
};
