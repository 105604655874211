import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Tabs, Toast, Image, Dialog, Switch } from 'antd-mobile';
import { transferAddress2Display } from '../../utils';
import copy from 'copy-to-clipboard';
import {
  EyeOutline,
  EyeInvisibleOutline,
  AddCircleOutline,
} from 'antd-mobile-icons';
import useNavigatePage from '../../hooks/use-navigate-page';
import { useWallet } from '../../hooks/use-wallet';
import { toThousands, mul, intercept, eq, lt, add } from '../../utils/number';
import './index.less';
import {
  BWWalletService,
  getAccountList,
} from '@bitverse-h5-wallet/bitverse-core';
import { AuthContext } from '../../contexts/AuthContext';
import { useNetwork } from '../../components/networks/useNetworks';

export const Wallet = () => {
  const navigate = useNavigate();
  const [eysIsShow, setEysIsShow] = useState(true);
  const { navigateToPage } = useNavigatePage();
  const { address, backupStatus } = BWWalletService.instance.getCurrentWallet();
  const switchStatus = localStorage.getItem('assetsSwitch') || 'true';
  const [assetSwitch, setAssetSwitch] = useState(
    switchStatus === 'true' ? true : false
  );
  const { tokenInfo } = useWallet({ assetSwitch });
  const { executeTodoTasks, isLogin } = useContext(AuthContext);

  const backupToast = async () => {
    const accountList = await getAccountList();
    if (accountList.length === 0) {
      return Dialog.alert({
        content: 'Please create a wallet first',
        confirmText: 'confirm',
        onConfirm: () => {},
      });
    }
    Dialog.confirm({
      content:
        'For the safety of your assets, please back up your current wallet first',
      cancelText: 'cancel',
      confirmText: 'confirm',
      onConfirm: () => {
        navigate('/backup');
      },
    });
  };

  const handleCopy = (text: string) => {
    if (backupStatus?.google && copy(text)) {
      return Toast.show({
        content: 'Copied',
        position: 'top',
      });
    }
    backupToast();
  };

  const colorName = (val: any) => {
    if (eq(val, 0)) return;
    return lt(val, 0) ? 'red' : 'green';
  };

  const handleDeposit = (url: any) => {
    if (backupStatus?.google) {
      navigateToPage(url);
      return;
    }
    backupToast();
  };

  const onAssetsSwitch = (e: any) => {
    setAssetSwitch(e);
    localStorage.setItem('assetsSwitch', e);
  };

  useEffect(() => {
    isLogin && executeTodoTasks();
  }, [isLogin]);

  return (
    <div className="wallet-wrap">
      <div className="wallet-content">
        <div className="df aic">
          <p className="df aic t2 f1" onClick={() => handleCopy(address)}>
            {transferAddress2Display(address)}
            <i className="icon-copy"></i>
          </p>
          <p className="fs12">
            View All Assets&nbsp;
            <Switch
              checked={assetSwitch}
              className="asset-switch"
              onChange={onAssetsSwitch}
            />
          </p>
        </div>
        <p className="df aic mt12">
          <span className="fs32 mr10">
            {eysIsShow
              ? `$${toThousands(tokenInfo?.totalAmount, 2)}`
              : '*******'}
          </span>
          {eysIsShow ? (
            <EyeOutline
              fontSize={20}
              color="#adb1b8"
              onClick={() => setEysIsShow(false)}
            />
          ) : (
            <EyeInvisibleOutline
              fontSize={20}
              color="#adb1b8"
              onClick={() => setEysIsShow(true)}
            />
          )}
        </p>
        <div className="mt24 df">
          <Button
            className="f1 btn-default mr10"
            onClick={() => handleDeposit('receive')}
          >
            Deposit
          </Button>
          <Button
            className="f1 btn-default"
            onClick={() => handleDeposit('send')}
          >
            Send
          </Button>
        </div>
      </div>
      <div className="list-wrap">
        <AddCircleOutline
          className="icon-add cp"
          fontSize={20}
          onClick={() => navigate('/token-manage')}
        />
        <Tabs>
          <Tabs.Tab title="Token" key="Token">
            {(tokenInfo?.result || []).length === 0 && (
              <div className="fs12 t2 tac mt32">No data</div>
            )}
            {(tokenInfo?.result || []).map((item: any, index: number) => (
              <div
                className="df aic token-lists"
                key={index}
                onClick={() => {
                  navigateToPage('detail', {
                    state: {
                      symbol: item?.symbol,
                      balance: item?.balance,
                      contract: item?.contract,
                      iconUrl: item?.iconUrl,
                      chainId: item?.chainId,
                      coinId: item?.coinId,
                    },
                  });
                }}
              >
                <div className="icon-wrap mr10">
                  <Image
                    src={item?.iconUrl}
                    width={32}
                    height={32}
                    style={{ borderRadius: 32 }}
                    fit="cover"
                  />
                  {assetSwitch && (
                    <Image
                      className="network-icon"
                      src={useNetwork(item?.chainId)?.network?.iconUrl}
                      width={14}
                      height={14}
                      style={{ borderRadius: 14 }}
                      fit="cover"
                    />
                  )}
                </div>
                <div className="f1">
                  <p className="fs16 fw600">{item?.symbol}</p>
                  <p className="fs12 t2">
                    ${toThousands(item?.price, 2)} USD
                    <span className={`${colorName(item.change24h)} ml10`}>
                      {intercept(mul(item.change24h, 100), 2)}%
                    </span>
                  </p>
                </div>
                {eysIsShow ? (
                  <div className="tar">
                    <p className="fs16 fw600">
                      {toThousands(item?.balance, 6)}
                    </p>
                    <p className="fs12 t2">
                      {toThousands(mul(item?.price, item?.balance), 2)} USD
                    </p>
                  </div>
                ) : (
                  '***'
                )}
              </div>
            ))}
          </Tabs.Tab>
          {/* <Tabs.Tab title="NFTs" key="NFTs"></Tabs.Tab> */}
        </Tabs>
      </div>
    </div>
  );
};
