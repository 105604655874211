import { BWSecureStorage } from '../bwSecureStorage';
import httpClient from '@bitverse-h5-wallet/bitverse-http-client';

import { WalletKey } from '../mpcWalletBackupRecover/type';
import { ScryptGenerator } from '../scryptGenerator';
import { Storage } from '../storage';
import { AccountListType, AccountType } from '../types/types';
import { mpcKeyGen } from '../utils/mpc-utils';
import { walletAddressFromMk } from '../utils/wallet-utils';
import { Networks } from '../networks';
import { getBaseDomain } from '../utils/utils';

const bitverseServerbaseURL = getBaseDomain();
interface WalletsState {
  walletMaps: Record<string, string>;
  currentWalletId: string;
  currentWallet: AccountType | null;
}
export const BWWalletStore = new Storage<WalletsState>(
  {
    walletMaps: {},
    currentWalletId: '',
    currentWallet: null,
  },
  'BWWalletStorage'
);

class BWWalletService {
  private static _instance: BWWalletService;
  public currentWalletId = '';
  public currentWallet: AccountType | null = null;

  private constructor() {
    this.currentWalletId = BWWalletStore.getItem('currentWalletId') ?? '';
    this.currentWallet = BWWalletStore.getItem('currentWallet');
  }
  public static get instance(): BWWalletService {
    if (!this._instance) {
      this._instance = new BWWalletService();
    }
    return this._instance;
  }
  public getAllWallets() {
    const storedWallets = BWWalletStore.getItem('walletMaps');
    const wallets: AccountListType = [];
    const { uid } = this.getUserInfo();
    Object.keys(storedWallets).forEach((key) => {
      if (key.endsWith(uid)) {
        const wallet = JSON.parse(storedWallets[key]);
        wallets.push(wallet);
      }
    });
    return wallets;
  }

  public async createMpcWallet(password: string) {
    try {
      const result = await mpcKeyGen();
      if (!result) return;
      const { id, master_key } = result;
      const walletMap = {
        curv: 'secp256k1',
        keyinfo: result,
      };
      const encrypted = await ScryptGenerator.encrypt(
        password,
        [JSON.stringify(walletMap)],
        id
      );

      const mapId = this.getWalletCombUid(id);
      await BWSecureStorage.write(mapId, encrypted);
      const walletAddress = await walletAddressFromMk(master_key);
      const wallet: AccountType = {
        name: this.genWalletName(),
        walletId: id,
        address: walletAddress,
        backupStatus: {
          icloud: false,
          google: false,
          dropbox: false,
        },
      };
      this.addWalletMeta(wallet);
      this.createWalletSyncBackend({
        walletId: id,
        address: walletAddress,
      });
      return true;
    } catch (error) {
      return false;
    }
  }
  public getWalletById(walletId: string) {
    const storedWallets = BWWalletStore.getItem('walletMaps');
    const mapId = this.getWalletCombUid(walletId);
    const wallet = JSON.parse(storedWallets[mapId]);
    return wallet;
  }
  public addWalletMeta(wallet: AccountType) {
    const { walletId } = wallet;
    if (!walletId) return;

    const storedWallets = BWWalletStore.getItem('walletMaps');
    const mapId = this.getWalletCombUid(walletId);
    storedWallets[mapId] = JSON.stringify(wallet);
    BWWalletStore.setItem('walletMaps', storedWallets);

    this.switchWallet(walletId, wallet);
  }

  public switchWallet(walletId: string, wallet?: AccountType) {
    this.currentWalletId = walletId;
    BWWalletStore.setItem('currentWalletId', walletId);
    const storedWallets = BWWalletStore.getItem('walletMaps');
    const mapId = this.getWalletCombUid(walletId);
    this.currentWallet = JSON.parse(
      storedWallets[mapId] ?? JSON.stringify(wallet)
    );
    BWWalletStore.setItem('currentWallet', this.currentWallet);
    return this.currentWallet;
  }
  public updateWalletBackupStatus(
    walletId: string,
    googlebackupStatus: boolean
  ) {
    const storedWallets = BWWalletStore.getItem('walletMaps');
    const mapId = this.getWalletCombUid(walletId);
    const wallet = JSON.parse(storedWallets[mapId]);
    Object.assign(wallet.backupStatus, {
      google: googlebackupStatus,
    });
    storedWallets[mapId] = JSON.stringify(wallet);
    BWWalletStore.setItem('walletMaps', storedWallets);
    this.currentWallet = wallet;
    BWWalletStore.setItem('currentWallet', this.currentWallet);
  }
  public getCurrentWallet() {
    return (
      ((this.currentWallet ??
        BWWalletStore.getItem('currentWallet')) as AccountType) ?? {}
    );
  }
  public getCurrentWalletId() {
    return this.currentWalletId ?? BWWalletStore.getItem('currentWalletId');
  }
  private genWalletName() {
    const wallets = this.getAllWallets();
    const walletName = `Wallet ${wallets.length}`;
    return walletName;
  }
  public async getCurrentWalletMasterKeyInfo(password: string) {
    const { walletId } = this.getCurrentWallet();
    const mapId = this.getWalletCombUid(walletId);
    const keygenResultStore = await BWSecureStorage.read(mapId);
    const keygenResultList: string[] = await ScryptGenerator.decrypt(
      password,
      keygenResultStore
    );
    if (!keygenResultList?.length) return null;

    const keygenResultOrigin = keygenResultList[0];
    let keygenResult: any = {};

    if (typeof keygenResultOrigin === 'string') {
      keygenResult = JSON.parse(keygenResultOrigin) as WalletKey;
    } else {
      keygenResult = keygenResultOrigin;
    }

    if (!keygenResult) {
      console.error('没有钱包信息');
      return null;
    }

    return keygenResult.keyinfo;
  }
  public async getMasterKeyInfoByWalletId(walletId: string, password: string) {
    const mapId = this.getWalletCombUid(walletId);
    const keygenResultStore = await BWSecureStorage.read(mapId);
    const keygenResultList: string[] = await ScryptGenerator.decrypt(
      password,
      keygenResultStore
    );
    if (!keygenResultList?.length) return;
    const keygenResult = JSON.parse(keygenResultList[0]) as WalletKey;
    if (!keygenResult) {
      console.error('没有钱包信息');
      return;
    }
    return keygenResult.keyinfo;
  }
  public async createWalletSyncBackend({
    walletId,
    address,
  }: {
    walletId: string;
    address: string;
  }) {
    const currentNetwork = Networks.instance.getCurrentNetwork();
    const params = {
      walletAddresses: [
        {
          coinId: currentNetwork.coinId,
          address,
          walletId,
          type: 'BW',
        },
      ],
    };
    const rep = await httpClient.post(
      `${bitverseServerbaseURL}/bitverse/wallet/v1/public/device/address/create`,
      { ...params }
    );
    if (rep?.retCode === 0) {
      // const { result } = rep;
      console.log('createWalletSyncBackend success');
    }
  }
  public getUserInfo() {
    const userInfo = JSON.parse(
      localStorage.getItem('bitverse_user_info') || '{}'
    );
    return userInfo as { email: string; uid: string };
  }
  public getWalletCombUid(id: string) {
    const { uid } = this.getUserInfo();
    const mapId = `${id}&${uid}`;
    return mapId;
  }
}

export { BWWalletService };
