import { useRef, useState } from "react";
import classnames from 'classnames';
import { EyeFill, EyeInvisibleFill } from "antd-mobile-icons";

interface PasswordInputProps {
  className?:string
  onFocus?: (...props: unknown[]) => void;
  onBlur?: (...props: unknown[]) => void;
  onChange?: (value: string) => void;
  [key: string]: unknown;
}

export const CustomPasswordInput = ({
  className,
  onFocus,
  onBlur,
  onChange,
  ...props
}: PasswordInputProps) => {
  const [visible, setVisible] = useState(false);
  const inputDivElement = useRef<HTMLInputElement>(null);
  const inputElement = useRef<HTMLInputElement>(null);
  return (
    <div 
      ref={inputDivElement}
      className={classnames(`df aic adm-input ${className ? className : ''}`)}
      onClick={()=>{
        inputDivElement.current?.classList.add('custom-input-active');
        inputElement.current?.focus();
      }}
      onBlur={()=>{
        inputDivElement.current?.classList.remove('custom-input-active');
        inputElement.current?.blur();
      }}
    >
      <input
        ref={inputElement}
        className="adm-input-element"
        {...props}
        onFocus={(e) => {
          if (onFocus) onFocus(e.target.value);
        }}
        onBlur={(e) => {
          if (onBlur) onBlur(e.target.value);
        }}
        onChange={(e)=>{
          if (onChange) onChange(e.target.value);
        }}
        type = {visible?'text':'password'}
      />
      <div className="fs18 t2" onClick={()=>{setVisible(!visible)}}
      >
        {!visible ? <EyeInvisibleFill /> : <EyeFill /> }
      </div>
    </div>
  );
};