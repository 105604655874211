import React, { useEffect, useState } from 'react';
import { Button, Radio, Toast } from 'antd-mobile';
import { useNavigate } from 'react-router-dom';
// import { Header } from '../../Layouts/Header';
import './index.less';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import {
  BWWalletService,
  BackupService,
  PasswordService,
} from '@bitverse-h5-wallet/bitverse-core';
import { getGoogleUserInfo } from '../../utils';

export const getGoogleAuthToken = (): Promise<string> => {
  return new Promise((resolve) => {
    // chrome.identity.getAuthToken({ interactive: true }, function (token: any) {
    //   console.log(token);
    //   resolve(token);
    // });
  });
};

const BaseWalletBackUp = () => {
  const navigate = useNavigate();
  const [currentWallet, setCurrentWallet] = useState(() =>
    BWWalletService.instance.getCurrentWallet()
  );
  const [authToken, setAuthToken] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [user, setCurrentUser] = useState<{
    accessToken: string;
    isUserLoggedIn: boolean;
  }>({ accessToken: '', isUserLoggedIn: false });

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      user.accessToken = codeResponse.access_token;
      user.isUserLoggedIn = true;
      setCurrentUser({ ...user });
    },
    onError: (error) => {
      console.log('error', error);
    },
    flow: 'implicit',
    // scope: 'https://www.googleapis.com/auth/drive',
    scope: 'https://www.googleapis.com/auth/drive.file',
  });

  const handleLogin = async () => {
    if (!(await PasswordService.instance.verify())) {
      window.location.href = '/pwd';
      return;
    }
    login();
  };

  const toastContent = (toastTxt, email) => {
    return (
      <div className="mt8">
        <div className="fs16 tac">{toastTxt}</div>
        <div className="fs12 mt8 t2">
          backup email:<p>{email}</p>
        </div>
      </div>
    );
  };

  useEffect(() => {
    async function handleBackup() {
      const { walletId, name } = BWWalletService.instance.getCurrentWallet();

      if (!(await PasswordService.instance.verify())) {
        window.location.href = '/pwd';
        return;
      }
      const password = await PasswordService.instance.getPassword();
      const googleUserInfo = await getGoogleUserInfo(user.accessToken);
      try {
        Toast.show({
          maskClassName: 'customize-toast',
          content: toastContent(`${name} backing up`, googleUserInfo?.email),
          icon: 'loading',
          duration: 0,
          maskClickable: false,
        });
        await BackupService.instance.handleBackup({
          token: user.accessToken,
          password,
          walletId,
        });
        BWWalletService.instance.updateWalletBackupStatus(walletId, true);
        setCurrentWallet(BWWalletService.instance.getCurrentWallet());
        Toast.show({
          maskClassName: 'customize-toast',
          icon: 'success',
          content: toastContent(
            `${name} backup success`,
            googleUserInfo?.email
          ),
        });
      } catch (error: any) {
        Toast.show({
          maskClassName: 'customize-toast',
          icon: 'fail',
          content: toastContent(
            error?.message ?? `${name} backup success`,
            googleUserInfo?.email
          ),
        });
        console.log(error);
      }
    }
    if (user.isUserLoggedIn && user.accessToken !== '') {
      handleBackup();
    }
  }, [user]);

  return (
    <>
      {/* <Header pageName="Backup Wallet" /> */}
      <div className="backup-wrap pd16">
        <div className="fs18">Backup Wallet</div>
        <div className="g-hint mt12">
          If you switch devices, you can easily restore your wallet. No private
          keys needed. Files will be backed up on your personal cloud drive,
          back up your files on multiple cloud drives for extra security.
        </div>
        <div className="t2 mt32">Choose Backup File</div>
        {currentWallet ? (
          <div className="g-item-list mt12">
            <div className="df aic cloud-disk-item">
              <div className="icon-wrap mr10 GoogleDrive"></div>
              <div className="f1">Google Drive</div>
              {currentWallet?.backupStatus?.google ? (
                <>
                  <Radio checked />
                  &nbsp; Backed up
                </>
              ) : (
                <Button
                  size="small"
                  color="primary"
                  fill="outline"
                  onClick={handleLogin}
                >
                  Sync Now
                </Button>
              )}
              {/* <Button
              size="small"
              color="primary"
              fill="outline"
              onClick={() => login()}
            >
             Sync Now
            </Button> */}
            </div>
            {/* <div className="df aic cloud-disk-item">
          <div className="icon-wrap mr10 iCloud"></div>
          <div className="f1">iCloud</div>
          <Button
            size="small"
            color="primary"
            fill="outline"
            onClick={handleUploadButtonClick}
          >
           Sync Now
          </Button>
        </div>
        <div className="df aic cloud-disk-item">
          <div className="icon-wrap mr10 DropBox"></div>
          <div className="f1">Drop Box</div>
          <Button
            size="small"
            color="primary"
            fill="outline"
            onClick={handleUploadButtonClick}
          >
            Sync Now
          </Button>
        </div> */}
          </div>
        ) : (
          <div style={{ padding: 24, color: 'red' }}>No wallet to back up</div>
        )}

        <div className="fs12 t2 lh16">
          Bitverse guarantees that you’ll be able to access your funds on matter
          what.
        </div>
        {currentWallet?.backupStatus?.google ? (
          <Button
            onClick={() => navigate('/app/wallet')}
            color="primary"
            className="w100 mt16"
            fill="solid"
            size="large"
          >
            Go Home
          </Button>
        ) : (
          <div
            className="fs12 cp w100 mt24 later-btn"
            onClick={() => navigate('/app/wallet')}
          >
            Backup Later
          </div>
        )}

        {/* <p>authToken: {authToken}</p> */}

        {isModalOpen && (
          <div className="loading-box">
            <div className="loading-content">
              <span className="loader"></span>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export const WalletBackUp = () => {
  return (
    <GoogleOAuthProvider clientId="312636396063-31ftqi8mu7ujsqfki7377e1rf24cn7e2.apps.googleusercontent.com">
      <div>
        <BaseWalletBackUp />
      </div>
    </GoogleOAuthProvider>
  );
};
