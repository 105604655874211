import React, { useEffect, useState, useContext } from 'react';
import {
  UnorderedListOutline,
  AddOutline,
  DownlandOutline,
  CheckCircleFill,
} from 'antd-mobile-icons';
import { Popup, Toast } from 'antd-mobile';
import { transferAddress2Display } from '../utils';
import useNavigatePage from '../hooks/use-navigate-page';
import {
  AccountListType,
  AccountType,
  BWWalletService,
  PasswordService,
  getAccountList,
} from '@bitverse-h5-wallet/bitverse-core';
import './index.less';
import { ProviderContext } from '../context';
import { eventBus } from '@bitverse-h5-wallet/bitverse-message-sdk';

export default function LeftMenu() {
  const [leftMenuVisible, setLeftMenuVisible] = useState(false);
  const [currentAccount, setCurrentAccount] = useState(
    () => BWWalletService.instance.getCurrentWallet()?.address
  );
  const { navigateToPage } = useNavigatePage();
  const [walletList, setWalletList] = useState<AccountListType>([]);
  const { globalState, globalDispatch } = useContext(ProviderContext);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // 获取钱包列表
  const getWalletList = async () => {
    const accountList = await getAccountList();
    setWalletList(accountList);
  };

  // 选择钱包
  const chooseAddressFun = (item: AccountType) => {
    BWWalletService.instance.switchWallet(item.walletId);
    setCurrentAccount(item.address);
    globalDispatch({
      type: 'get_address_action',
      payload: {
        address: item.address,
      },
    });
    eventBus.emit('accountsChanged', item.address);
    setLeftMenuVisible(false);
  };

  // 添加钱包
  const addAddressFun = async () => {
    setIsModalOpen(true);
    setLeftMenuVisible(false);
    if (!(await PasswordService.instance.verify())) {
      navigateToPage('auth');
      return;
    }
    const password = await PasswordService.instance.getPassword();
    const keygenResult = await BWWalletService.instance.createMpcWallet(
      password
    );
    Toast.show(`${keygenResult ? 'success' : 'Failed to create wallet'}`);
    chooseAddressFun(BWWalletService.instance.getCurrentWallet());
    setIsModalOpen(false);
  };

  useEffect(() => {
    setTimeout(() => {
      getWalletList();
    }, 100);
  }, []);

  return (
    <>
      <UnorderedListOutline
        className="cp"
        fontSize={22}
        onClick={() => {
          setLeftMenuVisible(true);
          getWalletList();
        }}
      />
      <Popup
        visible={leftMenuVisible}
        showCloseButton
        onClose={() => {
          setLeftMenuVisible(false);
        }}
        onMaskClick={() => {
          setLeftMenuVisible(false);
        }}
        position="left"
        className="left-menu-popup"
      >
        <div className="account-list">
          {walletList.map((item, index: number) => (
            <div
              key={index}
              className="df aic fs16 bborder pdtb16 cp"
              onClick={() => chooseAddressFun(item)}
            >
              <div className="f1">
                {item.name}
                <p className="fs12 t2 mt8">
                  {transferAddress2Display(item.address)}
                </p>
              </div>
              {currentAccount === item.address && (
                <CheckCircleFill fontSize={20} color={'#56FAA5'} />
              )}
            </div>
          ))}
        </div>
        <div className="pdtb16 cp" onClick={addAddressFun}>
          <AddOutline className="mr10" />
          Add wallet
        </div>
        <a
          href="https://www.bitverse.zone/download"
          target="_blank"
          rel="noreferrer"
        >
          <div
            className="pdtb16 cp"
            onClick={() => {
              // alert('下载app');
              setLeftMenuVisible(false);
            }}
          >
            <DownlandOutline className="mr10" />
            Download Bitverse App
          </div>
        </a>
      </Popup>
      {isModalOpen && (
        <div className="loading-box">
          <div className="loading-content">
            <span className="loader"></span>
          </div>
        </div>
      )}
    </>
  );
}
