import React, { useState } from 'react';
import { TabBar } from 'antd-mobile';
import { FolderOutline, SetOutline, TextOutline } from 'antd-mobile-icons';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

export const Footer: FC = () => {
  const navigate = useNavigate();
  const [activeKey, setActiveKey] = useState(location.pathname);

  const setRouteActive = (value: string) => {
    setActiveKey(value);
    navigate(value);
  };

  const tabs = [
    // {
    //   key: '/app/home',
    //   title: 'Test',
    //   icon: <FolderOutline />,
    // },
    {
      key: '/app/wallet',
      title: 'Wallet',
      icon: <FolderOutline />,
    },
    {
      key: '/app/dapps',
      title: 'DApps',
      icon: <TextOutline />,
    },
    {
      key: '/app/set',
      title: 'Set',
      icon: <SetOutline />,
    },
  ];

  return (
    <TabBar activeKey={activeKey} onChange={(value) => setRouteActive(value)}>
      {tabs.map((item) => (
        <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
      ))}
    </TabBar>
  );
};
