import { Buffer } from 'buffer';

export const NX_API_Base_URL = process.env.NX_API_Base_URL;

export function paddingLeft(data: string) {
  const data2 = data.padStart(64, '0');
  const bb = Buffer.from(data2, 'hex');
  return bb;
}

export const transferAddress2Display = (address: string | undefined) => {
  if (address && address.length >= 40) {
    return `${address.substr(0, 6)}...${address.substr(address.length - 6, 6)}`;
  } else {
    return address;
  }
};

export const getGoogleUserInfo = async (
  access_token: string
): Promise<{ email: string }> => {
  const res = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
    headers: { Authorization: `Bearer ${access_token}` },
  });

  const userInfo = await res.json();
  return userInfo;
};
