import { IStorage } from './interface';

export function getFromStorage<T, K extends string = string>(key: K): T | null {
  try {
    return JSON.parse(localStorage.getItem(key) ?? '') ?? null;
  } catch {
    return null;
  }
}

export function setToStorage(key: string, value: any) {
  return localStorage.setItem(key, JSON.stringify(value));
}

export function removeFromStorage(key: string) {
  return localStorage.removeItem(key);
}

export function clearStorage() {
  return localStorage.clear();
}

export class Storage<T extends Record<string, any>> implements IStorage<T> {
  private NS: string;
  public defaults: T;
  constructor(defaults: T, namespace = '') {
    this.NS = namespace;
    this.defaults = defaults;
  }

  getItem<K extends keyof T>(key: K): T[K] {
    return (
      getFromStorage<T[K]>(this.NS + ':' + key.toString()) ?? this.defaults[key]
    );
  }
  setItem<K extends keyof T>(key: K, value: T[K]) {
    return setToStorage(this.NS + ':' + key.toString(), value);
  }

  removeItem<K extends keyof T>(key: K) {
    return removeFromStorage(this.NS + ':' + key.toString());
  }
}
