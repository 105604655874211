import qrCode from 'qrcode-generator';
import './index.less';

interface QRCodeViewProps {
  data: string;
  color?: string;
  margin?: number;
  cellSize?: number;
}

function QrCodeView({
  data,
  margin,
  cellSize = 4,
}: QRCodeViewProps) {
  const address = data
  const qrImage = qrCode(4, 'M');
  qrImage.addData(address);
  qrImage.make();

  return (
    <div className="qr-code">
      <div
        className="qr-code__wrapper"
        dangerouslySetInnerHTML={{
          __html: qrImage
            .createTableTag(cellSize, margin)
        }}
      />
    </div>
  );
}

export default QrCodeView;
