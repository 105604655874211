import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Header } from '../../layouts/Header';
import { Button, Image, SearchBar, Tabs, SpinLoading } from 'antd-mobile';
import { CheckCircleFill } from 'antd-mobile-icons';
import './index.less';
import http from '@bitverse-h5-wallet/bitverse-http-client';
import {
  getSelectedTokenFromLocalStorage,
  setSelectedTokenFromLocalStorage,
} from '../../utils/storage';
import { useNetworks } from '../../components/networks/useNetworks';
import { NX_API_Base_URL } from '../../utils';

export const TokenManage = () => {
  const navigate = useNavigate();
  const searchStyle = {
    '--background': '#7474801f',
    '--height': '36px',
  };
  const [tokenList, setTokenList] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const supportChain = useNetworks();
  const [selectedToken, setSelectedToken] = useState<any>({});
  useEffect(() => {
    getSelectedToken();
    handleTokenList('', []);
  }, []);
  const getSelectedToken = async () => {
    const val = getSelectedTokenFromLocalStorage('');
    if (val) {
      const allObj = JSON.parse(val);
      const dict: any = {};
      if (allObj) {
        allObj.forEach((item: any) => {
          dict[item.chainId + item.contract] = item;
        });
        setSelectedToken(dict);
      } else {
        return;
      }
    }
  };

  const handleTokenList = async (keyword: string, coinIds: Array<number>) => {
    const supportChainId: any = {};
    supportChain.forEach((item) => {
      supportChainId[item.chainId ?? ''] = item;
    });
    setLoading(true);
    const res = await http.post(
      `${NX_API_Base_URL}/bitverse/wallet/v1/public/token/search`,
      {
        coinIds: coinIds,
        keyword: keyword,
      }
    );

    setLoading(false);
    //过滤掉只支持的链的token

    const list: any[] = [];
    (res?.result?.list ?? []).forEach((item: any) => {
      // eslint-disable-next-line no-prototype-builtins
      if (supportChainId.hasOwnProperty(item.chainId)) {
        list.push(item);
      }
    });
    setTokenList(list);
  };
  const filterToken = (chainId: number) => {
    const list: any[] = [];
    tokenList.forEach((item: any) => {
      if (item.chainId == chainId) {
        list.push(item);
      }
    });
    return list;
  };

  const listModel = (data: any) => {
    return (
      <div className="tokenmanage-list">
        {loading ? (
          <div className="loading">
            <SpinLoading />
          </div>
        ) : (
          <>
            {data.length === 0 && (
              <div className="fs12 t2 tac mt32">No data</div>
            )}
            {data.map((item: any, index: number) => {
              return (
                <div
                  className="df aic"
                  key={index}
                  onClick={() => {
                    const list: any = [];
                    const key = item.chainId + item.contract;
                    if (
                      // eslint-disable-next-line no-prototype-builtins
                      !selectedToken.hasOwnProperty(key)
                    ) {
                      setSelectedToken((prev: any) => {
                        const data = {
                          ...prev,
                        };

                        data[key] = item;
                        return data;
                      });
                      for (const k in selectedToken) {
                        list.push(selectedToken[k]);
                      }
                      // eslint-disable-next-line no-prototype-builtins
                      if (!selectedToken.hasOwnProperty(key)) {
                        list.push(item);
                      }
                    } else {
                      setSelectedToken((prev: any) => {
                        const data = {
                          ...prev,
                        };
                        delete data[key];

                        return data;
                      });
                      for (const k in selectedToken) {
                        if (key !== k) {
                          list.push(selectedToken[k]);
                        }
                      }
                    }

                    setSelectedTokenFromLocalStorage('', JSON.stringify(list));
                  }}
                >
                  <Image
                    className="mr10"
                    src={item.iconUrl}
                    width={24}
                    height={24}
                    style={{ borderRadius: 12 }}
                    fit="cover"
                  />
                  <p className="f1">{item.symbol}</p>
                  <CheckCircleFill
                    fontSize={20}
                    color={
                      selectedToken &&
                      // eslint-disable-next-line no-prototype-builtins
                      selectedToken.hasOwnProperty(item.chainId + item.contract)
                        ? '#56FAA5'
                        : '#ffffff38'
                    }
                  />
                </div>
              );
            })}
          </>
        )}
      </div>
    );
  };

  return (
    <>
      <Header pageName="Add Mainnet" />
      <SearchBar
        className="pd16"
        placeholder="Search currency name or enter contract address"
        style={searchStyle}
        onClear={() => {
          handleTokenList('', []);
        }}
        onSearch={(val) => {
          handleTokenList(val, []);
        }}
      />
      <div className="f1 token-wrap">
        <Tabs>
          <Tabs.Tab title="All" key="All">
            {listModel(tokenList)}
          </Tabs.Tab>
          {supportChain.map((item: any, index: any) => (
            <Tabs.Tab title={item.name} key={index}>
              {listModel(filterToken(item.chainId))}
            </Tabs.Tab>
          ))}
        </Tabs>
      </div>
      <div className="pd16">
        <Button
          onClick={() => navigate('/app/wallet')}
          color="primary"
          className="w100"
          fill="solid"
        >
          Confirm
        </Button>
      </div>
    </>
  );
};
