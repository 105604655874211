import { MasterKeyType } from '../types/types';

export interface WalletBackUpInfo {
  walletId: string;
  walletName: string;
  walletGenerateType: number;
  cloudDiskType: number;
  walletDerive: WalletDerive[];
  mpcClientMasterKey: MpcClientMasterKey[];
}

export interface WalletKey {
  curv: 'secp256k1';
  keyinfo: {
    id: string;
    master_key: MasterKeyType;
  };
}
export interface WalletDerive {
  derivationPath: string;
  coinType: number;
  address: string;
  publicKey: string;
}

export interface MpcClientMasterKey {
  walletAlgorithmType: number;
  cipherMasterKey: string;
}

export enum CloudType {
  none,
  icloud,
  googleDriver,
  dropbox,
}

export enum WalletAlgorithmType {
  algorithmTypeEcdsa = 1,
  algorithmTypeEddsa = 2,
  algorithmTypeSchnorr = 3,
}

export const cloudBackupDir = 'DO NOT DELETE Bitverse Backup';
