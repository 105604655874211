import React from 'react';
import './index.less';

type CardType = {
  title: string;
  children: React.ReactNode;
};

export const Card = ({ title, children }: CardType) => {
  return (
    <div className='card shadow'>
      <h3 className='mycard-title'>{title}</h3>
      <div className='mycard-body'>{children}</div>
    </div>
  );
};
