/* eslint-disable @nx/enforce-module-boundaries */
import { RequestArguments } from 'eip1193-provider';
import { providers } from 'ethers';
import { Configurations, RpcUrls } from './type';
import { getSignParamsMessage, getSignTypedDataParamsData, getSignTypedDataV4ParamsData, log } from './utils';
import {
  BitverseEnum,
  BitverseEnumLocal,
  DappSDK,
} from '@bitverse-h5-wallet/bitverse-dapp-sdk';
import localStorageManager from './localStorageManager';

type AppSettings = any;

export class JsonRpcProvider {
  private appSetting?: Omit<AppSettings, 'chain'>;
  private configurations: Configurations = { onAuthChain: true };
  public rpcUrls?: string;
  public http: any;
  public readonly bitverseWallet: DappSDK;

  constructor(
    configurations?: Configurations,
    appSetting?: Omit<AppSettings, 'chain'>,
    url?: string
  ) {
    this.appSetting = appSetting;
    if (configurations) {
      this.configurations = { ...this.configurations, ...configurations };
    }
    this.rpcUrls = url

    this.bitverseWallet = new DappSDK({
      url,
    });
  }

  set changeMessage(fn: any) {
    this.bitverseWallet.changeMessage = fn;
  }

  public async connect(params: any): Promise<Array<string> | undefined> {
    const account = await this.bitverseWallet.getAccount(params);
    if (account) return account;
  }

  public async disconnect() {
    await this.bitverseWallet.logout();
  }

  public async walletSwitchEthereumChain(params: any) {
    return await this.bitverseWallet.walletSwitchEthereumChain(params);
  }

  public async request(request: RequestArguments): Promise<any> {
    // if (request.method === BitverseEnum.ethSignTypedData) {
    //   return await this.bitverseWallet.signTypedData(
    //     getSignTypedDataParamsData(request.params as string[]),
    //     { onAuthChain: this.configurations.onAuthChain }
    //   );
    // } 
    if (request.method === BitverseEnum.ethSignTypedDataV4) {
      return await this.bitverseWallet.signTypedDataV4(
        // request.params,
        getSignTypedDataV4ParamsData(request.params as string[]),
        { onAuthChain: this.configurations.onAuthChain }
      );
    } else if (request.method === BitverseEnum.personalSign) {
      return await this.bitverseWallet.signMessage(
        getSignParamsMessage(request.params as string[]),
        { isEIP191Prefix: true, onAuthChain: this.configurations.onAuthChain }
      );
    } else if (request.method === BitverseEnum.ethSendTransaction) {
      const _params =
        request?.params && Array.isArray(request?.params) && request?.params[0]
          ? request?.params[0]
          : undefined;
      if (_params) {
        return await this.bitverseWallet.sendTransaction(_params);
      }
      throw new Error('eth_sendTransaction error');
    } else {
      return await this.http.send(
        request.method,
        (request.params || []) as Array<any>
      );
    }
  }

  public updatebitverseWalletConfig = (
    chainId: number | null,
    rpcUrl?: string
  ) => {
    log('updatebitverseWalletConfig', chainId, rpcUrl);
    localStorageManager.setItem(
      BitverseEnumLocal.dappChainId,
      String(chainId),
      60 * 24
    );

    if (rpcUrl) {
      localStorageManager.setItem(
        BitverseEnumLocal.dappRpcUrl,
        rpcUrl || '',
        60 * 24
      );
      this.http = new providers.JsonRpcProvider(rpcUrl);
    }
  };
}
