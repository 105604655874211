export const selectedTokenLocalStorageKey = 'token-list';

export const getSelectedTokenFromLocalStorage = (address: string) => {
  return localStorage.getItem(selectedTokenLocalStorageKey + '-' + address);
};

export const setSelectedTokenFromLocalStorage = (
  address: string,
  data: string
) => {
  localStorage.setItem(selectedTokenLocalStorageKey + '-' + address, data);
};
