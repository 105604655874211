// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import CryptoJS, { MD5 } from 'crypto-js';
import { ScryptGenerator } from '../scryptGenerator';
import { Storage } from '../storage';
import { getDeviceFingerprint } from '../utils/utils';
import { BWIndexedDBService } from '../bwIndexedDBService';
import { BWWalletService } from '../bwWalletService';

const passwordKey = 'B6QFqW6fJJwcpPnZsG4gGrZLGuR';
const secretKey = 'hRmFFojlrgjq6jo4Ik1rz1sa53CNShiFbg';
const secretValue =
  'hRmFFojlrgjq6jo4Ik1rz1sa53CNShiFbgVFevnW973txXb8oD2xQGL7tmM5SwtWRtgsstlw0HyQhsvfBmjz1QRRIRFZgTAIoOrH8o7XdeSSVuKFb8vC9SmhJp0FNNRQ';

interface PasswordServiceState {
  [passwordKey]: string;
  password: string;
}
const BWSecureStore = new Storage<PasswordServiceState>(
  {
    [passwordKey]: '',
    password: '',
  },
  'BWSecureStorage'
);

class PasswordService {
  private static _instance: PasswordService;
  public password = '';

  public static get instance(): PasswordService {
    if (!this._instance) {
      this._instance = new PasswordService();
    }
    return this._instance;
  }

  public async getPassword() {
    if (this.password) return this.password;
    const value = (await BWIndexedDBService.instance.read(passwordKey)) ?? '';
    const decryptValue = this.decrypt(value);
    return decryptValue;
  }

  public async hasPassword() {
    const value = await this.getPassword();
    const indexedValue =
      // 指纹变了，但是是有老密码的 解开的密钥是空的，这时候应该是验证密码
      (await BWIndexedDBService.instance.read(passwordKey)) ?? '';
    const flag = !!this.password || value || !!indexedValue;
    return !!flag;
  }
  public async setPassword(password: string) {
    try {
      const md5Pas = MD5(password).toString();
      this.setEncryptValue(md5Pas, secretValue);
      const encryptValue = this.encrypt(md5Pas);
      this.password = md5Pas;
      await BWIndexedDBService.instance.write(passwordKey, encryptValue);
    } catch (error) {
      console.error('设置密码报错', error);
    }
  }

  public async startSession(password: string) {
    if (this.password) {
      return true;
    }
    try {
      await this.setPassword(password);
      return true;
    } catch (error) {
      return false;
    }
  }

  public async verify(password = '') {
    try {
      if (!password) {
        const value = await this.getPassword();
        if (!value) return false;
        const keyinfo = await this.getEncryptValue(value);
        return !!keyinfo;
      } else {
        const md5Pass = MD5(password).toString();

        const keyinfo = await await this.getEncryptValue(md5Pass);
        // 用户输入的密码是正确的
        if (keyinfo) {
          this.setPassword(password);
          return true;
        }
        return false;
      }
    } catch (error) {
      return false;
    }
  }
  public encrypt(password: string) {
    const secretKey = getDeviceFingerprint();
    console.log('encrypt 指纹', secretKey);
    const encrypted = CryptoJS.AES.encrypt(password, secretKey).toString();
    console.log('encrypt 密码', encrypted);
    return encrypted;
  }
  public decrypt(password: string) {
    try {
      const secretKey = getDeviceFingerprint();
      console.log('decrypt 指纹', secretKey);
      const decrypted = CryptoJS.AES.decrypt(password, secretKey).toString(
        CryptoJS.enc.Utf8
      );
      console.log('decrypted 密码', decrypted);
      return decrypted;
    } catch (error) {
      return '';
    }
  }
  private async setEncryptValue(password: string, value: string) {
    const encrypted = await ScryptGenerator.encrypt(password, value);
    await BWIndexedDBService.instance.write(secretKey, encrypted);
  }
  private async getEncryptValue(password: string): Promise<string | null> {
    try {
      const encrypted = await BWIndexedDBService.instance.read(secretKey);
      if (!encrypted) return null;
      const decryptValue = await ScryptGenerator.decrypt(password, encrypted);
      return decryptValue;
    } catch (error) {
      return null;
    }
  }
  public async clearPassword() {
    this.password = '';
    await BWIndexedDBService.instance.delete(passwordKey);
    await BWIndexedDBService.instance.delete(secretKey);
  }
}
export { PasswordService };
