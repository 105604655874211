import { log } from "./utils";

class LocalStorageManager {
  // private localStorage: Storage;

  constructor() {
    // window.localStorage = window.localStorage;
  }

  public setItem(key: string, value: any, expirationMinutes?: number): void {
    const item = {
      value,
      expiration: expirationMinutes
        ? new Date().getTime() + expirationMinutes * 60 * 1000
        : undefined,
    };
    window.localStorage.setItem(key, JSON.stringify(item));
  }

  public getItem<T>(key: string): T | null {
    const itemString = window.localStorage.getItem(key);
    log(`getItem-${key}`, itemString)
    if (itemString) {
      try {
        const item = JSON.parse(itemString);
        if (
          item &&
          (!item.expiration || new Date().getTime() < item.expiration)
        ) {
          return item.value;
        } else {
          return null;
        }
      } catch (error) {
        return null;
      }
    }
    return null;
  }

  public removeItem(key: string): void {
    window.localStorage.removeItem(key);
  }
}

// 使用示例:
const localStorageManager = new LocalStorageManager();

export default localStorageManager;

// // 设置一个值，带有10分钟的过期时间
// localStorageManager.set("myKey", "myValue", 10);

// // 获取一个值
// const storedValue = localStorageManager.get<string>("myKey");

// if (storedValue !== null) {
//   console.log("存储的值是：" + storedValue);
// } else {
//   console.log("值不存在或已过期");
// }

// // 移除一个值
// localStorageManager.remove("myKey");
