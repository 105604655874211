import React, { createContext, PropsWithChildren } from 'react';
// import * as Comlink from 'comlink';
// import { mpcWorker } from './web-worker';
// import { mpcKeyGen } from '@bitverse-h5-wallet/bitverse-core';

// console.log('11111', mpcWorker);
const test = null;

const WorkerContext = createContext(null);

type WorkerProviderProps = PropsWithChildren<Record<string, unknown>>;

const WorkerProvider = (props: WorkerProviderProps) => {
  return (
    <WorkerContext.Provider value={test}>
      {props.children}
    </WorkerContext.Provider>
  );
};

export { WorkerContext };
export default WorkerProvider;
