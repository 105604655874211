// import {
//   isApprove,
// } from '@bitverse-h5-wallet/bitverse-wallet-core';
import { BitverseEnum, BitverseEnumLocal } from './constants';
import { EventEmitter } from 'events';
import { log } from './utils';
export const transferMethodId = '0xa9059cbb';
export const approveMethodId = '0x095ea7b3';

// 判断是否是合约交易
export const isTransfer = (txData: string) =>
  txData.substring(0, 10) === transferMethodId;

// 判断是否是授权交易
export const isApprove = (txData: string) => {
  if (!txData) {
    return false;
  } else {
    return txData.substring(0, 10) === approveMethodId;
  }
};

export class DappSDK {
  private onEvent = new Map();
  private walletOpenTypeMap: Map<string, any> = new Map();
  private walletUrl: string;
  public changeMessage: any;
  public events: EventEmitter = new EventEmitter();
  constructor({ url }: any) {
    this.walletUrl = url;
    this.onWalletMessage();
  }

  public async getAccount(params: any): Promise<Array<string>> {
    return new Promise((resolve, reject) => {
      const type = BitverseEnum.ethRequestAccounts;
      this.events.on(type, resolve);
      this.events.on(`${type}-error`, reject);
      this.walletOpenTypeMap.set(type, {
        message: {
          type,
          params,
        },
      });
      this.openWin(type);
    });
  }

  public async login(option: any) {
    return null;
  }

  public async logout() {
    return null;
  }

  public async signTypedData(option: any, params: any) {
    return new Promise((resolve, reject) => {
      const type = BitverseEnum.ethSignTypedData;
      this.events.on(type, resolve);
      this.events.on(`${type}-error`, reject);
      this.walletOpenTypeMap.set(type, {
        message: {
          type,
          params: {
            option,
            params,
            type,
          },
        },
      });
      this.openWin(type);
    });
  }
  public async signTypedDataV4(option: any, params: any) {
    return new Promise((resolve, reject) => {
      const type = BitverseEnum.ethSignTypedDataV4;
      this.events.on(type, resolve);
      this.events.on(`${type}-error`, reject);
      this.walletOpenTypeMap.set(type, {
        message: {
          type,
          params: {
            option,
            params,
            type,
          },
        },
      });
      this.openWin(type);
    });
  }

  public async walletSwitchEthereumChain(params: any) {
    return new Promise((resolve, reject) => {
      const type = BitverseEnum.walletSwitchEthereumChain;
      this.events.on(type, resolve);
      this.events.on(`${type}-error`, reject);
      this.walletOpenTypeMap.set(type, {
        message: {
          type,
          params: {
            params,
            type,
          },
        },
      });
      this.openWin(type);
    });
  }

  public async signMessage(option: any, params: any) {
    return new Promise((resolve, reject) => {
      const type = BitverseEnum.personalSign;
      this.events.on(type, resolve);
      this.events.on(`${type}-error`, reject);
      this.walletOpenTypeMap.set(type, {
        message: {
          type,
          params: {
            option,
            params,
            type,
          },
        },
      });
      this.openWin(type);
    });
  }

  public async sendTransaction(option: any) {
    const type = isApprove(option.data)
      ? BitverseEnum.ethSendTransactionApprove
      : BitverseEnum.ethSendTransaction;
    return new Promise((resolve, reject) => {
      this.events.on(type, resolve);
      this.events.on(`${type}-error`, reject);
      this.walletOpenTypeMap.set(type, {
        message: {
          type,
          params: option,
        },
      });
      this.openWin(type);
    });
  }

  public async updateConfig(option: any) {
    return option;
  }

  private onWalletMessage() {
    window.addEventListener('message', (e) => {
      if (e.data.type === BitverseEnumLocal.messageSdkMessage) {
        log('收到钱包消息-onMessage', e.data.data);
        switch (e.data.data.type) {
          case BitverseEnumLocal.messageSdkInit:
            this.sendWalletMessage(e.data.data.source);
            break;
          case BitverseEnum.chainChanged:
            this.changeMessage?.(
              e.data.data.type,
              e.data.data.params,
              e.data.data.error
            );
            break;
          case BitverseEnum.accountsChanged:
            this.changeMessage?.(
              e.data.data.type,
              e.data.data.params,
              e.data.data.error
            );
            break;
          case BitverseEnum.chainGet:
            this.changeMessage?.(
              e.data.data.type,
              e.data.data.params,
              e.data.data.error
            );
            break;
          default:
            this.messageResolve(
              e.data.data.type,
              e.data.data.params,
              e.data.data.error
            );
            break;
        }
      }
    });
  }

  private messageResolve(type: string, data: any, error: any) {
    const wallet = this.walletOpenTypeMap.get(type);
    if (wallet) {
      if (error) {
        this.events.emit(`${type}-error`, error);
      } else {
        this.events.emit(type, data);
      }

      this.events.removeAllListeners(type);
      wallet.openWin.close();
      this.walletOpenTypeMap.set(type, null);
    }
  }

  private sendWalletMessage(type: string, fn?: any) {
    const wallet = this.walletOpenTypeMap.get(type);
    log('sendWalletMessage', type, wallet);
    if (wallet && wallet.openWin) {
      wallet.openWin.postMessage(
        {
          type: BitverseEnumLocal.ethereumProviderSdkMessage,
          data: {
            type: wallet.message.type,
            source: type,
            params: wallet.message.params || {},
          },
        },
        '*'
      );
    }
  }

  openWin(type: string) {
    log('打开钱包窗口', type);
    if (
      this.walletOpenTypeMap.get(type) &&
      this.walletOpenTypeMap.get(type).openWin
    ) {
      this.walletOpenTypeMap.get(type).openWin.close();
      this.walletOpenTypeMap.set(type, null);
    }
    const windowWidth = 375; // 窗口宽度
    const windowHeight = 700; // 窗口高度
    const screenWidth = window.screen.availWidth - 200; // 屏幕宽度
    const screenHeight = window.screen.availHeight - 50; // 屏幕高度

    const windowLeft = screenWidth - windowWidth; // 窗口左边距离屏幕左边的距离
    const windowTop = -screenHeight; // 窗口上边距离屏幕上边的距离

    const openWin = window.open(
      `${this.walletUrl}?source=${type}`,
      type,
      // eslint-disable-next-line max-len
      `height=${windowHeight}, width=${windowWidth},left=${windowLeft},top=${windowTop}, toolbar=no, menubar=no, scrollbars=no, resizable=no, location=no, status=no`
    );
    log('openWin', openWin);
    this.walletOpenTypeMap.set(type, {
      ...this.walletOpenTypeMap.get(type),
      openWin,
    });
    const loop = setInterval(() => {
      // 使用定时器查询当前状态
      if (
        this.walletOpenTypeMap.get(type) &&
        this.walletOpenTypeMap.get(type).openWin?.closed
      ) {
        // 进行判断条件   closed属性就是返回当前窗口的状态
        this.walletOpenTypeMap.set(type, null);
        clearInterval(loop); // 清除定时器
        log('User Closed', type);
        this.events.emit(`${type}-error`, `User Closed ${type}`);
        // Logical code
      }
    }, 500);
    return openWin;
  }
}
