import { utils } from 'ethers';
import { RpcUrls } from './type';
type ChainType = any;
type Environment = any;

// supported chain ids, do not change
// ethereum
export const ETHEREUM_MAINNET = 1;
export const ETHEREUM_GOERLI = 5;

// polygon
export const POLYGON_MAINNET = 137;
export const POLYGON_MUMBAI = 80001;

// bsc
export const BSC_MAINNET = 56;
export const BSC_TESTNET = 97;

// rangers
export const RANGERS_MAINNET = 2025;
export const RANGERS_ROBIN = 9527;

// scroll testnet only
export const SCROLL_TESTNET = 534354;

// arbitrum
export const ARBITRUM_TESTNET = 421613;
export const ARBITRUM_MAINNET = 42161;

// avax
export const AVALANCHE_TESTNET = 43113;
export const AVALANCHE_MAINNET = 43114;

// kcc
export const KCC_TESTNET = 322;
export const KCC_MAINNET = 321;

// okc
export const OKC_TESTNET = 65;
export const OKC_MAINNET = 66;

// platon
export const PLATON_TESTNET = 2206132;
export const PLATON_MAINNET = 210425;


export const SUPPORTED_MAINNET_CHAIN_ID = [
  ETHEREUM_MAINNET,
  POLYGON_MAINNET,
  BSC_MAINNET,
  RANGERS_MAINNET,
  ARBITRUM_MAINNET,
  AVALANCHE_MAINNET,
  KCC_MAINNET,
  OKC_MAINNET,
  PLATON_MAINNET,
];
export const SUPPORTED_TESTNET_CHAIN_ID = [
  ETHEREUM_GOERLI,
  POLYGON_MUMBAI,
  BSC_TESTNET,
  RANGERS_ROBIN,
  SCROLL_TESTNET,
  ARBITRUM_TESTNET,
  AVALANCHE_TESTNET,
  KCC_TESTNET,
  OKC_MAINNET,
  PLATON_TESTNET,
];




function convertHexToUtf8(value: string) {
  if (utils.isHexString(value)) {
    return utils.toUtf8String(value);
  }

  return value;
}

export function getSignParamsMessage(params: string[]) {
  const message = params.filter((p) => !utils.isAddress(p))[0];

  return convertHexToUtf8(message);
}

export function getSignTypedDataParamsData(params: string[]) {
  const data = params.filter((p) => !utils.isAddress(p))[0];

  if (typeof data === 'string') {
    return JSON.parse(data);
  }

  return data;
}

export function getSignTypedDataV4ParamsData(params: string[]) {
  const data = params.filter((p) => !utils.isAddress(p))[0];

  if (typeof data === 'string') {
    return JSON.parse(data);
  }

  return data;
}

export const log = (type: string, ...arg: any) => console.log(`%cProvider:${type}`, 'color: blue; font-size: 12px; background: yellow;', ...arg);

