import {
  GoogleLogin,
  GoogleOAuthProvider,
  useGoogleLogin,
} from '@react-oauth/google';
import React from 'react';

export const GoogleAuth = () => {
  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => console.log(tokenResponse),
  });

  return (
    <GoogleOAuthProvider clientId='376627488721-0jvjmashognvboe5qnnthvcje3qt9ovj.apps.googleusercontent.com'>
      <GoogleLogin
        onSuccess={(credentialResponse: any) => {
          console.log(credentialResponse);
        }}
        onError={() => {
          console.log('Login Failed');
        }}
      />
    </GoogleOAuthProvider>
  );
};
