// 包含签名、授权、二次弹窗内容
export const handlePageInfo = (type: 'auth' | 'sign' | 'confirm') => {
  switch (type) {
    case 'auth':
      return {
        title: 'Confirm Authorization',
        tipTitle: 'Authorization Risk Warning',
        tipContent:
          'After authorization, the contract will have the transfer authority of the token, please pay attention to the authorized amount to ensure asset security.',
        btnTxt: 'Confirm authorization',
      };
    case 'sign':
      return {
        title: 'Signature Information',
        tipTitle: 'Risk Warning',
        tipContent:
          'This is a third-party application. Be sure to confirm the signature information to ensure the safety of funds.',
        btnTxt: 'Confirm',
      };
    default: // confirm
      return {
        title: 'Transaction Detail',
        tipTitle: 'Risk Warning',
        tipContent:
          'This is a third-party application. Be sure to confirm the signature information to ensure the safety of funds.',
        btnTxt: 'Confirm',
      };
  }
};

export const handleDetailData = (type: 'auth' | 'sign' | 'confirm') => {
  switch (type) {
    case 'auth':
      return [
        {
          name: 'Authorized Contract',
          info: '',
          isCopy: true,
          canEdit: false,
        },
        {
          name: 'Approved Spender',
          info: '',
          isCopy: true,
          canEdit: false,
        },
        {
          name: 'Approved Token',
          info: '',
          isCopy: false,
          canEdit: false,
        },
        {
          name: 'Approved Amount',
          info: '',
          isCopy: false,
          canEdit: true,
          redirectLink: '/changeAuthNum',
        },
        // {
        //   name: 'Gas Fee',
        //   info: '0.0006915 ETH ~ 0.000774 ETH',
        //   isCopy: false,
        //   canEdit: false,
        // },
      ];
    case 'sign':
      return [
        {
          name: 'Transaction Type',
          info: 'SignMessage',
          isCopy: false,
          canEdit: false,
        },
        {
          name: 'Signature Content',
          info: '',
          isCopy: false,
          canEdit: false,
        },
      ];
    default:
      return [
        {
          name: 'From',
          info: '',
          isCopy: true,
          canEdit: false,
        },
        {
          name: 'To',
          info: '',
          isCopy: true,
          canEdit: false,
        },
        {
          name: 'Asset',
          info: 'ETH',
          isCopy: false,
          canEdit: false,
        },
        {
          name: 'Amount',
          info: '1,999',
          isCopy: false,
          canEdit: false,
        },
      ];
  }
};
