import { Fragment, useContext, useEffect, useState } from 'react';
import { Header } from '../../layouts/Header';
import { CustomPasswordInput } from './components/PasswordInput';
import { Button, Popup, Toast } from 'antd-mobile';
import { AuthContext } from '../../contexts/AuthContext';
import { PasswordCheckPassed } from './components/PasswordCheckPassed';
import { PasswordService } from '@bitverse-h5-wallet/bitverse-core';
import http from '@bitverse-h5-wallet/bitverse-http-client';
import { NX_API_Base_URL } from '../../utils';
import { CloseOutline } from 'antd-mobile-icons';

const PasswordPage = () => {
  const [psd, setPsd] = useState('');
  const [doublePsd, setDoublePsd] = useState('');
  const [passwordCheckPassed, setPasswordCheckPassed] = useState<boolean>(true);
  const { auth } = useContext(AuthContext);
  const passwordInstance = PasswordService.instance;
  // const isSetStage = !passwordInstance.hasPassword();

  const [isSetStage, setIsSetStage] = useState<boolean>(false);
  useEffect(() => {
    (async () => {
      setIsSetStage(!(await passwordInstance.hasPassword()));
    })();
  }, []);
  //true-设置密码 false-验证密码

  const submit = async () => {
    if (isSetStage && psd !== doublePsd) {
      Toast.show({
        icon: 'fail',
        content: "Password don't match",
        position: 'bottom',
      });
      return;
    }
    auth(psd);
  };

  return (
    <div>
      <Header
        pageName={isSetStage ? 'Set Wallet Password' : 'Input Wallet Password'}
      />
      <div className="pd16">
        <div className="g-hint">
          Your password will be used for asset management verification and
          account login verification. Please keep it safe!
        </div>
        <CustomPasswordInput
          className="mt16"
          onChange={setPsd}
          placeholder="Enter your password"
        />

        {isSetStage && (
          <Fragment>
            <PasswordCheckPassed
              value={psd}
              setPassed={(pass) => {
                setPasswordCheckPassed(pass);
              }}
            />
            <CustomPasswordInput
              className="mt16"
              onChange={setDoublePsd}
              placeholder="Enter your password again"
            />
          </Fragment>
        )}

        <Button
          color="primary"
          className="w100 mt32"
          fill="solid"
          size="large"
          disabled={isSetStage && !passwordCheckPassed}
          onClick={submit}
        >
          Confirm
        </Button>
      </div>
    </div>
  );
};

const PasswordPicker = ({
  visible,
  onClose,
  onConfirm,
  needEmailCode = false,
}: {
  visible: boolean;
  onClose: () => void;
  onConfirm: (password: string) => void;
  needEmailCode?: boolean;
}) => {
  const [psd, setPsd] = useState('');
  const [doublePsd, setDoublePsd] = useState('');
  const passwordInstance = PasswordService.instance;
  const [passwordCheckPassed, setPasswordCheckPassed] = useState<boolean>(true);
  // const isSetStage = !passwordInstance.hasPassword();
  const [codeAvailable, setCodeAvailable] = useState(true);
  const [emailCode, setEmailCode] = useState('');
  const [countDownSecond, setCountDownSecond] = useState(0);
  const [hasVerifyCode, setHasVerifyCode] = useState(false);
  const [isSetStage, setIsSetStage] = useState<boolean>(false);
  useEffect(() => {
    (async () => {
      setIsSetStage(!(await passwordInstance.hasPassword()));
    })();
  }, []);

  const handleCountDown = () => {
    setCodeAvailable(false);
    let timeo = 60;
    setCountDownSecond(timeo);
    const timeStop = setInterval(() => {
      timeo--;
      if (timeo > 0) {
        setCountDownSecond(timeo);
      } else {
        setCountDownSecond(timeo);
        setCodeAvailable(true);
        clearInterval(timeStop); //清除定时器
      }
    }, 1000);
  };

  // 获取验证码
  const handleGetCode = () => {
    handleCountDown();
    http.post(`${NX_API_Base_URL}/bitverse/wallet/v1/private/user/code/send`, {
      bizType: 'TRANS',
    });
  };

  const verifyEmailCode = async () => {
    if (hasVerifyCode) {
      return true;
    }
    const res = await http.post(
      `${NX_API_Base_URL}/bitverse/wallet/v1/private/user/code/verify`,
      {
        bizType: 'TRANS',
        verifyCode: emailCode,
      }
    );
    if (res && res.retCode == 0 && res.result?.result) {
      setHasVerifyCode(true);
      return true;
    }
    Toast.show({
      icon: 'fail',
      content: 'Verification code is incorrect',
      position: 'bottom',
    });
    return false;
  };
  const submit = async () => {
    if (isSetStage && psd !== doublePsd) {
      Toast.show({
        icon: 'fail',
        content: "Password don't match",
        position: 'bottom',
      });
      return;
    }
    if (needEmailCode && !(await verifyEmailCode())) {
      return;
    }
    if (!isSetStage && !(await PasswordService.instance.verify(psd))) {
      Toast.show({
        icon: 'fail',
        content: 'Password error!',
        position: 'bottom',
      });
      return;
    }
    isSetStage && (await PasswordService.instance.setPassword(psd));
    onConfirm(psd);
  };

  return (
    <Popup visible={visible} onClose={onClose} closeOnMaskClick={false}>
      <div className="password-wrap pd16 mt32">
        <div className="df jcfe">
          <CloseOutline className="t2 fs16 mb16 cp" onClick={onClose} />
        </div>
        <div className="g-hint">
          Your password will be used for asset management verification and
          account login verification. Please keep it safe!
        </div>
        <CustomPasswordInput
          className="mt16"
          onChange={setPsd}
          placeholder="Enter your password"
        />
        {isSetStage && (
          <Fragment>
            <PasswordCheckPassed
              value={psd}
              setPassed={(pass) => {
                setPasswordCheckPassed(pass);
              }}
            />
            <CustomPasswordInput
              className="mt16"
              onChange={setDoublePsd}
              placeholder="Enter your password again"
            />
          </Fragment>
        )}
        {needEmailCode && (
          <div className="df aic mt16 adm-input">
            <input
              className="f1 adm-input-element"
              placeholder="Enter the verification code"
              onChange={(e: any) => {
                setEmailCode(e.target.value);
              }}
            />
            <Button
              className="get-code-btn"
              color="primary"
              fill="outline"
              disabled={!codeAvailable}
              onClick={handleGetCode}
              size="small"
            >
              Get Code{countDownSecond > 0 && `(${countDownSecond})`}
            </Button>
          </div>
        )}
        <Button
          color="primary"
          className="w100 mt32 mb24"
          fill="solid"
          size="large"
          disabled={
            !psd ||
            (isSetStage && !passwordCheckPassed) ||
            (needEmailCode && !emailCode)
          }
          onClick={submit}
        >
          Confirm
        </Button>
      </div>
    </Popup>
  );
};

export default PasswordPage;
export { PasswordPicker };
