import { useContext, useMemo, useState } from 'react';
import { Header } from '../../layouts/Header';
import { Button, Input, Toast } from 'antd-mobile';

import { STAGE } from '../../hooks/use-navigate-page';
import { useNavigate, useSearchParams } from 'react-router-dom';

import http from '@bitverse-h5-wallet/bitverse-http-client';

import './index.less';
import { userInfoStorageKey } from '../../constants';
import {
  BWWalletService,
  PasswordService,
} from '@bitverse-h5-wallet/bitverse-core';
import { t } from 'i18next';
import { PasswordPicker } from '../Password';
import { NX_API_Base_URL } from '../../utils';
import { AuthContext } from '../../contexts/AuthContext';

const mailReg =
  /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;

const EmailAddress = () => {
  const navigate = useNavigate();
  const { setIsLogin } = useContext(AuthContext);

  const [searchParams] = useSearchParams();
  const type = searchParams.get('type');

  const [emailAdd, setEmailAdd] = useState('');
  const [emailCode, setEmailCode] = useState('');
  const [invitationCode, setInvitationCode] = useState('');
  const [codeAvailable, setCodeAvailable] = useState(false);
  const [countDownSecond, setCountDownSecond] = useState(0);
  const [loading, setLoading] = useState(false);

  const [visible, setVisible] = useState(false);
  // 获取验证码
  const handleGetCode = async () => {
    handleCountDown();
    await http.post(
      `${NX_API_Base_URL}/bitverse/wallet/v1/public/user/verifycode/send`,
      {
        email: emailAdd.trim(),
        bizType: 'SIGNIN',
      }
    );
  };

  // 验证码60秒计时
  const handleCountDown = () => {
    setCodeAvailable(false);
    let timeo = 60;
    setCountDownSecond(timeo);
    const timeStop = setInterval(() => {
      timeo--;
      if (timeo > 0) {
        setCountDownSecond(timeo);
      } else {
        setCountDownSecond(timeo);
        setCodeAvailable(true);
        clearInterval(timeStop); //清除定时器
      }
    }, 1000);
  };

  // 验证邮箱地址
  const validEmailAddress = (val: string) => {
    const valid = mailReg.test(val.trim());
    setCodeAvailable(valid && countDownSecond === 0);
    return valid;
  };

  const onChange = (val: string) => {
    validEmailAddress(val);
    setEmailAdd(val);
  };

  const onCodeFocus = () => {
    const valid = validEmailAddress(emailAdd);
    emailAdd &&
      !valid &&
      Toast.show({
        icon: 'fail',
        content: 'Invalid email!',
        position: 'bottom',
      });
  };

  const auth = async () => {
    setVisible(false);
    if (type === STAGE.CREATE) {
      setLoading(true);
      const password = await PasswordService.instance.getPassword();
      const keygenResult = await BWWalletService.instance.createMpcWallet(
        password
      );

      if (keygenResult) {
        navigate('/backup');
      } else {
        Toast.show({
          content: 'Failed to create wallet',
          icon: 'fail',
          position: 'bottom',
        });
      }
    } else if (type === STAGE.RECOVERY) {
      navigate('/recovery');
    }
  };

  // 邀请码校验
  const checkInvitationCode = async () => {
    const res = await http.post(
      `${NX_API_Base_URL}/bitverse/bitdapp/v1/public/quest/invite/code/check`,
      {
        reqFrom: 'WALLET_USER_REGISTER',
        bizDomain: 'WALLET_REGISTER_01',
        inviteCode: invitationCode?.trim(),
      }
    )
    if(res.retCode == 0){
      return true
    }
    Toast.show({
      icon: 'fail',
      content: res.retMsg,
      position: 'bottom',
    });
    return false
  }

  // 邮箱验证码校验
  const onSubmit = async () => {
    setLoading(true);
    if(!invitationCode?.trim() || await checkInvitationCode()){
      const res = await http.post(
        `${NX_API_Base_URL}/bitverse/wallet/v1/public/user/signin`,
        {
          email: emailAdd.trim(),
          verifyCode: emailCode,
          invitationCode: invitationCode?.trim() || undefined,
        }
      );
      if (res.retCode == 0) {
        const { uid } = res.result;
        localStorage.setItem(
          userInfoStorageKey,
          JSON.stringify({
            email: emailAdd.trim(),
            uid,
          })
        );
        setIsLogin(true);
        setVisible(true);
      } else {
        Toast.show({
          icon: 'fail',
          content: res.retMsg,
          position: 'bottom',
        });
      }
    }
    setLoading(false);
  };

  // 提交按钮限制
  const disabled = useMemo(() => {
    const str =
      !emailAdd.trim() || !emailCode.trim() || !mailReg.test(emailAdd.trim());
    return Boolean(str);
  }, [emailAdd, emailCode]);

  return (
    <div>
      <Header
        pageName={
          type === STAGE.CREATE ? 'Create MPC Wallet' : 'Login with App wallet'
        }
      />
      <div className="pd16">
        <Input
          value={emailAdd}
          placeholder="Email address"
          name="email"
          autoComplete="username"
          onChange={onChange}
        />
        <div className="df aic mt16 adm-input">
          <input
            className="f1 adm-input-element"
            placeholder="Enter the verification code"
            onChange={(e: any) => {
              setEmailCode(e.target.value);
            }}
            onFocus={onCodeFocus}
            type="text"
          />
          <Button
            className="get-code-btn"
            color="primary"
            fill="outline"
            disabled={!codeAvailable}
            onClick={handleGetCode}
            size="small"
          >
            Get Code{countDownSecond > 0 && `(${countDownSecond})`}
          </Button>
        </div>
        {type === STAGE.CREATE ? <Input
          className='mt16'
          value={invitationCode}
          placeholder="Invitation Code(Optional)"
          onChange={setInvitationCode}
        /> : null}
        <Button
          color="primary"
          className="w100 mt32"
          fill="solid"
          onClick={onSubmit}
          disabled={disabled}
          size="large"
          loading={loading}
        >
          Continue
        </Button>
        <div className="fs12 lh16 t2 mt24">
          <p>Tips:</p>
          {type === STAGE.CREATE
            ? 'Easily manage assets across H5 or Bitverse app, with seamless interoperability!'
            : 'Log in using your Bitverse app wallet or effortlessly restore your cloud-backed wallet assets.'}
        </div>
      </div>
      <PasswordPicker
        visible={visible}
        onClose={() => setVisible(false)}
        onConfirm={auth}
      />
    </div>
  );
};

export default EmailAddress;
