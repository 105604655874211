import { useEffect, useState, useContext } from 'react';
import Authorization from '../Authorization';
import { handleDetailData } from '../Authorization/configuration';
import {
  recoverSpenderAndAmount,
  sendApprove,
} from '@bitverse-h5-wallet/bitverse-core';
import { ProviderContext } from '../../context';
import { useCurrentNetwork } from '../../components/networks/useNetworks';
import { eventBus } from '@bitverse-h5-wallet/bitverse-message-sdk';
import httpClient from '@bitverse-h5-wallet/bitverse-http-client';
import { NX_API_Base_URL } from '../../utils';
import './index.less';
import { Toast } from 'antd-mobile';
import { PasswordPicker } from '../Password';

interface AuthTS {
  data: string;
  to: string;
}

export const Auth = () => {
  const network = useCurrentNetwork();
  const { globalState } = useContext(ProviderContext);
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [auth, setAuth] = useState<AuthTS>();
  const [decimals, setDecimals] = useState(0);
  const [token, setToken] = useState('');
  const [amount, setAmount] = useState('');
  const [spender, setSpender] = useState('');
  const [pwdVisible, setPwdVisible] = useState(false);

  useEffect(() => {
    setAuth(globalState.navigateParams);
  }, [globalState.navigateParams]);

  useEffect(() => {
    // 根据合约调用接口获取 精度及币种信息
    handleContractDetail(auth?.to || '');
  }, [auth]);

  const handleContractDetail = async (contractAddress: string) => {
    try {
      setPageLoading(true);
      const rtn = await httpClient.post(
        `${NX_API_Base_URL}/bitverse/wallet/v1/public/token/search`,
        {
          coinIds: [network.coinId],
          keyword: contractAddress, // 合约地址
        }
      );
      const { result, retCode } = rtn;
      if (retCode === 0) {
        const { list } = result;
        setDecimals(list[0]?.decimals || 0);
        setToken(list[0]?.symbol || '');
        // 解析 data
        const { amount, spender } = recoverSpenderAndAmount(
          auth?.data ?? '',
          list[0]?.decimals
        );
        setAmount(amount);
        // setSpender(`0x${spender}`);
        setSpender(`${spender}`);
        setPageLoading(false);
      }
    } catch (error) {
      console.log('[response error]: ', error);
    }
  };

  const handleConfirm = async () => {
    setLoading(true);
    setPwdVisible(false);
    handleApprove();
  };

  const handleApprove = async () => {
    try {
      console.log('amount', amount);
      const transactionHash = await sendApprove(
        auth?.to ?? '',
        spender,
        amount,
        decimals
      );
      // TODO: 通过调合约拿精度。
      // const usdc = '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174';
      // const result = await sendApprove(auth?.to ?? '', spender, amount, 6);
      console.log('resultHush', transactionHash);
      if (transactionHash) {
        // 授权成功
        Toast.show({
          icon: 'success',
          content: 'Authorization successful!',
        });
        eventBus.emit('eth_sendTransaction_approve', transactionHash);
      } else {
        eventBus.emit(
          'eth_sendTransaction_approve',
          {},
          'Authorization failed!'
        );
        // 授权失败
        Toast.show({
          icon: 'fail',
          content: 'Authorization failed!',
        });
      }
    } catch (error) {
      console.error(error);
      eventBus.emit(
        'eth_sendTransaction_approve',
        {},
        'Authorization failed!'
      );
      // 授权失败
      Toast.show({
        icon: 'fail',
        content: 'Authorization failed!',
      });
    } finally {
      setLoading(false);
    }
  };

  // UI展示数据（解析 Data 之后 - recoverSpenderAndAmount）
  const handleDataSource = () => {
    const data = JSON.parse(JSON.stringify(handleDetailData('auth')));
    data.forEach((itm) => {
      switch (itm.name) {
        case 'Authorized Contract': // 红包提供 to
          itm.info = auth?.to ?? '';
          break;
        case 'Approved Spender': // data 解析
          itm.info = spender ?? '';
          break;
        case 'Approved Token': // 接口拿
          itm.info = token ?? '';
          break;
        case 'Approved Amount': // data 解析
          itm.info = amount ?? 0;
          break;
        default:
          break;
      }
    });
    return data;
  };

  useEffect(() => {
    handleDataSource();
  }, [auth, decimals, token, amount]);

  return (
    <div>
      <Authorization
        detail={handleDataSource()}
        type="auth"
        onConfirm={() => {
          setPwdVisible(true);
        }}
        updateData={(val) => {
          setAmount(val);
        }}
        loading={loading}
        pageLoading={pageLoading}
      ></Authorization>
      <PasswordPicker
        visible={pwdVisible}
        onConfirm={handleConfirm}
        onClose={() => setPwdVisible(false)}
        needEmailCode
      />
    </div>
  );
};
