import { BWIndexedDBService } from '../bwIndexedDBService';
import { BWWalletService } from '../bwWalletService';
import { Storage } from '../storage';

interface MPCWalletsState {
  mpcWallets: Record<string, string>;
}
export const BWSecureStore = new Storage<MPCWalletsState>(
  {
    mpcWallets: {},
  },
  'BWSecureStorage'
);
class BWSecureStorage {
  public static async write(key: string, value: string) {
    const mpcWallets = await BWSecureStorage.getAllMPCWallets();
    mpcWallets[key] = value;
    await BWIndexedDBService.instance.write('mpcWallets', mpcWallets);
    // BWSecureStore.setItem('mpcWallets', mpcWallets);
  }
  public static async read(key: string) {
    const mpcWallets = await BWSecureStorage.getAllMPCWallets();
    const value = mpcWallets[key];
    return value;
  }
  public static async delete(key: string) {
    const mpcWallets = await BWSecureStorage.getAllMPCWallets();
    delete mpcWallets[key];
    // BWSecureStore.setItem('mpcWallets', mpcWallets);
    await BWIndexedDBService.instance.write('mpcWallets', mpcWallets);
  }
  public static async deleteAll() {
    // BWSecureStore.setItem('mpcWallets', {});
    await BWIndexedDBService.instance.write('mpcWallets', {});
  }
  public static async getAllMPCWallets(): Promise<Record<string, string>> {
    // const storedMPCWallets = BWSecureStore.getItem('mpcWallets');
    const storedMPCWallets = ((await BWIndexedDBService.instance.read(
      'mpcWallets'
    )) ?? {}) as Record<string, string>;
    const { uid } = BWWalletService.instance.getUserInfo();
    const currentWallets: Record<string, string> = {};
    Object.keys(storedMPCWallets).forEach((key) => {
      if (key.endsWith(uid)) {
        currentWallets[key] = storedMPCWallets[key];
      }
    });
    return currentWallets;
  }
}

export { BWSecureStorage };
