import { openDB, DBSchema, IDBPDatabase } from 'idb';

const DB_NAME = 'bitverse-h5-wallet';
const DB_VERSION = 1;

interface BWIndexedDBProps extends DBSchema {
  'key-maps': {
    key: string;
    value: any;
  };
}
class BWIndexedDBService {
  private static _instance: BWIndexedDBService;
  public db: IDBPDatabase<BWIndexedDBProps> | null = null;
  constructor() {
    this.init();
  }

  public static get instance(): BWIndexedDBService {
    if (!this._instance) {
      this._instance = new BWIndexedDBService();
    }
    return this._instance;
  }
  private async init() {
    if (!this.db) {
      this.db = await openDB<BWIndexedDBProps>(DB_NAME, DB_VERSION, {
        upgrade(db) {
          if (!db.objectStoreNames.contains('key-maps')) {
            db.createObjectStore('key-maps');
          }
        },
      });
    }
  }
  public async write(key: string, value: any) {
    if (!this.db) {
      await this.init();
    }
    await this.db?.put('key-maps', value, key);
  }
  public async read(key: string) {
    if (!this.db) {
      await this.init();
    }
    const value = await this.db?.get('key-maps', key);
    return value;
  }
  public async delete(key: string) {
    if (!this.db) {
      await this.init();
    }
    await this.db?.delete('key-maps', key);
  }
  public async deleteAll() {
    if (!this.db) {
      await this.init();
    }
    await this.db?.clear('key-maps');
  }
  public async getAllKeys(key: string) {
    if (!this.db) {
      await this.init();
    }
    const value = await this.db?.getAllKeys('key-maps', key);
    return value;
  }
}

export { BWIndexedDBService };
