import { Buffer } from 'buffer';
import { ethers, Contract } from 'ethers';
import { erc20_abi } from './abi';
export function buf2hex(buffer: Uint8Array) {
  return [...new Uint8Array(buffer)]
    .map((x) => x.toString(16).padStart(2, '0'))
    .join('');
}

export function dec2buf(decNumber: number) {
  return hex2buf(decNumber.toString(16));
}

export function hex2buf(hexString: string) {
  if (hexString.startsWith('0x')) {
    hexString = hexString.substr(2);
  }
  if (hexString.length % 2 !== 0) {
    hexString = '0' + hexString;
  }
  const numBytes = hexString.length / 2;
  const uint8Array = new Uint8Array(numBytes);
  for (let i = 0; i < numBytes; i++) {
    uint8Array[i] = parseInt(hexString.substr(i * 2, 2), 16);
  }

  return uint8Array;
}

export function stringToArray(bufferString: string) {
  const uint8Array = new TextEncoder().encode(bufferString);
  return uint8Array;
}
export function arrayToString(bufferValue: Uint8Array) {
  return new TextDecoder('utf-8').decode(bufferValue);
}

export function paddingLeft(data: string) {
  const data2 = data.padStart(64, '0');
  const bb = Buffer.from(data2, 'hex');
  return bb;
}

export const transferMethodId = '0xa9059cbb';
export const approveMethodId = '0x095ea7b3';

// 判断是否是合约交易
export const isTransfer = (txData: string) =>
  txData.substring(0, 10) === transferMethodId;

// 判断是否是授权交易

export const isApprove = (txData: string) => {
  if (!txData) {
    return false;
  } else {
    return txData.substring(0, 10) === approveMethodId;
  }
};

export const strip0x = function (txData: string) {
  return txData.slice(0, 2) === '0x' ? txData.slice(2) : txData;
};

export function hexToBytes(hex: string) {
  const bytes = [];
  for (let c = 0; c < hex.length; c += 2) {
    // @ts-ignore
    bytes.push(parseInt(hex.substr(c, 2), 16));
  }
  return bytes;
}

/**
 *
 * @param txData 交易数据
 * @param decimal ERC20 合约精度，比如你要给红包合约授权 USDT 合约，那么这里就是 USDT 合约的精度
 * @returns 解析出交易数据中的 spender 和 amount
 */
export function recoverSpenderAndAmount(txData: string, decimal: number) {
  if (txData.length < 32) {
    throw new Error(
      'txData can not  recover spender and amount, you need try catch it by yourself'
    );
  }
  const spender = '0x' + strip0x(txData).slice(8 + 24, 8 + 24 + 40);
  const _amountHex = strip0x(txData).slice(72, 72 + 64);
  const _amount = ethers.BigNumber.from('0x' + _amountHex);
  const amount = ethers.utils.formatUnits(_amount, decimal);
  return {
    spender, // DApp 合约地址，比如红包合约地址，也可能是你个人钱包地址
    amount, // 如果精度不一致，这里解析的结果就不一致，所以必须传入和 DApp 开发者一模一样的精度。比如你授权 100 USDT，但是你的 USDT 精度是 6，那么这里解析出来的就是 0.0001 USDT
  };
}

/**
 *
 * @param data 交易数据
 * @returns erc20 Transfer解析出交易数据中的 recipent 和 amount,测试数据:0xa9059cbb000000000000000000000000c2132d05d31c914a87c6611c10748aeb04b58e8f0000000000000000000000000000000000000000000000000000000000000001
 */
export function decodeErc20TransferRecipentAndAmount(
  data: string,
  decimal: number
) {
  const contract = new Contract(
    '0xc2132d05d31c914a87c6611c10748aeb04b58e8f', //usdt可以写死
    erc20_abi
  );
  const item = contract.interface.decodeFunctionData('transfer', data);
  if (item.length >= 2) {
    return {
      recipent: item[0],
      amount: ethers.utils.formatUnits(item[1], decimal),
    };
  }
}

/**
 *
 * @param data 交易数据
 * @returns erc20 transferFrom解析出交易数据中的 recipent 和 amount,测试数据:0x23b872dd0000000000000000000000005f31f365fcab711d6448b35b242d12f5923917a5000000000000000000000000a7309b3db8959f30bcbb10979c22a3179d8069bf0000000000000000000000000000000000000000000000000000000000000001
 */
export function decodeErc20TransferFromRecipentAndAmount(
  data: string,
  decimal: number
) {
  const contract = new Contract(
    '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
    erc20_abi
  );
  let rtn: any = null;
  const item = contract.interface.decodeFunctionData('transferFrom', data);
  if (item.length >= 3) {
    rtn = {
      recipent: item[1],
      amount: ethers.utils.formatUnits(item[2], decimal),
    };
  }
  return rtn;
}
