import { Button } from 'antd-mobile';
import './index.less';
import { useContext, useEffect, useState } from 'react';
import { BitverseEnum } from '@bitverse-h5-wallet/bitverse-dapp-sdk';
import { eventBus } from '@bitverse-h5-wallet/bitverse-message-sdk';
import {
  switchNetwork,
  useCurrentNetwork,
  useNetworks,
} from '../../components/networks/useNetworks';
import arrow from '../../assets/images/arrow.png';
import { ProviderContext } from '../../context';
import { Networks } from '@bitverse-h5-wallet/bitverse-core';

const SwitchChain = () => {
  const networkInfo = useCurrentNetwork();
  const allNetworks = useNetworks();
  const [targetChain, setTargetChain] = useState<any>({});
  const { globalState } = useContext(ProviderContext);

  const switchFun = (type) => {
    if (type === 'Confirm') {
      switchNetwork(targetChain?.chainId);

      eventBus.emit(BitverseEnum.chainGet, Networks.instance.getCurrentNetwork());
      eventBus.emit(BitverseEnum.walletSwitchEthereumChain, [
        targetChain?.chainId,
      ]);
    }
    if (type === 'Cancel') {
      eventBus.emit(BitverseEnum.walletSwitchEthereumChain, {}, 'User Cancel');
    }
  };

  useEffect(() => {
    const paramChainId = (globalState?.navigateParams?.params || [])[0]
      ?.chainId;
    console.log(globalState.navigateParams, '====switchchain');
    setTargetChain(
      allNetworks.filter(
        (item) => Number(item.chainId) === Number(paramChainId)
      )[0] || []
    );
  }, [globalState?.navigateParams]);

  return (
    <div className="switch_chain_wrap pd16 df">
      <div className="f1 df aic jcc fdc">
        <div>Allow this site to switch networks?</div>
        <div className="df aic switch-content">
          <div className="tac chain-item">
            <img src={networkInfo?.iconUrl} />
            <p className="mt8">{networkInfo?.name}</p>
          </div>
          <div className="df aic switch-icon">
            <i></i>
            <i></i>
            <img src={arrow} />
            <i></i>
            <i></i>
          </div>
          <div className="tac chain-item">
            <img src={targetChain?.iconUrl} />
            <p className="mt8">{targetChain?.name}</p>
          </div>
        </div>
      </div>
      <div className="df aic">
        <Button
          onClick={() => switchFun('Cancel')}
          color="primary"
          className="w100 mr10"
          fill="outline"
          size="large"
        >
          Cancel
        </Button>
        <Button
          onClick={() => switchFun('Confirm')}
          color="primary"
          className="w100"
          size="large"
        >
          Confirm
        </Button>
      </div>
    </div>
  );
};

export default SwitchChain;
