import {
  IEthereumProvider,
  ProviderAccounts,
  RequestArguments,
} from 'eip1193-provider';
import { EventEmitter } from 'events';
import { JsonRpcProvider } from './json-rpc-provider';
import { BitverseProviderOptions } from './type';
import { log } from './utils';
import { BitverseEnum, BitverseEnumLocal } from '@bitverse-h5-wallet/bitverse-dapp-sdk';
import localStorageManager from './localStorageManager';
export class BitverseProvider implements IEthereumProvider {
  private account?: Array<string> | undefined = undefined;
  static instance: BitverseProvider | undefined = undefined;

  public events: EventEmitter = new EventEmitter();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  public chainId: number | null;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  public readonly signer: JsonRpcProvider ;

  constructor(options?: BitverseProviderOptions) {
    const ENV: any = {
      'local': 'http://localhost:4200/',
      'dev': 'https://wallet.bitverse-dev.bitverse.zone/',
      'prod': 'https://wallet.bitverse.zone/',
    }
    if(!BitverseProvider.instance){
      const config: BitverseProviderOptions = Object.assign(options || {}, {
        chainId: null,
        returnEmail: false,
        appSetting: {
          appName: '',
          appIcon: '',
        },
        rpcUrls: {},
        url:ENV[localStorageManager.getItem(BitverseEnumLocal.ENV) as string] ||  ENV.prod
        // url: ENV.dev
      });
      log('config', config)
      this.chainId = Number(config.chainId);
      const _chainId = localStorageManager.getItem(BitverseEnumLocal.dappChainId)
      const _rpcUrl = localStorageManager.getItem<string>(BitverseEnumLocal.dappRpcUrl)
      const _account = localStorageManager.getItem<string>(
        BitverseEnumLocal.dappSdkAccount
      ) || ''

      this.signer = new JsonRpcProvider(
        config.configurations,
        config.appSettings,
        config.url
      );

      if(_chainId && _rpcUrl){
        this.chainId = Number(_chainId);
        this.signer.updatebitverseWalletConfig(
          this.chainId,
          _rpcUrl
        );

      }

      // 如果账号非法、没有rpc url，就删除
      if(!String(_chainId) || !_rpcUrl || !_account || !_account.includes('0x')) {
        localStorageManager.removeItem(BitverseEnumLocal.dappSdkAccount);
      }


      this.signer.changeMessage = (type: string, params: any) => {
        log('changeMessage', type, params);
        switch (type) {
          case BitverseEnum.chainChanged: {
            const chainId = Number(params.chainId);
            this.signer.updatebitverseWalletConfig(
              chainId,
              params.info.rpc
            );
            if (this.chainId !== chainId) {
              this.chainId = chainId;
              this.events.emit(BitverseEnum.chainChanged, chainId);
            }
            break;
          }
          case BitverseEnum.chainGet:{
            const chainId = Number(params.chainId);

            if (this.chainId !== chainId) {
              this.chainId = chainId;
              this.events.emit(BitverseEnum.chainChanged, chainId);
            }
            this.signer.updatebitverseWalletConfig(
              this.chainId,
              params.info.rpc
            );
            break;
          }
          case BitverseEnum.accountsChanged: {
            if (this.account && this.account?.length > 0) {
              this.events.emit(BitverseEnum.accountsChanged, params);
            }

            break;
          }
        }
      };
      BitverseProvider.instance = this;
    } else {
      return BitverseProvider.instance;
    }

  }


  public request = async (args: RequestArguments) => {
    log('request', args, this.signer, this.signer.rpcUrls);
    switch (args.method) {
      case BitverseEnum.ethRequestAccounts: {
        const account = await this.connect({
          url: window.location.origin,
          icon: this.getFaviconIco(),
        });
        log(`request-${args?.method || ''}-Res`, account);
        return account;
      }
      case BitverseEnum.ethAccounts: {
        const account = await this.connect({
          url: window.location.origin,
          icon: this.getFaviconIco(),
        });
        log(`request-${args?.method || ''}-Res`, account);
        return account;
      }
      case BitverseEnum.ethChainId:
        log(`request-${args?.method || ''}-Res`, this.chainId);
        return Number(this.chainId);
      case BitverseEnum.walletSwitchEthereumChain: {
        const _params =
          args?.params && Array.isArray(args?.params) && args?.params[0]
            ? args?.params[0]
            : undefined;
        const chainId =
          typeof _params?.chainId === 'string' &&
          _params?.chainId?.startsWith('0x')
            ? parseInt(_params?.chainId, 16)
            : _params?.chainId;
        await this.signer.walletSwitchEthereumChain(args?.params);
        // this.signer.updatebitverseWalletConfig(chainId);
        this.chainId = Number(chainId);
        this.events.emit('chainChanged', this.chainId);
        log(`request-${args?.method || ''}-Res`, this.chainId);
        return this.chainId
      }
      default:
        break;
    }
    const res =  await this.signer.request(args);
    log(`request-${args?.method || ''}-Res`, res);
    return res
  }
  public getFaviconIco() {
    const hrefArr = document.getElementsByTagName('link');
    let url = '';
    for (let i = 0; i < hrefArr.length; i++) {
      if (hrefArr[i].rel && hrefArr[i].rel == 'icon') {
        url = hrefArr[i].href;
        break;
      }
    }
    return url;
  }

  public async connect(params: any) {
    let _account: any = localStorageManager.getItem(
      BitverseEnumLocal.dappSdkAccount
    );
    log('local-account', _account);
    try {
      _account = JSON.parse(_account || '') || this.account;
    } catch (error) {
      _account = [];
    }
    this.account = _account;

    if (this.account && this.account.length > 0) {
      return this.account;
    }
    const account = await this.signer.connect(params);
    if (account && account.length > 0) {
      localStorageManager.setItem(
        BitverseEnumLocal.dappSdkAccount,
        JSON.stringify(account),
        60 *24
      );
      this.account = account;
      this.events.emit('connect', account);
      return account;
    } else {
      return [];
    }
  }

  public async disconnect(): Promise<void> {
    await this.signer.disconnect();
    this.events.emit('disconnect');
    localStorageManager.removeItem(BitverseEnumLocal.dappSdkAccount);
    this.account = undefined;
  }

  public sendAsync(
    args: RequestArguments,
    callback: (error: Error | null, response: any) => void
  ): void {
    this.request(args)
      .then((response) => callback(null, response))
      .catch((error) => callback(error, undefined));
  }

  public async enable(): Promise<ProviderAccounts> {
    const account: any = await this.request({
      method: BitverseEnum.ethRequestAccounts,
    });
    return [account?.address || ''];
  }

  public isBitverseProvider(): boolean {
    return true;
  }

  public isConnected(): boolean {
    return !!this.account;
  }

  public getChainId(): number | null {
    return this.chainId;
  }

  public getSigner() {
    return this.signer;
  }

  public get networkVersion() {
    return this.chainId;
  }

  on(event: string, listener: any): void {
    this.events.on(event, listener);
  }

  once(event: string, listener: any): void {
    this.events.once(event, listener);
  }

  removeListener(event: string, listener: any): void {
    this.events.removeListener(event, listener);
  }

  off(event: string, listener: any): void {
    this.events.off(event, listener);
  }
}
