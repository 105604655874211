import { COIN_CONFIG } from './config';
import { Image } from 'antd-mobile';
import './index.less';
interface CoinItemProps {
  symbol: string
  name?: string;
  balance: string;
  price: string;
  icon?: string;
  onClick?: (props: unknown) => void;
}

const emptyTxt = '0.0000'
const CoinItem = ({ symbol, name, balance, price, onClick, icon }: CoinItemProps) => {
  return (
    <div className="df aic token-list" onClick={onClick}>
      <Image
        className="mr10"
        src={
          icon ||
          COIN_CONFIG[symbol]?.img ||
          'https://pic.bitverse.zone/token_images/137/matic.png'
        }
        width={32}
        height={32}
        style={{ borderRadius: 32 }}
        fit="cover"
      />
      <div className="f1">
        <span className="fs16 fw600">{symbol}</span> &nbsp;
        <span className="t2">{name || COIN_CONFIG[symbol]?.fullName}</span>
      </div>
      <div className="tar">
        <p className="fs16 fw600">{balance || emptyTxt}</p>
        <p className="fs12 t2">{price || emptyTxt} USD</p>
      </div>
    </div>
  );
};

export default CoinItem;
