import { FC, useContext, useEffect, useState } from 'react';
import { Card } from '../../components/Card';
import { Button, Checkbox, Input, Space, Tag } from 'antd-mobile';
import {
  sendApprove,
  sendTransactionFromBitverse,
  sendErc20Transfer,
  recoverSpenderAndAmount,
} from '@bitverse-h5-wallet/bitverse-core';
import { utils } from 'ethers';
import { SmileFill, SmileOutline } from 'antd-mobile-icons';
// import { ProviderContext } from '../../context';
// import { abi } from '@openzeppelin/contracts/build/contracts/ERC20.json';

// mumbai Dummy ERC20 (DERC20) 授权的合约地址
const dummyAddress = '0xfe4F5145f6e09952a5ba9e956ED0C25e3Fa4c7F1';
const btAddress = '0x2ad86bb946ceca61eb945ca0eb908ae9502931c7';

// mumbai USDT 合约
const usdtAddress = '0x6566b015A646Ef7DC7FB3EA8Ac76C8643A226B23';

// 个人钱包地址
const frankWalletAddress = '0xa9Aa4613FAdA2287935CE5d6D375c28d248b5b50';
const ufoWalletAddress = '0xd332DCa2B5681Cc5e7E69C44B00182EbA2A6dcF5';

const BTTokenPolygon = '0x2aD86bb946CEcA61Eb945cA0eB908AE9502931c7';
const usdc = '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174';
const steTokenPolygon = '0x4c25b94100724c485A843ab055aC1e98DF5e9F8E';

// dapp contract address
const classicalAddress = '0x759100Bf7AF047faC50e0696ea1Ca563f44DacC1';
const hongbaoAddress = '0xE6325DCf470A46389F7e8555332eB91281bD544e';

export const SignTxDemo: FC = () => {
  const [loadingTx, setLoadingTx] = useState(false);
  const [loadingErc20, setLoadingErc20] = useState(false);
  const [loadingErc20Approve, setLoadingErc20Approve] = useState(false);
  // const [loadingTx, setLoadingTx] = useState(false);
  const [valueErc20, setValueErc20] = useState('');
  const [decimalErc20, setDecimalErc20] = useState('');

  // const { jsonRpcProvider } = useContext(ProviderContext);

  // const [workerReady, setWorkerReady] = useState(false);

  // console.log('11111', mpcWorker);

  // useEffect(() => {
  //   const onWorkerReady = (msg: WorkerMessage) => {
  //     console.log('onWorkerReady');

  //     if (msg.data.ready) {
  //       setWorkerReady(true);
  //       mpcWorker.removeEventListener('message', onWorkerReady);
  //     }
  //   };

  //   mpcWorker.addEventListener('message', onWorkerReady);
  // }, []);

  // useEffect(() => {
  //   getCurrentAccount().then((address) => setWalletAddress(address));
  // }, []);

  /**
   * 执行主链币转账
   */
  async function testNativeTokenTransfer() {
    setLoadingTx(true);
    console.log('[开始执行 交易]');

    const txObj = {
      to: frankWalletAddress,
      value: utils.hexlify(utils.parseEther('0.001')),
    };

    const transactionRaw = {
      ...txObj,
      gasLimit: utils.hexlify(21000), // 0x5208
    };
    await sendTransactionFromBitverse(transactionRaw);

    setLoadingTx(false);
  }

  /**
   * ERC20 合约转账
   * 自动预估gas费
   * mumbai 网络：必须传 from 参数, 否则会报错: zero address
   * 成功案例: https://mumbai.polygonscan.com/address/0xd224030c26bccab04710cca2994c0498b996e03c#tokentxns
   */
  const testErc20Transfer = async () => {
    setLoadingErc20(true);
    console.log('[开始执行 合约转账 testErc20Transfer]');

    const btTokenDecimal = decimalErc20 || 8;
    const amount = utils.parseUnits(valueErc20 || '1.2', btTokenDecimal);
    console.log('amount ', amount);
    const rtn = await sendErc20Transfer(
      BTTokenPolygon,
      frankWalletAddress,
      amount.toString()
    );
    console.log('rtn', rtn);
    setLoadingErc20(false);
  };

  /**
   * ERC20 合约授权
   * 自动预估gas费
   * https://mumbai.polygonscan.com/tx/0x578c04e80871e4286a1a366cd6b66a06b76253ce9c73e708d706014d4695d2ff
   * 错误的cae
   */
  const testErc20Approve = async () => {
    // const { amount, spender } = recoverSpenderAndAmount(
    //   '0x095ea7b3000000000000000000000000eebbc58129355c4464cbea5f6507e883321b92a9ffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
    //   6
    // );
    try {
      setLoadingErc20Approve(true);
      console.log('[开始执行 合约转账 testErc20Transfer]');
      // const erc20Decimal = 18;
      const btTokenDecimal = 8;
      const result = await sendApprove(
        usdc,
        hongbaoAddress,
        '0.001',
        btTokenDecimal
      );
      console.log('[testErc20Approve] result =', result);

      setLoadingErc20Approve(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="sign-tx">
      <Card title="测试原生币 (交易签名 & 交易广播) ✅">
        <div>
          <Button
            color="success"
            onClick={testNativeTokenTransfer}
            loading={loadingTx}
          >
            2.1 测试交易(原生代币转账)
          </Button>
        </div>
      </Card>

      <Card title="测试合约 ✅">
        <Space direction="vertical">
          <Tag color="#87d068" fill="outline">
            BT: {BTTokenPolygon}
          </Tag>
          <Input
            placeholder="请输入转账金额"
            value={valueErc20}
            onChange={(val) => setValueErc20(val)}
          />
          <Input
            placeholder="请输入转账金额精度"
            value={decimalErc20}
            onChange={(val) => setDecimalErc20(val)}
          />
          <Button
            color="success"
            onClick={testErc20Transfer}
            loading={loadingErc20}
          >
            3.1 测试合约 transfer(ERC20代币转账)
          </Button>
        </Space>
        <div>
          <Button
            color="success"
            onClick={testErc20Approve}
            loading={loadingErc20Approve}
          >
            3.2 测试合约 approve
          </Button>
        </div>
      </Card>
    </div>
  );
};
