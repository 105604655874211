import axios from 'axios';

const userToken =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJFUzI1NiJ9.eyJhcHBJZCI6ImJpdHZlcnNlX2FwcCIsInVzZXJJZCI6MjA4ODk5MDAwMDAxMDE0MiwicGxhdGZvcm0iOjEsImlzc3VlZF9hdCI6MTY4NzUzMDkzNTc0MSwiZ2VuX3RzIjoxNjg3NjE3MzM1NzQxLCJleHBpcmVzX2F0IjoxNjkwMTIyOTM1NzQxLCJpYXQiOjE2ODc1MzA5MzUsImV4cCI6MTY5MDEyMjkzNX0.MEYCIQD_LA_2Bm0JDd1cezMeg6v-3DAAaN9lbUI6YecMLGmMwgIhAL64kOSJOHfe9ADoYVwCQ_A53N148aY4xZq9o5vEIveV';

export const instance = axios.create({
  baseURL: 'http://api2.bitverse-dev-1.bitverse.zone',
  timeout: 1000 * 30,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'device-id': '634d410360b2b599152e1125',
    // 'app-version': '2.0.6',
    platform: 'android',
    userToken,
  },
});
