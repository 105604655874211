import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { DotLoading, Mask, SearchBar } from 'antd-mobile';
import Decimal from 'decimal.js';

import CoinItem from '../../components/CoinItem';
import { STAGE } from '../../hooks/use-navigate-page';
import { Header } from '../../layouts/Header';
import http from '@bitverse-h5-wallet/bitverse-http-client';

import './index.less';

import { getCurrentAccount } from '@bitverse-h5-wallet/bitverse-core';
import { useNetwork } from '../../components/networks/useNetworks';
import { getSelectedTokenFromLocalStorage } from '../../utils/storage';
import { NX_API_Base_URL } from '../../utils';

interface IListProps {
  symbol: string;
  name: string;
  coinId: number;
  iconUrl: string;
  decimals: number;
  contract: string;
  type: string;
  chainId: string;
}

const SelectCoin = () => {
  const navigator = useNavigate();
  const [searchParams] = useSearchParams();
  const type = searchParams.get('type');
  const location = useLocation();
  const { chainId } = location.state || {};

  const network = useNetwork(chainId)?.network;
  const [coinList, setCoinList] = useState<Array<IListProps>>([]);
  const [balance, setBalance] = useState<Map<string, any>>(new Map());
  const [loading, setLoading] = useState<boolean>(false);

  const handleSearch = async (searchStr?: string) => {
    setLoading(true);
    if (searchStr) {
      const { result } = await http.post(
        `${NX_API_Base_URL}/bitverse/wallet/v1/public/token/search`,
        {
          coinIds: [network.coinId],
          keyword: searchStr,
        }
      );
      setCoinList(result.list);
    } else {
      const localToken = getSelectedTokenFromLocalStorage('');
      let dataSource: any[] = [];
      // 本地有token
      if (localToken && JSON.parse(localToken).length > 0) {
        dataSource = JSON.parse(localToken).filter(
          (item: any) => network.coinId === item.coinId
        );
        if (dataSource.length === 0) {
          dataSource = [network];
        }
      } else {
        dataSource = [network];
      }
      setCoinList(dataSource);
    }
    setLoading(false);
  };

  const getBalance = async () => {
    const account = await getCurrentAccount();

    const { result } = await http.post(
      `${NX_API_Base_URL}/bitverse/wallet/v1/public/asset/token/batchQuery`,
      {
        includeBalance: true,
        list: coinList.map((item) => ({
          coinId: item.coinId,
          chainId: item.chainId,
          address: account,
          contract: item.contract,
        })),
      }
    );

    const balanceMap = new Map();
    result?.result?.forEach((item: any) => {
      balanceMap.set(`${item.chainId || ''}_${item.contract || ''}`, {
        balance: new Decimal(item.balance).toFixed(4),
        price: new Decimal(item.balance).mul(item.price || 0).toFixed(4),
      });
    });
    setBalance(balanceMap);
  };

  useEffect(() => {
    handleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    coinList.length && getBalance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coinList]);

  const changeSearchStr = (val: string) => {
    handleSearch(val);
  };

  return (
    <div>
      <Header
        pageName={`Select ${
          type === STAGE.SEND ? 'Transfer' : 'Receive'
        } Currency`}
      />
      <SearchBar
        placeholder="Search currency name or enter contract address"
        className="pd16"
        onChange={changeSearchStr}
        clearable
      />
      <Mask visible={loading}>
        <div className="select-coin-list-loading">
          <DotLoading color="primary" />
        </div>
      </Mask>
      <div className="select-coin-list-container">
        {coinList.map((coin) => (
          <CoinItem
            key={`${coin.chainId}_${coin.contract}`}
            symbol={coin.symbol}
            icon={coin.iconUrl}
            name={coin.name}
            balance={
              balance.get(`${coin.chainId || ''}_${coin.contract || ''}`)
                ?.balance
            }
            price={
              balance.get(`${coin.chainId || ''}_${coin.contract || ''}`)?.price
            }
            onClick={() => {
              navigator(
                `${type === STAGE.SEND ? '/send' : '/receive'}?coinId=${
                  coin.coinId || ''
                }&contract=${coin.contract || ''}&chainId=${coin.chainId}`
              );
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default SelectCoin;
