import { useEffect, useState, useContext } from 'react';
import { eventBus } from '@bitverse-h5-wallet/bitverse-message-sdk';
import Authorization from '../Authorization';
import { handleDetailData } from '../Authorization/configuration';
import { ProviderContext } from '../../context';
import './index.less';
import {
  PasswordService,
  getProvider,
  getTypedDataPayload,
  signTypedDataV4,
} from '@bitverse-h5-wallet/bitverse-core';
import { Toast } from 'antd-mobile';
import { PasswordPicker } from '../Password';

interface SignTS {
  option: string;
  type: string;
}

export const SignTypedData = () => {
  const [loading, setLoading] = useState(false);
  const [sign, setSign] = useState<SignTS>();
  const [needPwd, setNeedPwd] = useState<boolean>(false);
  const { globalState } = useContext(ProviderContext);

  useEffect(() => {
    console.log('globalState.navigateParams', globalState.navigateParams);
    setSign(globalState.navigateParams);
  }, [globalState.navigateParams]);

  const handleDataSource = () => {
    const data = JSON.parse(JSON.stringify(handleDetailData('sign')));
    data.forEach((itm) => {
      if (itm.name === 'Signature Content') {
        itm.info = sign?.option ?? '';
      } else {
        itm.info = sign?.type ?? '';
      }
    });
    return data;
  };

  const handleConfirm = async () => {
    if (!(await PasswordService.instance.verify())) {
      setNeedPwd(true);
    } else {
      signTypedData();
    }
  };

  const signTypedData = async () => {
    setLoading(true);
    const signatureHex = await signTypedDataV4(sign?.option ?? '');
    setLoading(false);
    console.log('sigResult', signatureHex);
    if (signatureHex && JSON.stringify(signatureHex) !== '{}') {
      Toast.show({
        icon: 'success',
        content: 'Sign successful!',
      });
      eventBus.emit('eth_signTypedData_v4', signatureHex);
    } else {
      eventBus.emit('eth_signTypedData_v4', {}, 'Sign failed!');
      Toast.show({
        icon: 'fail',
        content: 'Sign failed!',
      });
    }
  };

  return (
    <div>
      <Authorization
        detail={handleDataSource()}
        type="sign"
        onConfirm={handleConfirm}
        loading={loading}
      ></Authorization>
      <PasswordPicker
        visible={needPwd}
        onClose={function (): void {
          setNeedPwd(false);
        }}
        onConfirm={function (password: string): void {
          setNeedPwd(false);
          signTypedData()
        }}
      />
    </div>
  );
};
