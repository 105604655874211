import { CloudType, WalletBackUpInfo, WalletKey, cloudBackupDir } from './type';
import NodeRSA from 'node-rsa';
import { Buffer } from 'buffer';
import httpClient from '@bitverse-h5-wallet/bitverse-http-client';
import { ScryptGenerator } from '../scryptGenerator';
import { BWSecureStorage } from '../bwSecureStorage';
import { walletAddressFromMk } from '../utils/wallet-utils';
import { BWWalletService } from '../bwWalletService';
import { AccountType } from '../types/types';
import { getBaseDomain } from '../utils/utils';

const bitverseServerbaseURL = getBaseDomain();

const blobToString = (blob: Blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const text = reader.result;
      resolve(text);
    };
    reader.onerror = reject;
    reader.readAsText(blob);
  });
};
class RecoverService {
  public walletBackupList: WalletBackUpInfo[] = [];
  // private static instance: RecoverService;
  private static _instance: RecoverService;

  private googleAuthToken = '';

  public static get instance(): RecoverService {
    if (!this._instance) {
      this._instance = new RecoverService();
    }
    return this._instance;
  }
  public async getWalletRecoverList() {
    const resp = await httpClient.post(
      `${bitverseServerbaseURL}/bitverse/wallet/v1/private/kms/wallet/recovery`,
      {},
    );
    console.log('getWalletRecoverList', resp);
    if (resp?.retCode === 0) {
      return this.updateRecoverStatus(resp.result?.wallets);
    }
    return [];
  }
  private updateRecoverStatus(list: any[]) {
    return list.map((item) => {
      const walletMaps = BWWalletService.instance.getAllWallets();
      const recoverStatus =
        walletMaps.findIndex((wallet) => {
          return wallet.walletId === item.walletId;
        }) > -1;
      return { ...item, recoverStatus };
    });
  }
  private async downloadFile(cloudType: CloudType, filename: string) {
    let rsaPrivateKey = '';
    switch (cloudType) {
      case CloudType.googleDriver:
        rsaPrivateKey = await this._googleDownload(filename);
        break;

      default:
        break;
    }
    return rsaPrivateKey;
  }
  private async _googleDownload(filename: string) {
    try {
      const baseURL = 'https://www.googleapis.com/drive/v3';
      // 获取文件夹ID
      const directoryParams = new URLSearchParams({
        q: `name='${cloudBackupDir}' and mimeType='application/vnd.google-apps.folder'`,
      });
      const directoryResponse = await fetch(
        `${baseURL}/files?${directoryParams.toString()}`,
        {
          headers: {
            Authorization: `Bearer ${this.googleAuthToken}`,
          },
        }
      );
      const directoryData = await directoryResponse.json();

      if (directoryData.files.length === 0) {
        console.log('Directory not found');
        return '';
      }

      const directoryId = directoryData.files[0].id;

      // 获取文件ID
      const fileParams = new URLSearchParams({
        q: `name='${filename}' and '${directoryId}' in parents`,
      });
      const fileResponse = await fetch(
        `${baseURL}/files?${fileParams.toString()}`,
        {
          headers: {
            Authorization: `Bearer ${this.googleAuthToken}`,
          },
        }
      );
      const fileData = await fileResponse.json();

      if (fileData.files.length === 0) {
        console.log('File not found');
        return '';
      }

      const fileId = fileData.files[0].id;

      // 下载文件
      const downloadResponse = await fetch(
        `${baseURL}/files/${fileId}?alt=media`,
        {
          headers: {
            Authorization: `Bearer ${this.googleAuthToken}`,
          },
        }
      );
      const blob = await downloadResponse.blob();
      const text = (await blobToString(blob)) as string;
      return text;
    } catch (error) {
      console.error('文件下载失败！', error);
      return '';
    }
  }
  public async handleRecover({
    walletBackUp,
    password,
    cloudType,
    googleAuthToken,
  }: {
    walletBackUp: WalletBackUpInfo;
    password: string;
    cloudType: CloudType;
    googleAuthToken: string;
  }): Promise<void> {
    // 验证密码
    this.googleAuthToken = googleAuthToken;
    const walletId: string = walletBackUp.walletId;
    const rsaPrivateKeyPem = await this.downloadFile(
      cloudType,
      `${walletId}.x`
    );
    if (rsaPrivateKeyPem === '') {
      console.error(
        `The private key downloaded by the cloud disk is empty: walletId=${walletId} cloud=${cloudType}`
      );
      throw new Error('The private key downloaded by the cloud disk is empty');
    }

    await this.recoverWallet({
      walletBackUp,
      password,
      rsaPrivateKeyPem,
    });
  }
  private async recoverWallet({
    walletBackUp,
    password,
    rsaPrivateKeyPem,
  }: {
    walletBackUp: WalletBackUpInfo;
    password: string;
    rsaPrivateKeyPem: string;
  }) {
    try {
      const privateNodeRSA = new NodeRSA();

      privateNodeRSA.importKey(rsaPrivateKeyPem, 'pkcs8');
      const masterKeyBackUps = walletBackUp.mpcClientMasterKey;
      const walletKeys: WalletKey[] = [];
      const walletKeysStr: string[] = [];
      let id = '';

      masterKeyBackUps.forEach((masterKeyBackUp) => {
        const { cipherMasterKey } = masterKeyBackUp;
        const buffer = Buffer.from(cipherMasterKey, 'hex');
        const masterKeyStr = privateNodeRSA.decrypt(buffer, 'utf8');
        const masterKeyStrList = JSON.parse(masterKeyStr);
        masterKeyStrList.forEach((masterKeyStrItem: string) => {
          const masterKeyItem = JSON.parse(masterKeyStrItem) as WalletKey;
          if (masterKeyItem.curv == 'secp256k1') {
            id = masterKeyItem.keyinfo['id'];
          }
          walletKeys.push(masterKeyItem);
          walletKeysStr.push(masterKeyStrItem);
        });
      });
      if (id == '') {
        throw new Error('No cloud disk or service available');
      }

      const encrypted = await ScryptGenerator.encrypt(
        password,
        walletKeysStr,
        id
      );
      ///保存加密后的密文到keychain/keyStorage中
      const mapId = BWWalletService.instance.getWalletCombUid(id);
      await BWSecureStorage.write(mapId, encrypted);

      const mk = walletKeys[0].keyinfo.master_key;
      const walletAddress = await walletAddressFromMk(mk);
      console.log('walletAddress', walletAddress);
      const wallet: AccountType = {
        name: walletBackUp.walletName,
        walletId: id,
        address: walletAddress,
        backupStatus: {
          icloud: false,
          google: true,
          dropbox: false,
        },
      };
      BWWalletService.instance.addWalletMeta(wallet);
    } catch (error) {
      console.error('恢复钱包失败！', error);
      throw new Error('Wallet recovery failed!');
    }
  }
}

export { RecoverService };
