import { AutoCenter, Button, Image, SpinLoading } from 'antd-mobile';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './index.less';
import { TRANSACTION_STATUS } from './configuration/constant';
import noDataIcon from '../../assets/noData.svg';
import { Header } from '../../layouts/Header';
import useNavigatePage from '../../hooks/use-navigate-page';
import { useNavigate } from 'react-router-dom';
import { useNetwork } from '../../components/networks/useNetworks';
import httpClient from '@bitverse-h5-wallet/bitverse-http-client';
import { NX_API_Base_URL } from '../../utils';
import { toThousands } from '../../utils/number';
import dayjs from 'dayjs';
import { BWWalletService } from '@bitverse-h5-wallet/bitverse-core';
import { txListStorageKey } from '../../constants';

interface TokenTransactionItmTS {
  direction: string;
  time: string;
  value: string;
  status: string;
  hash: string;
}

interface PendingTransactionItmTS {
  symbol: string;
  contract: string;
  chainId: string;
  address: string;
  quantity: string;
  date: string;
  hash: string;
}

const TokenDetail: React.FC = () => {
  const navigate = useNavigate();
  const [detailList, setDetailList] = useState<TokenTransactionItmTS[]>([]);
  const [otherNetworkPendingList, setOtherNetworkPendingList] = useState<
    PendingTransactionItmTS[]
  >([]);
  const [loading, setLoading] = useState(false);
  const { navigateToPage } = useNavigatePage();
  const address = BWWalletService.instance.getCurrentWallet()?.address;

  const location = useLocation();
  const { symbol, balance, contract, iconUrl, chainId } = location.state;
  const network = useNetwork(chainId)?.network;

  useEffect(() => {
    // 获取当前钱包所有 Pending 交易数据
    const allPendingList = JSON.parse(
      localStorage.getItem(txListStorageKey) || '[]'
    );
    // 过滤出当前钱包&网络&币种&合约地址的
    let otherList = [] as PendingTransactionItmTS[];
    const newList = allPendingList.filter((itm: PendingTransactionItmTS) => {
      const {
        address: itmAddress,
        chainId,
        symbol: itmSymbol,
        contract: itmContract,
      } = itm;
      const isThis =
        itmAddress === address &&
        chainId === network.chainId &&
        itmSymbol === symbol &&
        itmContract === contract;
      if (!isThis) {
        otherList = [itm, ...otherList];
      }
      return isThis;
    });
    handleTokenList(newList, otherList);
  }, []);

  // 过滤数组
  function removeFromArray(arrA, arrB, otherList) {
    const valuesToRemove = arrB.map((item) => item.hash);
    //过滤出不包含在 valuesToRemove 中的元素
    const filteredArray = arrA.filter(
      (itemA) => !valuesToRemove.includes(itemA.hash)
    );
    //拼其他网络 Pending + 当前过滤后端相同数据 Pending
    const newStoreList = [...filteredArray, ...otherList];
    console.log('newStoreList', newStoreList);
    localStorage.setItem(txListStorageKey, JSON.stringify(newStoreList));
    return filteredArray;
  }
  // 获取当前 Token 交易列表
  const handleTokenList = async (
    localStorageList: PendingTransactionItmTS[],
    otherList: PendingTransactionItmTS[]
  ) => {
    setLoading(true);
    try {
      const rtn = await httpClient.post(
        `${NX_API_Base_URL}/bitverse/wallet/v1/public/trade/token/transaction/query`,
        {
          coinId: network.coinId,
          chainId: network.chainId,
          address,
          contract,
          size: 100, //默认100条
        }
      );
      const { result, retCode } = rtn;
      if (retCode === 0) {
        // 从后端接口查看是否有 hash 值一致的，删除 LocalStorage 该项 并从 Pending List 删除
        let pendingList = removeFromArray(
          localStorageList,
          result.list,
          otherList
        );
        console.log('pendingList', pendingList);
        console.log('result.list', result.list);
        // 如果有 pendingList 塞入
        pendingList = pendingList.map((itm) => {
          return {
            direction: 'PENDING',
            time: itm.date,
            value: itm.quantity,
            status: 'PENDING',
            hash: itm.hash,
          };
        });
        const newArr = [...pendingList, ...result.list];
        setDetailList(newArr);
        setLoading(false);
      }
    } catch (error) {
      console.log('[response error]: ', error);
    }
  };

  const handleBackToHome = () => {
    navigateToPage('home');
  };

  return (
    <div className="token-detail-container">
      <Header
        pageName="Token Detail"
        handleBackClick={() => handleBackToHome()}
      ></Header>
      <div className="page-content">
        <div className="token-info tac df fdc aic">
          <Image
            src={iconUrl ?? ''}
            width={32}
            height={32}
            style={{ borderRadius: 32 }}
            fit="cover"
          />
          <AutoCenter>
            <p className="mt12 mb6">{symbol ?? ''}</p>
            <p className="fs14 t2 mb6">{network.name}</p>
            <p className="fs32 fw600">
              {balance ? toThousands(balance, 6) : ''}
            </p>
          </AutoCenter>
        </div>
        <div className="content-detail">
          <AutoCenter>Records</AutoCenter>
          {loading ? (
            <AutoCenter className="loading mt16">
              <SpinLoading />
            </AutoCenter>
          ) : (
            <div className="detail-info tac mt16">
              {!detailList.length ? (
                <img className="no-data-icon" src={noDataIcon} alt="" />
              ) : (
                detailList.map((itm, idx) => {
                  return (
                    <div
                      key={idx}
                      className="history-itm df jcsb aic cp"
                      onClick={() => {
                        navigateToPage('status', {
                          state: {
                            hash: itm.hash,
                            contract,
                            iconUrl,
                            balance,
                            chainId,
                          },
                        });
                      }}
                    >
                      <div className="flex-col df fdc fw500 fs14">
                        <span className="df jcfs aic">
                          <img
                            src={
                              TRANSACTION_STATUS[
                                itm.status === 'PENDING'
                                  ? 'PENDING'
                                  : itm.direction
                              ].icon
                            }
                            alt=""
                          />
                          {`${
                            TRANSACTION_STATUS[
                              itm.status === 'PENDING'
                                ? 'PENDING'
                                : itm.direction
                            ].text
                          } ${symbol}`}
                        </span>
                        <span>
                          {itm.time
                            ? dayjs.unix(Number(itm.time)).format('YYYY-MM-DD')
                            : ''}
                        </span>
                      </div>
                      <div className="flex-col df fdc fw500 fs14 aife">
                        <span>{`${itm.value} ${symbol}`}</span>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          )}
        </div>
        <div className="page-footer df">
          <Button
            color="primary"
            className="w100 mt16 mr10"
            fill="outline"
            onClick={() => {
              navigate(`/receive?chainId=${chainId}`);
            }}
          >
            Deposit
          </Button>
          <Button
            color="primary"
            className="w100 mt16"
            fill="solid"
            onClick={() => {
              navigate(
                `/send?chainId=${chainId}&coinId=${network.coinId}&contract=${contract}`
              );
            }}
          >
            Send
          </Button>
        </div>
      </div>
    </div>
  );
};
export default TokenDetail;
