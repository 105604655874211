import { NavBar } from 'antd-mobile';
import { FC } from 'react';

import './index.less';
import { useNavigate } from 'react-router-dom';

interface HeaderProps {
  pageName?: string;
  left?: React.ReactNode;
  right?: React.ReactNode;
  back?: React.ReactNode;
  handleBackClick?: () => void;
}

export const Header: FC<HeaderProps> = ({
  pageName,
  left,
  right,
  back,
  handleBackClick,
}) => {
  const navigate = useNavigate();

  const onBack = () => {
    if (handleBackClick) {
      handleBackClick();
    } else {
      navigate(-1);
    }
  };

  return (
    <NavBar back={back} left={left} right={right} onBack={onBack}>
      {pageName ?? 'Wallet'}
    </NavBar>
  );
};
