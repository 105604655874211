import { Fragment, useContext, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import AppLayout from './layouts/AppLayout';
import DefaultLayout from './layouts/DefaultLayout';
import { Demo } from './pages/Demo/Demo';
import { Login } from './pages/Login/Login';
import { GoogleAuth } from './pages/GoogleAuth';
import { BackupRecovery } from './pages/BackupRecovery';
import { DApps } from './pages/DApps';
import { Set } from './pages/Set';
import { WalletBackUp } from './pages/WalletBackUp';
import { RecoveryWallet } from './pages/RecoveryWallet';
import { Sign } from './pages/Sign';
import MessageSDK from '@bitverse-h5-wallet/bitverse-message-sdk';
import Welcome from './pages/Welcome';
import EmailAddress from './pages/EmailAddress';
import Password from './pages/Password';
import TokenDetail from './pages/TokenDetail';
import DetailStatus from './pages/DetailStatus';
import { Auth } from './pages/Auth';
import { Wallet } from './pages/Wallet';
import { TokenManage } from './pages/TokenManage';
import SelectCoin from './pages/SelectCoin';
import Send from './pages/Send';
import Receive from './pages/Receive';
import SwitchChain from './pages/SwitchChain';

import useNavigatePage from './hooks/use-navigate-page';
import AuthFailed from './pages/AuthFailed';
import SendContract from './pages/SendContract';
import { Test } from './pages/Test';
import { AuthContext } from './contexts/AuthContext';
import httpClient from '@bitverse-h5-wallet/bitverse-http-client';
import { NX_API_Base_URL } from './utils';
import { Mask, Toast } from 'antd-mobile';
import { walletVerify } from './utils/wallet-exist';
import { SignTypedData } from './pages/SignTypedData';

export const App = () => {
  const { setIsLogin } = useContext(AuthContext);
  const { runRouterChange, navigateToPage } = useNavigatePage();
  const [userLoading, setUserLoading] = useState(false);

  const getUserInfo = async () => {
    setUserLoading(true);
    const rtn = await httpClient.post(
      `${NX_API_Base_URL}/bitverse/wallet/v1/private/user/info/get`
    );
    setUserLoading(false);
    if (rtn?.retCode === 10007 || !walletVerify()) {
      setIsLogin(false);
      navigateToPage('welcome');
    } else if (rtn?.retCode === 0) {
      setIsLogin(true);
    } else {
      Toast.show({
        icon: 'fail',
        content: rtn?.retMsg,
        position: 'top',
      });
      setIsLogin(false);
    }
  };

  useEffect(() => {
    const messageSDK = new MessageSDK();
    messageSDK.onRouterChange = (path: string, params: any) => {
      console.log('--------onRouterChange', path, params);
      runRouterChange(path, params);
    };

    messageSDK.init();
    getUserInfo();
  }, []);

  return (
    <Fragment>
      <Routes>
        <Route path="/app" element={<AppLayout />}>
          <Route index element={<Demo />} />
          <Route path="demo" element={<Demo />}></Route>
          <Route path="test" element={<Test />}></Route>
          <Route path="dapps" element={<DApps />}></Route>
          <Route path="set" element={<Set />}></Route>
          <Route path="google" element={<GoogleAuth />} />
          <Route path="backup" element={<BackupRecovery />} />
          <Route path="wallet" element={<Wallet />} />
        </Route>
        <Route path="/" element={<DefaultLayout />}>
          <Route index element={<Welcome />}></Route>
          <Route path="/welcome" element={<Welcome />}></Route>
          <Route path="/pwd" element={<Password />}></Route>
          <Route path="/token-detail" element={<TokenDetail />}></Route>
          <Route path="/detail-status" element={<DetailStatus />}></Route>
          <Route path="backup" element={<WalletBackUp />}></Route>
          <Route path="recovery" element={<RecoveryWallet />}></Route>
          <Route path="welcome" element={<Welcome />}></Route>
          <Route path="email-address" element={<EmailAddress />}></Route>
          <Route path="token-manage" element={<TokenManage />}></Route>
          <Route path="select-coin" element={<SelectCoin />} />
          <Route path="send" element={<Send />} />
          <Route path="send-contract" element={<SendContract />} />
          <Route path="receive" element={<Receive />} />
          <Route path="login" element={<Login />}></Route>
          <Route path="sign" element={<Sign />}></Route>
          <Route path="signTypedData" element={<SignTypedData />}></Route>
          <Route path="authorization" element={<Auth />}></Route>
          <Route path="auth-failed" element={<AuthFailed />}></Route>
          <Route path="/switch-chain" element={<SwitchChain />}></Route>
        </Route>
      </Routes>
      <Mask visible={userLoading}>
        <div className="loading-box">
          <div className="loading-content">
            <span className="loader"></span>
          </div>
        </div>
      </Mask>
    </Fragment>
  );
};
