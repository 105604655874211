import { useState, useEffect, useReducer, useContext } from 'react';
import httpClient from '@bitverse-h5-wallet/bitverse-http-client';
import { useCurrentNetwork } from '../components/networks/useNetworks';
import { getSelectedTokenFromLocalStorage } from '../utils/storage';
import { BWWalletService } from '@bitverse-h5-wallet/bitverse-core';
import { NX_API_Base_URL } from '../utils';
import { ProviderContext } from '../context';

export function useWallet(prop) {
  const { assetSwitch } = prop;
  const [tokenInfo, setTokenInfo] = useState({ totalAmount: '', result: [] });
  const initialNetwork = useCurrentNetwork();
  const initialAddress = BWWalletService.instance.getCurrentWallet()?.address;
  const { globalState } = useContext(ProviderContext);

  // 根据链查询链下的token
  const getTokenList = async (network: any, address: any) => {
    const list: any[] = [];
    const localToken = getSelectedTokenFromLocalStorage('');
    let dataSource: any[] = [];
    // 有本地有token && assetSwitch不为true，则按当前链过滤
    if (localToken && JSON.parse(localToken).length > 0) {
      dataSource = assetSwitch
        ? JSON.parse(localToken)
        : JSON.parse(localToken).filter(
            (item: any) => network.coinId === item.coinId
          );
      if (dataSource.length === 0) {
        dataSource = [network];
      }
    } else {
      dataSource = [network];
    }

    dataSource.map((item: any) => {
      list.push({
        coinId: item.coinId,
        chainId: item.chainId,
        address: address,
        contract: item.contract || '',
      });
    });

    try {
      const data = await httpClient.post(
        `${NX_API_Base_URL}/bitverse/wallet/v1/public/asset/token/batchQuery`,
        {
          includeBalance: true,
          list: list,
        }
      );
      setTokenInfo(data.result);
    } catch (error) {
      console.log('[response error]: ', error);
    }
  };

  useEffect(() => {
    getTokenList(initialNetwork, initialAddress);
  }, [globalState, assetSwitch]);

  return {
    tokenInfo,
  };
}
