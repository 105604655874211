export function bytesToHex(arrayBuffer: ArrayBuffer): string {
  const uint8Array = new Uint8Array(arrayBuffer);
  const hexChars: string[] = [];
  for (let i = 0; i < uint8Array.length; i++) {
    const hex = uint8Array[i].toString(16).padStart(2, '0');
    hexChars.push(hex);
  }
  return hexChars.join('');
}

// 十六进制字符串转换为 ArrayBuffer
export function hexToBytese(hex: string): ArrayBuffer {
  const hexWithoutSpaces = hex.replace(/\s/g, '');
  const byteLength = hexWithoutSpaces.length / 2;
  const arrayBuffer = new ArrayBuffer(byteLength);
  const uint8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteLength; i++) {
    const hexByte = hexWithoutSpaces.substr(i * 2, 2);
    const byte = parseInt(hexByte, 16);
    uint8Array[i] = byte;
  }
  return arrayBuffer;
}
