import eventBus, { type } from './event-bus';
import { log } from './utils';

export class MessageSDK {
  private source = '';
  // 生产一个空函数，如果没有传入onRouterChange，就不会报错
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public onRouterChange: any = () => {};
  constructor() {
    const params = new URLSearchParams(window.location.search);
    this.source = params.get('source') ||  sessionStorage.getItem('SOURCE') || '';
    sessionStorage.setItem('SOURCE', this.source);
  }

  /**
   * 初始化
   */
  init() {
    this.onMessage();
    this.sendMessage('MESSAGE_SDK_INIT');
    // 监听eventBus 事件，如果有事件触发，就发送消息给钱包
    eventBus.on(type, (e: any) => {
      this.sendMessage(e.type, e.params, e.error);
    });
  }

  /**
   * 发送消息给钱包
   * @param type 发送到钱包的消息类型
   * @param params 发送到钱包的参数
   */
  public async sendMessage(type: string, params: any = {}, error: any = null) {
    log('发送消息给SDK-sendMessage', type, params, error)
    window.opener?.postMessage(
      {
        type: 'MESSAGE_SDK_MESSAGE',
        data: {
          type,
          source: this.source || sessionStorage.getItem('SOURCE'),
          params,
          error,
        },
      },
      '*'
    );
  }

  /**
   * 监听message, 只监听来自于钱包的message
   */
  public async onMessage() {
    window.addEventListener('message', (e) => {
      if (e.data.type === 'ETHEREUM_PROVIDER_SDK_MESSAGE') {
        log('收到SDK消息-onMessage', e.data.data)
        switch (e.data.data.type) {
          case null:
            break;
          default:
            // 目前无脑的发消息给钱包，如果有特殊需求，可以在这里处理
            this.onRouterChange(e.data.data.type, e.data.data.params);
        }
      }
    });
  }
}
