import { v4 as uuidv4 } from 'uuid';
import { mpcWorker } from '../worker/web-mpc-worker';
import httpClient from '@bitverse-h5-wallet/bitverse-http-client';
import { KeygenResultType, MpcSignType } from '../types/types';
import { utf8ToBytes } from 'ethereum-cryptography/utils';
import {
  ecrecover,
  fromRpcSig,
  hashPersonalMessage,
  pubToAddress,
} from '@ethereumjs/util';
import { buf2hex, paddingLeft } from './convert-utils';
import { BWWalletService } from '../bwWalletService';
import { Buffer } from 'buffer';
import { PasswordService } from '../passwordService';
import { getBaseDomain } from './utils';
import {
  MessageTypes,
  SignTypedDataVersion,
  TypedDataUtils,
  TypedMessage,
} from '@metamask/eth-sig-util';
import { getProvider } from './chain-utils';
import { _TypedDataEncoder } from 'ethers/lib/utils';
import { TypedDataDomain, TypedDataField } from 'ethers';
import { bufferToHex } from 'ethereumjs-util';

const baseURL = getBaseDomain();

export async function mpcKeyGen(): Promise<KeygenResultType | null> {
  // keygen first
  const kg_party_one_first_message_result = await httpClient.post(
    `${baseURL}/bitverse/wallet/v1/private/mpc/ecdsa/keygen/first`,
    {}
  );
  if (kg_party_one_first_message_result.retCode != 0) {
    //TODO 处理失败请求
    return null;
  }
  const kg_party_one_first_message = kg_party_one_first_message_result.result;

  const id = JSON.parse(kg_party_one_first_message)[0];
  const kg_first_message = JSON.parse(kg_party_one_first_message)[1];

  const keyGenFirstHandleResult = await mpcWorker.ecdsa_keygen_first_handle(id);

  // keygen second
  const kg_party_one_second_message_result = await httpClient.post(
    `${baseURL}/bitverse/wallet/v1/private/mpc/ecdsa/keygen/second`,
    keyGenFirstHandleResult.key_gen_second_req
  );
  if (kg_party_one_second_message_result.retCode != 0) {
    //TODO 处理失败请求
    return null;
  }

  const kg_second_message = kg_party_one_second_message_result.result;
  const keyGenSecondHandleResult = await mpcWorker.ecdsa_keygen_second_handle(
    id,
    kg_first_message,
    JSON.parse(kg_second_message)
  );

  // chain_code first
  const cc_party_one_first_message_result = await httpClient.post(
    `${baseURL}/bitverse/wallet/v1/private/mpc/ecdsa/keygen/chaincode/first`,
    keyGenSecondHandleResult.chain_code_first_req
  );
  if (cc_party_one_first_message_result.retCode != 0) {
    //TODO 处理失败请求
    return null;
  }
  const cc_party_one_first_message = cc_party_one_first_message_result.result;
  const chainCodeFirstHandleResult =
    await mpcWorker.ecdsa_chaincode_first_handle(id);

  // chain_code second
  const cc_party_one_second_message_result = await httpClient.post(
    `${baseURL}/bitverse/wallet/v1/private/mpc/ecdsa/keygen/chaincode/second`,
    chainCodeFirstHandleResult.chain_code_second_req
  );

  if (cc_party_one_second_message_result.retCode != 0) {
    //TODO 处理失败请求
    return null;
  }
  const cc_party_one_second_message = cc_party_one_second_message_result.result;

  // swap Masterkey
  const chainCodeSecondHandleResult =
    await mpcWorker.ecdsa_chaincode_second_handle_and_return_master_key(
      id,
      keyGenSecondHandleResult.party_two_paillier,
      JSON.parse(kg_second_message),
      JSON.parse(cc_party_one_first_message),
      chainCodeFirstHandleResult.cc_ec_key_pair2,
      keyGenFirstHandleResult.kg_ec_key_pair_party2,
      JSON.parse(cc_party_one_second_message)
    );

  console.log('客户端私钥分片=========>', chainCodeSecondHandleResult);

  return {
    id: id,
    ...chainCodeSecondHandleResult,
  };
}

/**
 * MPC签名统一走这个方法
 * @returns 返回 r/s/recId
 */
export async function mpcSign({ id, master_key, msg }: MpcSignType) {
  try {
    const signFirstRequestResult =
      await mpcWorker.ecdsa_build_sign_first_request(id);

    const biz_id = uuidv4();
    const signFirstRequest = signFirstRequestResult.sign_first_req;
    signFirstRequest.bizId = biz_id;
    const sign_party_one_first_message_result = await httpClient.post(
      `${baseURL}/bitverse/wallet/v1/private/mpc/ecdsa/sign/first`,
      signFirstRequest
    );
    if (sign_party_one_first_message_result.retCode != 0) {
      return;
    }
    const sign_party_one_first_message =
      sign_party_one_first_message_result.result;

    const c_message_le_hex = msg; // 自己包装 _preHash 3种实现
    const c_x_pos = (60).toString(16); // evm 固定值 x = 60
    const c_y_pos = (0).toString(16); // evm 固定值 y = 0
    const signFirstHandleResult = await mpcWorker.ecdsa_sign_first_handle(
      c_message_le_hex,
      master_key,
      c_x_pos,
      c_y_pos,
      id,
      JSON.parse(sign_party_one_first_message),
      signFirstRequestResult.eph_comm_witness,
      signFirstRequestResult.eph_ec_key_pair_party2
    );

    signFirstHandleResult.sign_second_request.bizId = biz_id;
    const sign_party_one_second_message_result = await httpClient.post(
      `${baseURL}/bitverse/wallet/v1/private/mpc/ecdsa/sign/second`,
      signFirstHandleResult.sign_second_request
    );

    if (sign_party_one_second_message_result.retCode != 0) {
      return;
    }
    const signature = JSON.parse(sign_party_one_second_message_result.result);
    return signature;
  } catch (error) {
    console.log(error);
  }
}

// plaintext 必须为十六进制
export async function backup_client_ecc_encrypt(
  plaintext: string,
  publicKey: Record<string, string>
) {
  //解析客户端MasterKey中的P2
  // const publicKey = JSON.parse(
  //   '{"x":"4bba85e12462a20e4714cda4ebc76520025f156b8010bd3c4edcf94a82c223b9","y":"446cea318cc3b77c6e8a84616003356fa2af70e766064e23b85195a493950466"}'
  // );
  const ciphertext = (await mpcWorker.ecdsa_ecc_encrypt(
    plaintext,
    publicKey
  )) as { proof: Record<string, string>; helgamalsegmented: any };
  return ciphertext;
}

export async function ecc_server_key_backup_verify(
  serverEncryptedPrivateKey: Record<string, string>,
  clientMasterKey: Record<string, string>
) {
  const verifyResult = await mpcWorker.ecdsa_ecc_server_key_backup_verify(
    serverEncryptedPrivateKey,
    clientMasterKey
  );
  return verifyResult;
}

async function preHash(typedDataJson: any, version: string) {
  if (version === 'V1') {
    // Version 1 is not supported by eth-sig-util, you would need to implement this if necessary
    throw new Error('EIP-712 version 1 is not supported');
  } else if (version === 'V3') {
    // Version 3 is not directly supported by eth-sig-util, you would need to implement this if necessary
    throw new Error('EIP-712 version 3 is not supported');
  } else if (version === 'V4') {
    // Use eth-sig-util to hash the typed data for version 4
    const hash = TypedDataUtils.eip712Hash(
      typedDataJson,
      SignTypedDataVersion.V4
    );
    return bufferToHex(hash);
  } else {
    throw new Error('Unsupported EIP-712 version');
  }
}

export async function signTypedDataV4(typedDataJson: any) {
  const signs = await signMessage(typedDataJson);
  return signs;
}

/**
 * 使用EIP-191、EIP-712 标准进行消息签名
 * @param message string | typedJson
 * @returns hex string
 */
export async function signMessage<T extends MessageTypes>(
  message: string | TypedMessage<T>
) {
  if (!message) return Promise.reject('message is empty');
  let hash;

  if (typeof message === 'object') {
    hash = TypedDataUtils.eip712Hash(message, SignTypedDataVersion.V4);
  } else {
    const bf = utf8ToBytes(message);
    hash = hashPersonalMessage(Buffer.from(buf2hex(bf), 'hex'));
  }
  console.log(`[mpc-utils] signMessage hash = `, hash);

  const password = await PasswordService.instance.getPassword();
  const keyinfo = await BWWalletService.instance.getCurrentWalletMasterKeyInfo(
    password
  );
  if (!keyinfo) return;
  const { id, master_key } = keyinfo;
  try {
    const signature = await mpcSign({ id, master_key, msg: buf2hex(hash) });
    console.log(`[mpc-utils] mpcSign signature = `, signature);

    const bufferR = paddingLeft(signature.r);
    const bufferS = paddingLeft(signature.s);
    const bigintV = BigInt(signature.recid + 27); // 这里需要处理

    const hexV = bigintV.toString(16);
    const bufferV = Buffer.from(hexV, 'hex');

    // Convert the signature to Ethereum standard format
    const signatureBuffer = Buffer.concat([bufferR, bufferS, bufferV]);

    const signatureHex = buf2hex(signatureBuffer);
    return Promise.resolve(`0x${signatureHex}`); // mantle jaan 要求添加一个 0x 前缀，这个是否会影响其他业务需求注意。
  } catch (err) {
    return Promise.reject('sign message error');
  }
}

/**
 * 恢复签名地址
 * @param signature 签名结果
 * @param originMessage 原始信息
 * @returns 钱包地址
 */
export function recoverAddress(signature: string, originMessage: string) {
  // 如果 signature 以 0x 开头，去掉 0x
  if (signature.startsWith('0x')) {
    signature = signature.slice(2);
  }
  // Parse the signature buffer
  const { r, s, v } = fromRpcSig('0x' + signature);

  const bf = utf8ToBytes(originMessage);
  const messageHash = hashPersonalMessage(Buffer.from(buf2hex(bf), 'hex'));

  // Recover the public key from the signature
  const publicKey = ecrecover(messageHash, v, r, s);

  // Verify that the public key corresponds to the address of the signer
  const address = pubToAddress(publicKey);
  const walletAddress = '0x' + address.toString('hex');
  return walletAddress;
}
/**
 * 恢复签名地址
 * @param signature 签名结果
 * @param originMessage 原始信息
 * @returns 钱包地址
 */
export async function recoverAddressEip712(
  signature: string,
  originMessage: any
) {
  // 如果 signature 以 0x 开头，去掉 0x
  if (signature.startsWith('0x')) {
    signature = signature.slice(2);
  }
  // Parse the signature buffer
  const { r, s, v } = fromRpcSig('0x' + signature);

  // const bf = utf8ToBytes(originMessage);
  // const messageHash = hashPersonalMessage(Buffer.from(buf2hex(bf), 'hex'));

  const { domain, types, value } = originMessage;
  const typedDataPayload = await getTypedDataPayload(domain, types, value);
  const messageHash = TypedDataUtils.eip712Hash(
    typedDataPayload,
    SignTypedDataVersion.V4
  );

  // Recover the public key from the signature
  const publicKey = ecrecover(messageHash, v, r, s);

  // Verify that the public key corresponds to the address of the signer
  const address = pubToAddress(publicKey);
  const walletAddress = '0x' + address.toString('hex');
  return walletAddress;
}

export const getTypedDataPayload = async (
  domain: TypedDataDomain,
  types: Record<string, Array<TypedDataField>>,
  value: Record<string, any>
) => {
  const provider = getProvider();
  // Populate any ENS names (in-place)
  const populated = await _TypedDataEncoder.resolveNames(
    domain,
    types,
    value,
    (name: string) => {
      return provider.resolveName(name);
    }
  );
  const res = _TypedDataEncoder.getPayload(
    populated.domain,
    types,
    populated.value
  );

  return res;
};
