import './index.less';
import walletLogo from '../../assets/images/logo.png';
import { Button } from 'antd-mobile';
import useNavigatePage from '../../hooks/use-navigate-page';
import { useContext, useEffect } from 'react';
import { AuthContext } from '../../contexts/AuthContext';

const Welcome = () => {
  const { navigateToPage } = useNavigatePage();
  const { isLogin } = useContext(AuthContext);
  useEffect(() => {
    if (isLogin) {
      navigateToPage('home');
    }
  }, [isLogin]);
  return (
    <div className="welcome pd16">
      <div className="df fdc aic">
        <p className="fw500 fs24 welcome-to">Welcome to Bitverse Wallet</p>
        <img src={walletLogo} className="logo" alt="bitverse-wallet" />
        <Button
          onClick={() => navigateToPage('signUp')}
          color="primary"
          className="w100 mt16"
          fill="solid"
          size="large"
        >
          Create MPC Wallet
        </Button>
        <Button
          onClick={() => navigateToPage('login')}
          color="primary"
          className="w100 mt16"
          fill="outline"
          size="large"
        >
          Login with App wallet
        </Button>
      </div>
    </div>
  );
};

export default Welcome;
