import NodeRSA from 'node-rsa';
import MD5 from 'crypto-js/md5';
import { ScryptGenerator, BWSecureStorage, BWWalletService } from '../index';
import {
  backup_client_ecc_encrypt,
  ecc_server_key_backup_verify,
} from '../utils/mpc-utils';
import httpClient from '@bitverse-h5-wallet/bitverse-http-client';
import { CloudType, WalletAlgorithmType, cloudBackupDir } from './type';
import { getBaseDomain } from '../utils/utils';
class BackupService {
  private static _instance: BackupService;
  private isCreating = false;

  private bitverseServerbaseURL = getBaseDomain();
  private googleDriveUploadUrl =
    'https://www.googleapis.com/upload/drive/v3/files';
  private googleAuthToken = '';
  private constructor() {
    // 私有构造函数
  }

  public static get instance(): BackupService {
    if (!this._instance) {
      this._instance = new BackupService();
    }
    return this._instance;
  }

  public async handleBackup({
    cloudType = CloudType.googleDriver,
    token,
    password,
    walletId,
  }: {
    cloudType?: CloudType;
    password: string;
    token: string;
    walletId: string;
  }) {
    this.googleAuthToken = token;
    await this.backupClient({
      cloudType,
      password,
      walletId,
    });

    await this.backupServer(walletId, password, cloudType);
  }

  /// 备份客户端分片
  /// RSA私钥存云盘->客户端分片备份服务端->服务端私钥备份到云盘
  private async backupClient({
    cloudType,
    password,
    walletId,
  }: {
    password: string;
    walletId: string;
    cloudType: CloudType;
  }) {
    // await this.verifyPassword(password);
    //   判断当前选中wallet,如果没有选中，return false, 逻辑先跳过
    try {
      console.log(`开发备份钱包WalletId= ${walletId} Cloud=${cloudType}`);

      const key = new NodeRSA(null, { environment: 'browser' });
      const generator = key.generateKeyPair(2048, 65537);
      const privateKey = generator.exportKey('pkcs8-private');
      const publicKey = generator.exportKey('pkcs8-public');

      await this.backupClientAuth(walletId, privateKey, cloudType);
      console.log(`开始同步客户端备份状态给服务端`);
      // 开始同步客户端备份状态给服务端
      await this.clientMasterKeySaveToBackEndServer(
        walletId,
        password,
        publicKey
      );
    } catch (error) {
      return 'Backup error!';
    }
  }
  private verifyPassword = (password: string) => {
    return true;
  };

  private async backupClientAuth(
    walletId: string,
    clientRsa: string,
    cloudType: CloudType
  ): Promise<void> {
    switch (cloudType) {
      case CloudType.googleDriver:
        this.isCreating = true;
        await this._googleUpload(`${walletId}.x`, clientRsa);

        break;

      default:
        break;
    }
  }
  private async backupServerAuth(
    walletId: string,
    clientRsa: string,
    cloudType: CloudType
  ): Promise<void> {
    switch (cloudType) {
      case CloudType.googleDriver:
        this.isCreating = true;
        await this._googleUpload(`${walletId}.y`, clientRsa);

        break;

      default:
        break;
    }
  }
  private async createGoogleDriveFolderIfNotExists(folderName: string) {
    // 检查文件夹是否存在
    const response = await fetch(
      `https://www.googleapis.com/drive/v3/files?q=name='${folderName}'&spaces=drive&fields=files(id)`,
      {
        headers: {
          Authorization: `Bearer ${this.googleAuthToken}`,
        },
      }
    );

    if (response.ok) {
      const data = await response.json();
      if (data.files.length > 0) {
        // 文件夹已存在，返回文件夹对象
        return data.files[0].id;
      } else {
        // 文件夹不存在，创建文件夹
        const folderData = {
          name: folderName,
          mimeType: 'application/vnd.google-apps.folder',
        };

        const createResponse = await fetch(
          'https://www.googleapis.com/drive/v3/files',
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${this.googleAuthToken}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(folderData),
          }
        );

        if (createResponse.ok) {
          const createdFolder = await createResponse.json();
          return createdFolder.id;
        } else {
          console.error('文件夹创建失败');
        }
      }
    } else {
      console.error('文件夹检查失败');
    }
  }

  private async _googleUpload(filename: string, content: string) {
    const folderId = await this.createGoogleDriveFolderIfNotExists(
      cloudBackupDir
    );
    const metadata = {
      name: filename, // 文件名
      mimeType: 'application/octet-stream', // 文件类型
      parents: [folderId],
    };

    const form = new FormData();
    form.append(
      'metadata',
      new Blob([JSON.stringify(metadata)], { type: 'application/json' })
    );
    form.append(
      'file',
      new Blob([content], { type: 'application/octet-stream' })
    );

    const res = await fetch(
      `${this.googleDriveUploadUrl}?uploadType=multipart`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.googleAuthToken}`,
        },
        body: form,
      }
    );
    const json = await res.json();
    if (json['error']) {
      throw new Error(json['error']['message']);
    }

    if (json?.name === filename) {
      console.log('上传成功');
      return true;
    }
    throw new Error('Backup error!');
  }

  // 备份客户端分片 ---- 开始同步客户端备份状态给服务端
  private async clientMasterKeySaveToBackEndServer(
    walletId: string,
    password: string,
    publicKey: string
  ) {
    // 读取钱包密文
    try {
      const mapId = BWWalletService.instance.getWalletCombUid(walletId);
      const encryptedKeyGenStr = await BWSecureStorage.read(mapId);
      const masterKeyStrList = await ScryptGenerator.decrypt(
        password,
        encryptedKeyGenStr
      );
      // 这个变量是啥内容，不清楚
      const masterKeyStr = JSON.stringify(masterKeyStrList);

      if (!encryptedKeyGenStr) {
        throw "Wallet doesn't exist";
      }

      const walletkeys: any[] = [];

      masterKeyStrList.forEach((masterKeyStrItem: any) => {
        const masterKeyItem = JSON.parse(masterKeyStrItem);
        walletkeys.push(masterKeyItem);
      });

      const mpcClientMasterKeyBackUps: any[] = [];

      for await (const walletkey of walletkeys) {
        const masterKeyMap = walletkey.keyinfo;
        const masterKeyItemMap = masterKeyMap['master_key'];
        const publicMap = masterKeyItemMap['public'];

        /// 取到服务端p1
        const p1Map = publicMap['p1'];
        const publicNodeRSA = new NodeRSA(publicKey);
        const masterKeyEncrypted = publicNodeRSA.encrypt(masterKeyStr, 'hex');
        const encryptedMd5Hash = MD5(masterKeyEncrypted).toString();

        ///  生成helgamalsegmented

        const eccEncryptData = await backup_client_ecc_encrypt(
          encryptedMd5Hash,
          p1Map
        );
        // 删除 proof
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        delete eccEncryptData['proof'];
        const eccEncryptDataHelgamalsegmented = JSON.stringify(eccEncryptData);
        console.log('eccEncryptData', eccEncryptData);
        const mpcClientMasterKeyBackUp = {
          walletAlgorithmType: WalletAlgorithmType.algorithmTypeEcdsa,
          cipherMasterKey: masterKeyEncrypted,
          eccEncryptData: eccEncryptDataHelgamalsegmented,
        };
        mpcClientMasterKeyBackUps.push(mpcClientMasterKeyBackUp);
      }

      const { name } = BWWalletService.instance.getCurrentWallet();

      const walletBackUp = {
        walletId,
        walletGenerateType: 1,
        walletName: name,
        walletDrive: [],
        cloudDiskType: 2, // 谷歌云盘
        mpcClientMasterKey: mpcClientMasterKeyBackUps,
      };
      const result = await httpClient.post(
        `${this.bitverseServerbaseURL}/bitverse/wallet/v1/private/kms/wallet/backup`,
        {
          ...walletBackUp,
        },
      );
      console.log('[response ok]:', result);
    } catch (error) {
      console.error(error);
    }
  }
  /// 备份服务端密钥分片

  private async backupServer(
    walletId: string,
    password: string,
    cloudType: CloudType
  ): Promise<boolean> {
    try {
      // 备份服务端密钥分片
      const uploadCloudKey: string = await this.backupPartyOnePrivateKey(
        walletId,
        password
      );
      console.log('服务端私钥分片备份完成');

      await this.backupServerAuth(walletId, uploadCloudKey, cloudType);

      // 上报服务端备份状态
      // 1 成功
      await this.feedbackServerBackupStatus(walletId, 1, 2, 1);

      // 更新安全分数
      // await refreshBackupStatus();
      return true;
    } catch (e) {
      console.error(`${e}`);
      // 上报服务端备份状态
      // 失败 -1
      await this.feedbackServerBackupStatus(walletId, -1, 2, 1);
      throw new Error('Backup error!');
    }
  }
  private async backupPartyOnePrivateKey(walletId: string, password: string) {
    try {
      const mapId = BWWalletService.instance.getWalletCombUid(walletId);
      const encryptedKeyGenStr = await BWSecureStorage.read(mapId);
      if (!encryptedKeyGenStr) {
        throw "Wallet doesn't exist";
      }

      const serverEncryptedPrivateKey = await this.getEncryptedPrivateKey(
        walletId,
        WalletAlgorithmType.algorithmTypeEcdsa
      );

      const masterKeyStrList = await ScryptGenerator.decrypt(
        password,
        encryptedKeyGenStr
      );
      const walletkeys: any[] = [];

      masterKeyStrList.forEach((masterKeyStrItem: any) => {
        const masterKeyItem = JSON.parse(masterKeyStrItem);
        walletkeys.push(masterKeyItem);
      });
      if (walletkeys.map((item) => item.curv).includes('secp256k1')) {
        ///使用零知识证明，验证服务端返回的加密分片正确性
        const clientMasterKeyMap = walletkeys[0].keyinfo;
        const verifyResult = await ecc_server_key_backup_verify(
          JSON.parse(serverEncryptedPrivateKey),
          clientMasterKeyMap
        );
        if (!verifyResult) {
          throw new Error('ECC validation failed');
        }
      }

      return serverEncryptedPrivateKey;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  private async getEncryptedPrivateKey(
    walletId: string,
    walletAlgorithmType: WalletAlgorithmType
  ) {
    const params = {
      walletId,
      walletAlgorithmType,
    };

    const resp = await httpClient.post(
      `${this.bitverseServerbaseURL}/bitverse/wallet/v1/private/kms/wallet/getEncryptedServerPrivateKey`,
      {
        ...params,
      },
    );
    const { retCode, result = {} } = resp;
    console.log('[getEncryptedPrivateKey ok]:', resp);
    if (retCode === 0) {
      return result;
    }
    return null;
  }
  private async feedbackServerBackupStatus(
    walletId: string,
    status: number,
    cloudDiskType: number,
    walletAlgorithmType: number
  ): Promise<boolean> {
    const info = {
      walletId,
      status,
      cloudDiskType,
      walletAlgorithmType,
    };
    const result = await httpClient.post(
      `${this.bitverseServerbaseURL}/bitverse/wallet/v1/private/kms/wallet/feedbackServerBackupStatus`,
      {
        ...info,
      },
    );

    console.log('[feedbackServerBackupStatus ok]:', result);

    // 更新云盘
    // await serverSafetyScore(walletId);
    // await getServerBackupStatus(walletId);
    return true;
  }
}
export { BackupService };
