import { encode } from 'js-base64';
import ServerTime from './ServerTime';
import { resolveSignature } from './crypto';
import { baseURLMainNet, envConfig } from './config';
import { deviceId } from './utils';

async function request(method: string, url: string, data?: any, headers?: any) {
  // 计算时间戳
  const st = new ServerTime();
  await st.init();

  let relativeUrl = url;
  if (process.env.NODE_ENV === 'development') {
    const fullUrl = new URL(url);
    relativeUrl = url.replace(fullUrl.origin, '');
  }
  const timestamp = st.getTimestamp();

  const env = baseURLMainNet ? 'mainnet' : 'testnet';
  const accessKeyId = envConfig[env].access_key;
  const accessKeySecret = envConfig[env].secret;

  // console.log('[timestamp] ===', timestamp);
  // console.log('[accessKeyId] ===', accessKeyId);
  // console.log('[accessKeySecret] ===', accessKeySecret);

  // 计算消息内容的 base64 编码
  let content = '';
  if (method.toUpperCase() === 'GET') {
    const urlObj = new URL(url);
    const params: any = Object.keys(urlObj.search.substring(1)).sort();
    content = encode(
      params.map((key: string) => `${key}=${params[key]}`).join('&')
    );
  } else {
    try {
      content = encode(!data ? '' : JSON.stringify(data));
    } catch (_) {
      console.log('[catch] content error:', _);
      content = encode(data?.toString() ?? '');
    }
  }

  // console.log('[content] 原始值:', data);
  // console.log('[content] 加密后:', content);

  // 计算签名
  const signature = resolveSignature(
    accessKeyId,
    accessKeySecret,
    content,
    timestamp
  );

  // console.log('[signature] = ', signature);

  const options: RequestInit = {
    // 允许cookie
    credentials: 'include',
    method,
    headers: {
      'Content-Type': 'application/json',
      // 添加网关所需请求头
      'X-B-Timestamp': timestamp,
      'X-B-Signature': signature,
      'X-B-AccessKeyId': accessKeyId,
      'device-id': deviceId,
      platform: 'h5',
      app_name: 'bitverse_h5',
      version: '1.0.0',
      // 'app-version': '2.0.6',
      ...headers,
    },
    body: JSON.stringify(data),
  };

  return fetch(relativeUrl, options)
    .then((response) => {
      // 更新 token 逻辑
      // console.log('-----', response);
      // console.log('----- header token = ', token);

      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Network response was not ok.');
      }
    })
    .then((res) => {
      if (
        res?.retCode === 10007 &&
        !url.includes('/v1/private/user/info/get')
      ) {
        window.open('/auth-failed', '_self');
      } else {
        return res;
      }
    })
    .catch((error) => {
      console.error('There was a problem with the network request.', error);
    });
}

const http = {
  get: (url: string) => request('GET', url),
  post: (url: string, data?: any, headers?: any) =>
    request('POST', url, data, headers),
  // put: (url, data) => request('PUT', url, data),
  // delete: (url, data) => request('DELETE', url, data)
};

export default http;
